import isEmpty from "lodash/isEmpty";

export function isTrue(value)
{
    return (/true/i).test(value);
}

class storage
{
    constructor(storageType)
    {
        this.storage = window[storageType];
    }
    clear(key)
    {
        this.storage.removeItem(key);
    }
    readJSON(key)
    {
        let data = this.storage.getItem(key);

        let parsedValue;

        // if parsing fails return undefined to prevent crash
        try
        {
            parsedValue = data ? JSON.parse(data) : undefined;
        }
        catch (error)
        {
            parsedValue = undefined;
        }

        return parsedValue;
    }
    writeJSON(key, data)
    {
        // console.log("writeJSON -> ", key, data)
        this.storage.setItem(key, JSON.stringify(data));
    }
}

class webAPI
{
    constructor()
    {
        this.localStorage = new storage("localStorage");
        this.sessionStorage = new storage("sessionStorage");
    }
    clear()
    {
        this.localStorage.clear("user-data");
    }
    get token()
    {
        const userData = this.userData;
        return userData ? userData.token : undefined;
    }
    set token(token)
    {
        const userData = this.userData;
        userData.token = token;
        this.userData = userData;
    }
    //#region localStorage
    get userData()
    {
        return this.localStorage.readJSON("user-data");
    }
    set userData(value)
    {
        this.localStorage.writeJSON("user-data", value);
    }
    createHeaders(authorization, hasBody = true)
    {
        let headers = { "Content-Type": "application/json; charset=utf-8" };
        if (authorization === true && this.token)
        {
            headers["Authorization"] = "Bearer " + this.token;
        }
        return headers;
    }
    async get(url, authorization)
    {
        if (authorization && !this.token)
        {
            return {};
        }

        let headers = this.createHeaders(authorization);
        const response = await fetch(url, { headers: headers });

        if (response.status === 403)
        {
            this.clear();
            window.location.reload();
            return;
        }

        const hasAttachment = response.headers.get("content-disposition")?.includes?.("attachment");
        if (response.ok)
        {
            if (!hasAttachment)
            {
                return response.json();
            }
            else
            {
                return response;
            }
        }
        return {};
    }
    /**
     * Will reject promise on error, used to trigger react-query error states
     */
    async getQuery(url, authorization)
    {
        let headers = this.createHeaders(authorization);

        const response = await fetch(url, { headers: headers });

        if (response.status === 403)
        {
            this.clear();
            window.location.reload();
            return;
        }

        return response.json();
    }
    async put(url, data, authorization)
    {
        return this.sendData(url, "PUT", data, authorization);
    }
    async patch(url, data, authorization)
    {
        return this.sendData(url, "PATCH", data, authorization);
    }
    async post(url, data, authorization)
    {
        return this.sendData(url, "POST", data, authorization);
    }
    /**
     * Delete a specific object
     * @param {string} url
     * @param {boolean} authorization
     */
    async delete(url, authorization)
    {
        return this.sendData(url, "DELETE", undefined, authorization);
    }
    /**
     * Copy a specific object
     * @param {string} url
     * @param {boolean} authorization
     */
    async copy(url, authorization)
    {
        return this.sendData(url, "COPY", undefined, authorization);
    }
    async sendData(url, method, data, authorization, resultMethod)
    {
        if (authorization && !this.token)
        {
            return {};
        }

        const fetchMethod = method.toUpperCase();
        let headers = this.createHeaders(authorization);
        // "Content-Type": "application/x-www-form-urlencoded"
        let response = await fetch(url, {
            method: fetchMethod,
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrer: "no-referrer",
            // @ts-ignore
            body: fetchMethod !== "GET" && JSON.stringify(data),
        });
        let result = {};

        if (response.status === 403)
        {
            this.clear();
            window.location.reload();
            return;
        }

        if (response.ok)
        {
            if (resultMethod && typeof response[resultMethod] === "function")
            {
                result = response[resultMethod]();
            }
            else
            {
                result = response.text().then((text) =>
                {
                    try
                    {
                        return JSON.parse(text);
                    }
                    catch (err)
                    {
                        return text;
                    }
                });
            }
        }
        else
        {
            const text = await response.text();
            try
            {
                result = JSON.parse(text);
            }
            catch (err)
            {
                result = text;
            }

            return Promise.reject(result);

        }
        return result;
    }
    /**
     * Send File(s) to server
     * @param {string} url
     * @param {object} formData
     * @param {boolean} authorization
     * @param {function} callback not in use
     * @returns Promise
     *
     * @example
     * 	let formData = new FormData();
     *  formData.append(filename, base64 file);
     *  sendFile(<api endpoint>, formData, true);
     */
    async sendFile(url, formData, authorization, callback = undefined)
    {
        return new Promise((resolve, reject) =>
        {
            try
            {
                let request = new XMLHttpRequest();
                request.open("POST", url);
                // request.onprogress = callback;
                // request.onloadstart = callback;
                // request.upload.onprogress = callback;
                request.onload = () =>
                {
                    let result;
                    if (request.status === 200)
                    {
                        result = request.response ? JSON.parse(request.response) : {};
                    }
                    else
                    {
                        result = { success: false, status: request.status };
                    }
                    callback && callback(result);
                    resolve(result);
                };
                authorization && request.setRequestHeader("Authorization", `Bearer ${this.token}`);
                request.send(formData);
            }
            catch (err)
            {
                reject(err);
            }
        });
    }
}

class GenericServerAPI extends webAPI
{
    // notify, cms use v1, hub uses v2
    silentLogin(token)
    {
        return this.post("/api/v2/silent-login", { token }, false)
            .then((userData) =>
            {
                this.userData = userData;
                return userData;
            });
    }

    validateToken()
    {
        if (!this.token)
        {
            return Promise.reject("no token");
        }
        return this.post("/api/v2/token", { token: this.token }, false)
            .then((response) =>
            {
                if (!response.token)
                {
                    return Promise.reject("token is missing");
                }
                else
                {
                    this.token = response.token;
                    return true;
                }
            });
    }
}

class WebHubServerApi extends GenericServerAPI
{

    get timezone ()
    {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    async login(username, password)
    {
        return this.post("/api/v2/login", { email: username, passwordHash: password }, false)
            .then((userData) =>
            {
                this.userData = userData;
                return userData;
            });
    }

    forgotPassword(username)
    {
        return this.post("/api/v2/forgotPassword", { email: username }, false)
            .then((result) =>
            {
                console.log("forgot password result", result);
                return result;
            });
    }

    IsPasswordResetTokenExists(recoveryToken)
    {
        return this.post("/api/v2/IsPasswordResetTokenExists", { token: recoveryToken }, false);
    }

    resetPassword(password, recoveryToken)
    {
        return this.post("/api/v2/resetPassword", { token: recoveryToken, newPassword: password }, false)
            .then(async (result) =>
            {
                console.log("reset password result", result); // Returns new token upon success, false otherwise
                // Silent login
                await this.silentLogin(result.token);
                return (result !== false);
            });
    }

    getEnums()
    {
        return this.get("/api/v2/enums", true);
    }

    getAvatar()
    {
        if (this.userData.user.userInfo.profileImage)
        {
            return this.get(`/api/v3/image/${this.userData.user.userInfo.profileImage}`);
        }
        return Promise.resolve(undefined);
    }

    getAccounts()
    {
        return this.post("/api/v2/accounts", {}, true);
    }

    async getProperties()
    {
        const propertiesList = await this.post("/api/v2/properties/", {}, true);

        const properties = {};
        if (Array.isArray(propertiesList))
            propertiesList.forEach((property) =>
            {
                properties[property.propertyId] = property;
            });

        return properties;
    }

    addPendingProperties(properties)
    {
        return this.post("/api/v2/properties/addPendingProperties", { properties: properties }, true);
    }

    deletePendingProperty(propertyId)
    {
        return this.post("/api/v2/properties/delete-pending-property", { propertyId: propertyId }, true);
    }

    async validateData(validate)
    {
        const result = await this.post("/api/v2/validate/syntax", validate);
        return isTrue(result);
    }

    async validateUserInfo(userInfo)
    {
        const emailValid = await this.validateData({ email: userInfo.email });
        if (!emailValid) return `email ${userInfo.email} is not valid`;

        const phoneValid = await this.validateData({ number: userInfo.phoneNumber });
        if (!phoneValid) return `phone # ${userInfo.phoneNumber} is not valid`;

        return undefined;
    }

    validateCompanyName(company)
    {
        // Returns true if the company name is valid, false otherwise
        return this.post("/api/v2/validate/company-name", { companyName: company }, true);
    }

    validateEmail(email, creatorId)
    {
        // Returns true if the email address is valid (not taken), false otherwise
        return this.post("/api/v2/validate/email", { email: email, creatorId: creatorId }, true);
    }

    validatePassword(password)
    {
        return this.post("/api/v2/validate/password", { password: password });
    }

    updatePassword(email, currentPassword, newPassword)
    {
        const apiModel = {
            Email: email,
            NewPassword: newPassword,
            CurrentPassword: currentPassword
        };

        return this.post("/api/v5/user/update-password", apiModel, true);
    }

    createUser(userInfo)
    {
        return this.post("/api/v2/users/create", { user: userInfo }, true);
    }

    async updateUser(userInfo)
    {
        const result = await this.post("/api/v2/users/update", { user: userInfo }, true);
        return isTrue(result);
    }

    async deleteUser(userId)
    {
        const result = await this.post("/api/v5/user/delete", { ids: Array.isArray(userId) ? userId: [userId] }, true);
        return isTrue(result);
    }

    async suspendUser(userId)
    {
        const result = await this.post("/api/v5/user/suspend", { userId: userId }, true);
        return isTrue(result);
    }

    async unsuspendUser(userId)
    {
        const result = await this.post("/api/v2/users/unsuspend", { userId: userId }, true);
        return isTrue(result);
    }

    async transferUser(userId, ownerId)
    {
        const result = await this.post("/api/v2/users/transfer", { userId: userId, ownerId: ownerId }, true);
        return isTrue(result);
    }

    sendEmailFromTemplate(emailModel)
    {
        return this.post("/api/v2/send-email", emailModel, true);
    }

    updateCurrentUserAndProfileImage(userModel, imageModel, companyInfo)
    {
        let apiBody = { user: userModel, image: imageModel, company: companyInfo };
        return this.post("/api/v2/users/update-with-image", apiBody, true);
    }

    updateUserProfileImage(imageModel)
    {
        return this.post("/api/v2/users/update-image", { image: imageModel }, true)
            .then((success) =>
            {
                if (success)
                {
                    return this.silentLogin(this.token)
                        .then(() => true);
                }
                return success;
            });
    }

    /**
     *
     * @param {string | undefined} userId if undefined uses the authToken to get the current user
     */
    async getUserLicence(userId)
    {
        let licenceData = await this.post("/api/v2/licences/user", { userId: userId }, true);

        if (licenceData && Array.isArray(licenceData.licenceDateRangeList))
        {
            let licenceHash = {};
            licenceData.licenceDateRangeList.forEach((licence) =>
            {
                licenceHash[licence.name] = licence;
            });

            licenceData.licenceHash = licenceHash;
            delete licenceData.licenceDateRangeList;
        }
        return licenceData;
    }

    updateCompany(company)
    {
        return this.post("/api/v3/company", company, true);
    }

    updateCompanyImage(imageModel)
    {
        return this.put("/api/v3/company/image", { image: imageModel }, true)
            .then((success) =>
            {
                if (success)
                {
                    return this.silentLogin(this.token)
                        .then(() => true);
                }
                return success;
            });
    }

    updateTeam(teamId, name, members, permissions)
    {
        let team = {
            id: teamId,
            name,
            members: members.map((m) => m.id),
            permissions,
        };
        return this.post("/api/v3/company/teams", { team }, true);
    }

    updateUserPermissions(userId, addToTeam, oldTeamId, newTeamId, permissions)
    {
        if (addToTeam)
        {
            return this.post("/api/v3/user/team", { userId, oldTeamId, newTeamId }, true);
        }

        if (permissions.isAdmin)
        {
            return this.assignCompanyAdmin(userId, this.userData.user.userCompanyInfo.companyId);
        }

        return this.post("/api/v3/user/permissions", { oldTeamId, userId, permissions }, true);
    }

    getCompanySDKLicence(companyId)
    {
        return this.post("/api/v2/licences/company-sdk", { companyId: companyId }, true);
    }

    licenceCreateOrUpdate(licenceModel, sdkLicenceModel)
    {
        const model = { licenceModel: licenceModel, sdkLicenceModel: sdkLicenceModel };
        return this.post("/api/v2/licences/create-update", model, true);
    }

    async assignCompanyAdmin(userId, companyId)
    {
        const result = await this.post("/api/v5/company/assign-admin", { userId: userId }, true);
        const boolResult = isTrue(result);

        if (boolResult === true)
        {
            let userData = this.userData;
            let userInfo = userData && userData.user && userData.userInfo;

            if (userInfo && userInfo.company && userInfo.company.id === companyId)
            {
                userInfo.company.adminId = userId;
                this.userData = userData;
            }
        }

        return boolResult;
    }

    assignProperty(userId, properties)
    {
        return this.post("/api/v2/properties/assign", { userId: userId, propertyList: properties }, true);
    }

    getAssignedProperties(userId)
    {
        return this.post("/api/v2/properties/get-assigned", { userId: userId }, true);
    }

    getLicenceKeyInformation(licenceKey, syncReport)
    {
        const data = { LicenceKey: licenceKey, IsSyncDevices: syncReport };
        return this.post("/api/v2/licences/sdk-licencekey", data, true);
    }

    getServerImageUrl(id)
    {
        ///img/upload.png
        return `https://auth.mapsted.com/api/CommonData/GetImage/${id}`;
    }

    registerPublicUser(publicUser)
    {
        return this.sendData("/api/v2/users/register-public", "POST", publicUser, false);
    }

    getUserShoppingMalls()
    {
        const companyId = this.userData.user && this.userData.user.userInfo && this.userData.user.userInfo.companyId;
        return this.post("/api/v2/properties/get-user-shopping-malls", { companyId: companyId }, true);
    }

    getContactPerson()
    {
        const companyAdminId = this.userData.user && this.userData.user.userInfo
            && this.userData.user.userInfo.company && this.userData.user.userInfo.company.adminId;

        console.log("adminId ", companyAdminId);

        return this.get(`/api/v2/companies/get-contact-person/${companyAdminId}`, true);
    }

    getAgreement(type)
    {
        return this.get(`/api/v2/get-agreement/${type}`, true);
    }

    getUserAgreedProperties(userId, agreementId)
    {
        return this.post("/api/v2/properties/agreed-properties", { userId: userId, agreementId: agreementId }, true);
    }

    acceptAgreement(userId, agreementId, propertyId)
    {
        return this.post("/api/v2/accept-agreement", { userId: userId, agreementId: agreementId, propertyId: propertyId }, true);
    }

    changePropertyStatus(propertyId, newStatus)
    {
        return this.post("/api/v2/change-property-status", { propertyId: propertyId, newStatus: newStatus }, true);
    }

    billing = {
        getDefaultCard: () => this.get("/api/v3/payments/default_card", true),
        setDefaultCard: (paymentMethodId) => this.put("/api/v3/payments/default_card", { paymentMethodId }, true),
        getCompanyCreditCards: () => this.get("/api/v3/payments/cards", true).then(({ data }) => data),
        removeCard: (paymentMethodId) => this.delete(`/api/v3/payments/card/${paymentMethodId}`, true),
        getStripeToken: () => this.get("/api/v3/payments/stripe-publishable", true).then(({ token }) => token),
        createPaymentIntent: (amount, currency, invoiceNumber, paymentMethodId) => (
            this.post("/api/v3/payments/payment_intent", { amount, currency, paymentMethodId, invoiceNumber }, true)
                .then(({ success, intent_secret, error }) => success ? intent_secret : Promise.reject(error))
        )

    }

    /**
     * @param {Response} response
     * @return {Promise}
     */
    async downloadResponse(response, filename = undefined)
    {
        const contentDisposition = response.headers.get("content-disposition");

        let _filename = "";
        if (filename)
        {
            _filename = filename;
        }
        else if (contentDisposition && contentDisposition.includes("filename="))
        {
            _filename = contentDisposition.split("filename=\"")[1].split("\"")[0];
        }

        if (!_filename)
        {
            _filename = "download.txt";
        }

        return response.blob()
            .then((file) =>
            {
                const url = window.URL.createObjectURL(file);
                const a = document.createElement("a");
                a.href = url;
                a.download = _filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            });
    }

    /**
     * Downloads a file from the public folder
     * @param {string} filename
     */
    async downloadPublicFile(filename)
    {
        return fetch(`/api/public/${filename}`)
            .then((res) => this.downloadResponse(res, filename));
    }

    utr = {
        /**
         * Given an array of emails to check, returns emails that already exist in our system and the index of that email in the input emails array
         * @param emails {[string]}
         * @returns {Promise<[{email: string, index: number}]>}
         */
        validateUserEmailsUnique: (emails) => this.post("/api/v5/validation/unique-emails", { emails }, true)
    }

    sendFeedback = (message, imageDataURL) =>
    {
        const { userInfo, userCompanyInfo }= this.userData?.user;
        const { email, firstName, lastName } = userInfo;
        return this.post("/api/v1/feedback", {
            email,
            firstName,
            lastName,
            companyName: userCompanyInfo.name,
            page: window.location.href,
            message,
            imageDataURL
        }, true);
    }

    /**
     *
     * @param {Object} data
     * @param {String} data.reason
     * @param {String} data.feedback
     * @param {String} data.timezone
     * @param {("suspend"|"close")} type
     * @returns {Promise}
     */
    suspendDeleteAccount = async (data, type = "suspend") =>
    {
        if (!isEmpty(data))
        {
            data.timezone = this.timezone;
        }
        return await this.post(`/api/v5/user/${type}-account`, data, true);
    };

    /** */
    getCountries = async () =>
    {
        try
        {

            const { data } = await this.get("/api/v5/places/countries");

            return data;

        }
        catch (error)
        {
            throw new Error(error);
        }
    };

    holdStatus = async () => this.get("/api/v5/user/hold-status", true);

    getMobileSDK({ deviceType, appName })
    {
        const params = new URLSearchParams([["deviceType", deviceType], ["appName", appName]]);
        return this.get(`/api/v3/company/sdk-file?${params.toString()}`, true)
            .then((resp) => this.downloadResponse(resp, `${appName}.key`));
    }

}

export default new WebHubServerApi();
