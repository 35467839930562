import React, { useContext } from "react";
import Role from "./Role";
import { Placeholder } from "semantic-ui-react";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { ROLE_CATEGORIES } from "../../../../../_utils/constants";
import { useTranslation } from "react-i18next";

const RolesTableView = ({ properties, contacts, refreshContacts, contactsStatus, propertiesStatus, propertyFilter }) =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const { data: users, status: usersStatus } = useQuery({ ...QUERIES.utr.USERS(state.userInfo.company.companyUID), refetchOnWindowFocus: false });

    const Header = () => (
        <div className="tableBoxRow">
            <TableBoxColumn detail={trans("UserTeamsRoles.ROLE")} />
            <TableBoxColumn detail={trans("UserTeamsRoles.DEFAULT_CONTACT")} />
            <TableBoxColumn detail={trans("UserTeamsRoles.PROPERTY_CONTACT")} />
            <TableBoxColumn narrow />
        </div>
    );

    return (
        <div className="tableBox tableBoxUser roleTable">
            <Header />
            {((propertiesStatus === "success") && (contactsStatus === "success") && (usersStatus === "success" && Object.keys(users).length > 0)) && ROLE_CATEGORIES.map((cat) => (
                <Role
                    key={cat.id}
                    category={cat}
                    contacts={contacts?.filter((con) => con.ContactType === cat.id)}
                    properties={properties}
                    refreshContacts={refreshContacts}
                    propertyFilter={propertyFilter}
                />
            ))}
            {((propertiesStatus === "error") || (contactsStatus === "error") || (usersStatus === "error") || (usersStatus === "success" && Object.keys(users).length === 0)) && (
                <div className="tableBoxRow">
                    <TableBoxColumn>
                        <b>{trans("UserTeamsRoles.Cannot_Retrive_Roles")}</b>
                        {trans("UserTeamsRoles.Something_Went_Wrong")}
                    </TableBoxColumn>
                    <TableBoxColumn />
                    <TableBoxColumn />
                    <TableBoxColumn narrow />
                </div>
            )}
            {((propertiesStatus === "loading") || (contactsStatus === "loading") || (usersStatus === "loading")) && ROLE_CATEGORIES.map((cat) => (
                <div className="tableBoxRow" key={cat.id}>
                    <TableBoxColumn style={{ alignItems: "inherit" }}>
                        <Placeholder><Placeholder.Line/><Placeholder.Line/></Placeholder>
                    </TableBoxColumn>
                    <TableBoxColumn style={{ alignItems: "inherit" }}>
                        <Placeholder><Placeholder.Line/><Placeholder.Line/></Placeholder>
                    </TableBoxColumn>
                    <TableBoxColumn style={{ alignItems: "inherit" }}>
                        <Placeholder><Placeholder.Line/><Placeholder.Line/></Placeholder>
                    </TableBoxColumn>
                    <TableBoxColumn narrow style={{ alignItems: "inherit" }} />
                </div>
            ))}
        </div>
    );
};

export default RolesTableView;
