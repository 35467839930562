import React from "react";
import { Input } from "semantic-ui-react";
import { useSetState } from "ahooks";
import { AccountAccessColumn } from "../Dashboard";
import AccountAccessListItem from "./AccountAccessListItem";
import { usePagination } from "../../../_utils/stateHooks";
import { isEmpty } from "lodash";
import { PaginationBar } from "../../paymentBilling/components/PaginationBar";
import { Trans, useTranslation } from "react-i18next";


const Entities = ({ heading, subHeading, searchPlaceholder, entities = [],  }) =>
{
    const [state, setState] = useSetState({ search: "" });

    const trans = useTranslation().t;
    
    const propertiesFiltered = React.useMemo(() =>
    {
        if (!isEmpty(state.search))
        {
            return entities.filter(({ content }) => content.toLowerCase().includes(state.search.toLowerCase()));
        }
        return entities;
    }, [entities, state.search]);

    const pagination = usePagination(3, propertiesFiltered.length, 0, -1);

    const handleSearchChange = (e, { value }) =>
    {
        setState({ search: value });
        pagination.setPage(0);
    };

    return (
        <>
            <AccountAccessColumn heading={`${heading} (${propertiesFiltered?.length})`} subheading={subHeading}>
                <Input
                    className="accessInput"
                    icon={<img src="/img/icon-search.svg" alt="" />}
                    placeholder={searchPlaceholder}
                    value={state.search}
                    onChange={handleSearchChange}
                />
                {(entities?.length > 0) && (
                    propertiesFiltered
                        .slice(pagination.startIndex, pagination.endIndex)
                        .map((property) => (
                            <AccountAccessListItem key={property.key} {...property} />
                        ))
                )}  

                {propertiesFiltered?.length === 0 && <AccountAccessListItem content={<Trans 
                    i18nKey="AccountAccess.No_Results_For"
                    values={{ searchValue: state.search }}
                />} detail={trans("AccountAccess.Please_Try_Another_Search")} />}
                {(pagination.totalPages > 1) && (
                    <PaginationBar
                        currentPage={pagination.currentPage}
                        totalPages={pagination.totalPages}
                        setPage={pagination.setPage}
                        startIndex={pagination.startIndex}
                        endIndex={pagination.endIndex}
                        totalRows={propertiesFiltered.length}
                    />
                )}
            </AccountAccessColumn>
        </>
    );
};

export default Entities;
