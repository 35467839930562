import React, { useContext, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Image, Popup, Placeholder, Icon } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import { EditUserItem, EDIT_USER_ITEM_TYPES } from "../EditUserItem";
import { HeadingTitle } from "../HeadingTitle";
import "./EditTeamModal.css";
import * as Yup from "yup";
import { Field, Form, FormikProvider, getIn, useFormik } from "formik";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { useMutation, useQuery } from "react-query";
import { MUTATIONS, QUERIES } from "../../../../../_api/queries";
import { LoadingScreen } from "mapsted-components";
import { NotificationContext } from "../../../../notifications";
import { redirectToContactPageLink } from "../../../../../_utils/dashboardCards";
import serverApi from "../../../../../_api/server.api";
import { SettingsPopupLayout } from "../../SettingsPopupLayout";
import { isEqual } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import AnchorLink from "../../../../common/AnchorLink";

export const EditTeamModal = ({ disabled, team, refetchTeams }) =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const notification = useContext(NotificationContext);
    const { data: admin } = useQuery({
        ...QUERIES.utr.USERS(state.userInfo.company.companyUID),
        select: (usersData) => usersData?.find?.((user) => user.isCompanyAdmin) || {}
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [propertySearch, setPropertySearch] = useState("");

    const formik = useFormik({
        initialValues: {
            editTeam: {
                teamName: team?.name,
                teamDesc: team?.desc,
                selectedProducts: team?.products?.map((product) => product.productId.toString()) || [],
                selectedProperties: team?.properties?.map((prop) => prop.id.toString()) || [],
                selectedUsersToAdd: [],
                selectedUsersToRemove: []
            }
        },
        onSubmit: () => onEditTeamFormSubmit().then(() => null, () => null),
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            editTeam: Yup.object().shape({
                teamName: Yup.string().required(),
                teamDesc: Yup.string().required(),
                selectedProducts: Yup.array(),
                selectedProperties: Yup.array(),
                selectedUsersToAdd: Yup.array(),
                selectedUsersToRemove: Yup.array()
            })
        })
    });
    const { values, submitForm, errors, handleSubmit, handleReset, touched, initialValues, setFieldValue, isSubmitting, isValid } = formik;

    const { mutateAsync, reset } = useMutation(MUTATIONS.utr.TEAM_EDIT(refetchTeams));

    const onEditTeamFormSubmit = async () => await mutateAsync({
        ...values.editTeam,
        teamId: team.id,
        teamName: isEqual(initialValues.editTeam.teamName, values.editTeam.teamName)? "": values.editTeam.teamName,
        teamDesc: isEqual(initialValues.editTeam.teamDesc, values.editTeam.teamDesc)? "": values.editTeam.teamDesc,
        selectedUsersToRemove: values.editTeam.selectedUsersToRemove.map((u) => JSON.parse(u).id),
        selectedUsersToAdd: values.editTeam.selectedUsersToAdd.map((u) => JSON.parse(u).id)
    })
        .then(() => handleModalClose())
        .catch((err) =>
        {
            handleModalClose();
            notification.addNotification("danger", "Edit User Failed", "Try again later");
        });

    const getStyles = (fieldName) =>
    {
        if (getIn(touched, fieldName) && getIn(errors, fieldName))
            return { borderColor: "#F14850" };
    };

    /**
     * If a team field is touched and has error then show error message.
     * @return {boolean}
     */
    function getTouchedTeam()
    {
        const nameTouched = (getIn(touched, "editTeam.teamName") && getIn(errors, "editTeam.teamName"));
        const descTouched = (getIn(touched, "editTeam.teamDesc") && getIn(errors, "editTeam.teamDesc"));
        return (nameTouched || descTouched);
    }

    const renderProducts = () =>
    {
        if (admin?.products == null)
        {
            return <EditUserItem
                content={trans("EditTeamModal.You_Do_Not_Have_Any_Licences")}
                link={(
                    <div style={{ fontSize: "13px" }}>
                        <Trans 
                            i18nKey="EditTeamModal.Contact_Sales_To_Purchase" 
                            components={{ link: <AnchorLink href={redirectToContactPageLink(serverApi?.userData?.user?.userInfo)} /> }}
                        />
                        {/* <a href={redirectToContactPageLink(serverApi?.userData?.user?.userInfo)} target="_blank" rel="noreferrer">Contact sales</a> to purchase  */}
                    </div>
                )}
            />;
        }
        return admin?.products?.map((product) => (
            <EditUserItem
                key={`product-${product.productId}`}
                checkbox={(
                    <div className="defaultCB">
                        <label/>
                        <Field
                            name="editTeam.selectedProducts"
                            type="checkbox"
                            value={product.productId.toString()}
                            className="ui fitted checkbox secondary"
                        />
                    </div>
                )}
                content={product.productName}
                detail={<Trans 
                    i18nKey="EditTeamModal.Licence_Expiry_Date"
                    values={{ date: new Date(product.expiryDate).toDateString().split(" ").slice(1).join(" ") }}
                />}
                items={(product.productId === 1) ? ["Mapsted Maps - Mobile", "Mapsted Maps - Web"].concat(product?.licenceNames?.split(",")) : product?.licenceNames?.split(",")}
                type={EDIT_USER_ITEM_TYPES.PRODUCT}
            />
        ));
    };

    const renderProperties = () =>
    {
        const filtered = admin?.assignedProperties?.filter((prop) => prop.propertyName && prop.propertyName.toUpperCase().includes(propertySearch.toUpperCase()));
        if (filtered == null)
        {
            return <EditUserItem
                content={trans("EditTeamModal.To_Purchase")}
                link={<div style={{ fontSize: "13px" }}>
                    <Trans 
                        i18nKey="EditTeamModal.Please_Add_Property_To_Account" 
                        components={{ link: <AnchorLink href={"https://manage.mapsted.com/dashboard/property"} /> }}
                    />
                    {/* Please <a href="https://manage.mapsted.com/dashboard/property">add a property to your account</a> */}
                    
                </div>}
            />;
        }
        else if (filtered?.length < 1)
        {
            return <EditUserItem
                content={trans("EditTeamModal.No_Properties_Found")}
                detail={trans("EditTeamModal.Try_Adjusting_Your_Search")}
            />;
        }
        else
        {
            return filtered?.sort((a,b) => a?.propertyName?.localeCompare(b?.propertyName))?.map((property) => (
                <EditUserItem
                    key={property.propertyId}
                    checkbox={(
                        <div className="defaultCB">
                            <label/>
                            <Field
                                name="editTeam.selectedProperties"
                                type="checkbox"
                                value={property.propertyId.toString()}
                                className="ui fitted checkbox secondary"
                            />
                        </div>
                    )}
                    imgSrc={admin?.assignedPropertyIcons?.[property.propertyId]}
                    content={property.propertyName}
                    detail={((property.cityName === property.stateProvince) || !property.cityName || !property.stateProvince) ? property.cityName || "": property.cityName + ", " + property.stateProvince}
                    items={property?.buildings}
                />
            ));
        }
    };

    const renderUsersToAdd = () => values.editTeam.selectedUsersToAdd.map((user) =>
    {
        const userToAdd = JSON.parse(user);

        // When a user clicks the x button on a user that is to be added
        const handleUndoAddUser = () =>
        {
            if (values.editTeam.selectedUsersToAdd.includes(user))
            {
                setFieldValue("editTeam.selectedUsersToAdd", values.editTeam.selectedUsersToAdd.filter((u) => u !== user));
            }
        };

        return (
            <EditTeamProfileItem
                key={"toAdd" + userToAdd.id}
                firstName={userToAdd.firstName}
                lastName={userToAdd.lastName}
                profileImage={userToAdd.profileImage}
                isUserToAdd={true}
                onUndoAddUser={handleUndoAddUser}
            />
        );
    });

    const renderUsersToRemove = () => values.editTeam.selectedUsersToRemove?.map?.((user) =>
    {
        const userToRemove = JSON.parse(user);

        const handleUndoRemoveUser = () =>
        {
            // when a user clicks the x button on a user that is to be removed
            if (values.editTeam.selectedUsersToRemove.includes(user))
            {
                setFieldValue("editTeam.selectedUsersToRemove", values.editTeam.selectedUsersToRemove.filter((u) => u !== user));
            }
        };

        return (
            <EditTeamProfileItem
                key={"toRemove" + userToRemove.id}
                firstName={userToRemove.firstName}
                lastName={userToRemove.lastName}
                profileImage={userToRemove.profileImage}
                isUserToRemove={true}
                onUndoRemoveUser={handleUndoRemoveUser}
            />
        );
    });

    const renderUsers = () =>
    {
        const filtered = team?.users?.filter((u) => u.firstName);
        if (filtered?.length < 1 && values.editTeam.selectedUsersToAdd.length < 1)
        {
            return <EmptyTeamProfileItem />;
        }
        else
        {
            const handleToRemove = (u) =>
            {
                // when the user clicks the garbage can icon to remove a user
                const user = JSON.stringify(u).toString();
                if (!values.editTeam.selectedUsersToRemove.includes(user))
                {
                    setFieldValue("editTeam.selectedUsersToRemove", [...values.editTeam.selectedUsersToRemove, user]);
                }
            };

            // filter out users that are in the 'to remove' list
            return filtered?.filter((u) => !values.editTeam.selectedUsersToRemove.includes(JSON.stringify(u))).map((u) => (
                <EditTeamProfileItem
                    key={"users" + u.id}
                    firstName={u.firstName}
                    lastName={u.lastName}
                    profileImage={u.profileImage}
                    onRemove={() => handleToRemove(u)}
                />
            ));
        }
    };

    const handleModalClose = () =>
    {
        setModalOpen(false);
        handleReset();
        reset();
    };

    const checkboxesEqual = (initial = [], current = []) => isEqual(new Set(initial), new Set(current));

    /**
     * If checkboxes have same current values as initial values but different order, this means there are no changes
     * Formik cares about array order to determine dirty - so this does an unordered comparison
     * @type {boolean}
     */
    const clean = checkboxesEqual(initialValues.editTeam.selectedProperties, values.editTeam.selectedProperties)
        && checkboxesEqual(initialValues.editTeam.selectedProducts, values.editTeam.selectedProducts)
        && isEqual(initialValues.editTeam.teamName, values.editTeam.teamName)
        && isEqual(initialValues.editTeam.teamDesc, values.editTeam.teamDesc)
        && values.editTeam.selectedUsersToRemove.length === 0
        && values.editTeam.selectedUsersToAdd.length === 0;

    return (
        <FormikProvider value={formik}><Form onSubmit={handleSubmit}>
            {isSubmitting && <LoadingScreen />}
            <ModalLayout className="editUserModal"
                trigger={<Button type="button" disabled={disabled} onClick={() => setModalOpen(true)} className="buttonIcon">
                    <img src="/img/icon-edit.svg" alt="edit"/>
                </Button>}
                open={modalOpen}
                onClose={handleModalClose}
                customHeading={
                    <>
                    Edit Team
                        <Popup
                            trigger={<Icon size="tiny" style={{ marginLeft: "15px" }} circular name="help" />}
                            content={trans("EditTeamModal.Newly_Added_Users_Info")}
                            size="small"
                        />
                    </>
                }
                actions={<>
                    <Button color="grey" floated="left" content={trans("EditTeamModal.Back")} type="button" onClick={() => handleModalClose()} />
                    <Button color="orange" floated="right" content={trans("EditTeamModal.Save")} type="submit" disabled={clean || !isValid} onClick={submitForm} />
                </>}
            >
                <div className="editUserModalSidebar">
                    <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"506px"}>
                        <div className="alignerScroll">
                            <TextGroup heading={trans("EditTeamModal.Team_Name")} contentClassName="ui input inputForm">
                                <Field
                                    name="editTeam.teamName"
                                    className="inputForm"
                                    type="text"
                                    style={getStyles("editTeam.teamName")}
                                    placeholder={trans("EditTeamModal.Team_Name")}
                                />
                            </TextGroup>
                            <TextGroup heading={trans("EditTeamModal.Team_Description")} contentClassName="ui input inputForm" >
                                <Field as="textarea"
                                    name="editTeam.teamDesc"
                                    type="text"
                                    style={getStyles("editTeam.teamDesc")}
                                    placeholder={trans("EditTeamModal.Team_Description_Placeholder")}
                                />
                            </TextGroup>

                            {(getTouchedTeam())
                            && <TextGroup><div className="field-error">
                                {trans("EditTeamModal.Team_Name_Description_Required")}
                            </div></TextGroup>}

                            <HeadingTitle className="teamHeading" heading={trans("EditTeamModal.Users")}
                                button={<AddUserPopup teamUsers={team?.users} />}
                            />
                            <div className="editTeamActionGroup">
                                {renderUsersToAdd()}
                                {renderUsersToRemove()}
                                {renderUsers()}
                            </div>
                        </div>
                    </Scrollbars>
                </div>

                <div className="editUserModalContentBody">
                    <div className="editUserModalContent">
                        <HeadingTitle heading={trans("EditTeamModal.Product")}/>
                        <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"194px"}>
                            <div className="alignerScroll">
                                {renderProducts()}
                            </div>
                        </Scrollbars>
                    </div>

                    <div className="editUserModalContent">
                        <HeadingTitle heading={trans("EditTeamModal.Property")} hasSearch
                            isSearchOpen={propertySearch.length > 0}
                            searchInputProps={{
                                onChange: (e, { value }) => setPropertySearch(value),
                                value: propertySearch,
                                type: "text"
                            }}
                        />
                        <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"330px"}>
                            <div className="alignerScroll alignerHeight">
                                {renderProperties()}
                            </div>
                        </Scrollbars>
                    </div>
                </div>

            </ModalLayout>
        </Form></FormikProvider>
    );
};

const EditTeamProfileItem = ({
    firstName,
    lastName,
    profileImage,
    onRemove = () => null,
    onUndoRemoveUser = () => null,
    onUndoAddUser = () => null,
    isUserToAdd,
    isUserToRemove
}) =>
{
    const { data: profileImageData } = useQuery(QUERIES.utr.IMAGE_FROM_DB(profileImage));

    return (
        <div className="editTeamProfileItem">
            {isUserToAdd && <Icon color="orange" name="plus" />}
            {isUserToRemove && <Icon color="red" name="minus" />}
            {(Object.keys(profileImageData || {}).length === 0)? <Image as="span" src="/img/display-placeholder.svg" /> :<Image as="span" src={profileImageData.content} />}
            <p>{`${firstName} ${lastName}`}</p>
            {!isUserToAdd && !isUserToRemove && <Button className="buttonIcon" onClick={onRemove}>
                <img src="/img/icon-trash-fill.svg" alt="trash" />
            </Button>}
            {isUserToRemove && <Button className="buttonIcon" type="button" onClick={onUndoRemoveUser}>
                <Icon name="close" color="grey" />
            </Button>}
            {isUserToAdd && <Button className="buttonIcon" type="button" onClick={onUndoAddUser}>
                <Icon name="close" color="grey" />
            </Button>}
        </div>
    );
};

const EmptyTeamProfileItem = () => (
    <div className="editTeamProfileItem empty">
        <img src="/img/icon-empty-users.svg" alt="" />
        <p>
            <Trans 
                i18nKey="EditTeamModal.No_User_Added"
            />
        </p>
    </div>
);

const NonMember = ({ nm }) =>
{
    const { data: profileImageData } = useQuery(QUERIES.utr.IMAGE_FROM_DB(nm?.profileImage));

    return (
        <AddUserItem
            key={nm.id}
            firstName={nm.firstName}
            lastName={nm.lastName}
            image={(Object.keys(profileImageData || {}).length === 0) ? undefined: <Image as="span" src={profileImageData.content} />}
            checkbox={
                <div className="defaultCB">
                    <label/>
                    <Field
                        name="editTeam.selectedUsersToAdd"
                        type="checkbox"
                        value={JSON.stringify(nm)}
                        className="ui fitted checkbox secondary"
                    />
                </div>
            }
        />
    );
};

const AddUserPopupContents = ({ teamUsers }) =>
{
    const { state } = useContext(AuthContext);
    const { data: userData, isLoading } = useQuery(QUERIES.utr.USERS(state.userInfo.company.companyUID));

    const trans = useTranslation().t;


    const renderNonTeamUsers = () =>
    {
        const nonMembers = userData?.filter?.((user) => !teamUsers?.map((tu) => tu.id).includes(user.id) && !user.isCompanyAdmin);

        if (isLoading)
        {
            return (<>
                <div key="pl1"><Placeholder><Placeholder.Line /><Placeholder.Line /></Placeholder></div>
                <div key="pl2"><Placeholder><Placeholder.Line /><Placeholder.Line /></Placeholder></div>
                <div key="pl3"><Placeholder><Placeholder.Line /><Placeholder.Line /></Placeholder></div>
                <div key="pl4"><Placeholder><Placeholder.Line /><Placeholder.Line /></Placeholder></div>
            </>);
        }
        else if (!nonMembers || nonMembers?.length < 1)
        {
            return (
                <AddUserItem
                    image={null}
                    firstName={trans("EditTeamModal.No_Users_Left_To_Add")}
                />
            );
        }
        else
        {
            return nonMembers.map((nm) => <NonMember nm={nm} key={`non-member-${nm.id}`} />);
        }
    };

    return (<>
        <SettingsPopupLayout heading={trans("EditTeamModal.Add_Users")}>
            {renderNonTeamUsers()}
        </SettingsPopupLayout>
        {/*<Button type="button" floated="right" color="orange" content="Add" />*/}
    </>);
};

const AddUserPopup = ({ teamUsers }) =>
{
    const trans = useTranslation().t;

    const [open, setOpen] = useState(false);
    return (
        <Popup className="popupBox addUserPopup hasPopup"
            trigger={<Button type="button" basic color="orange" content={trans("EditTeamModal.Add")} onClick={() => setOpen(true)} />}
            on="click"
            position="right center"
            open={open}
            onClose={() => setOpen(false)}
        >
            <AddUserPopupContents teamUsers={teamUsers} />
            <Button floated="right" color="orange" content={trans("EditTeamModal.Done")} onClick={() => setOpen(false)}/>
        </Popup>
    );
};

const AddUserItem = ({ firstName = "", lastName = "", checkbox, placeholder, image = <Image as="span" src="/img/display-placeholder.svg" /> }) => (
    <div className="addUserItem">
        {checkbox}
        {image}
        {`${firstName} ${lastName}`}
        {placeholder}
    </div>
);
