import React, { useContext } from "react";
import { Button, Icon, Pagination } from "semantic-ui-react";
import { usePagination } from "../../../../../_utils/stateHooks";
import { InputWrapper, DropdownGroup } from "../../../../common/InputFormGroup";
import { CheckboxGroup } from "../../../../common/CheckboxGroup";
import { EditButton } from "../../../../common/EditButton";
import { ModalLayout } from "../../../../common/ModalLayout";
import "./RoleContactModal.css";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../../_api/queries";
import { get } from "../../../../../_utils/misc";
import { Trans, useTranslation } from "react-i18next";

const RoleContactModalView = ({
    contacts,
    properties,
    trigger,
    category,
    modifyContact,
    addContact,
    removeContact,
    onClose,
    oneContact,
    toggleOneContact,
    handleSave,
    saveDisabled,
    open
}) =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const { data: users } = useQuery({ ...QUERIES.utr.USERS(state.userInfo.company.companyUID), refetchOnWindowFocus: false });

    const userOptions = React.useCallback((propertyFilter) => users
        .reduce((acc, user) =>
        {
            if (!user.isSuspended)
            {
                if (propertyFilter)
                {
                    const assignedProperties = get(user, "assignedProperties", []);
                    const userHasProperty = assignedProperties.some(({ propertyId }) => Number(propertyId) === Number(propertyFilter));
                    if (userHasProperty)
                    {
                        acc.push({ key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}` });

                    }
                }
                else
                {
                    acc.push({ key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}` });
                }
            }
            return acc;
        },[]),[users]);

    const usedProperties = contacts.map((c) => c.PropertyId);
    const propertyOptions = Object.values(properties)
        .map((p) => ({
            key: p.propertyId,
            value: String(p.propertyId),
            text: p.propertyName,
            disabled: usedProperties.includes(String(p.propertyId))
        }));
    const hasUnusedProperty = propertyOptions.some((prop) => !prop.disabled);

    const { defaultContact, otherContacts } = React.useMemo(() =>
    {
        const defaultContact = contacts.find((c) => c.AllProperties);
        const otherContacts = contacts.filter((c) => !c.AllProperties);
        return { defaultContact, otherContacts };
    },[contacts]);

    const pagination = usePagination(3, otherContacts.length, 0, -1);

    const nProperties = Object.values(properties).length;

    const paginatedContact = React.useCallback((contacts, pagination) => contacts.slice(pagination.startIndex, pagination.endIndex),[]);


    const handleAddContact = () =>
    {
        addContact();
    };

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            trigger={trigger}
            className="roleContacts tabRoleModal"
            heading={<Trans
                i18nKey="UserTeamsRoles.Set_Category"
                values={{ category: category.title }}
            />}
            actions={<>
                <Button color="orange" floated="right" content={trans("UserTeamsRoles.Done")} onClick={handleSave} disabled={saveDisabled}/>
                {(nProperties < 1)
                    && <Button
                        basic
                        floated="right"
                        content={trans("UserTeamsRoles.Add_Properties")}
                        as="a"
                        href={"https://manage.mapsted.com/dashboard/property"}
                    />
                }
            </>}
        >
            <div className="aligner">
                <p className="pModal gap3">{`"${category.quote}"`}</p>

                {(nProperties > 1) && (
                    <CheckboxGroup secondary
                        checkboxProps={{
                            label: <Trans
                                i18nKey="UserTeamsRoles.Set_The_Same_Cateogry_For_All_Properties"
                                values={{ category: category.title }}
                            />,
                            checked: oneContact,
                            onChange: (e, { checked }) => toggleOneContact(checked)
                        }}
                    />
                )}
                <div className="defaultModal flexModal" style={{ marginTop: "20px" }}>
                    <InputWrapper label="Default Contact">
                        <DropdownGroup
                            dropdownProps={{
                                value: defaultContact && defaultContact.UserId,
                                options: userOptions(),
                                onChange: (e, { value, options }) =>
                                {
                                    modifyContact(defaultContact.index, { ...defaultContact, AllProperties: false });
                                    const optionIndex = options.findIndex((option) => option.value === value);
                                    const patchContact = otherContacts.find(({ index }) => index === optionIndex);
                                    modifyContact(optionIndex, { ...patchContact, AllProperties: true });
                                },
                                placeholder: trans("UserTeamsRoles.Choose_User"),
                                search: true
                            }}
                        />
                    </InputWrapper>
                </div>

                {(!oneContact) && (
                    <>
                        {paginatedContact(otherContacts, pagination)
                            .map((c, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={`${i}:${c.index}:${c.PropertyId}`}>
                                    <div className="defaultModal flexModal flexTwinModal">
                                        <InputWrapper label={trans("UserTeamsRoles.Property")}>
                                            <DropdownGroup
                                                dropdownProps={{
                                                    value: c.PropertyId,
                                                    options: propertyOptions,
                                                    onChange: (e, { value }) => modifyContact(c.index, { ...c, PropertyId: value }),
                                                    placeholder: trans("UserTeamsRoles.Select_Property"),
                                                    search: true
                                                }}
                                            />
                                        </InputWrapper>
                                        <InputWrapper label="Designated Contact">
                                            <DropdownGroup
                                                dropdownProps={{
                                                    value: c.UserId,
                                                    options: userOptions(c.PropertyId),
                                                    onChange: (e, { value }) => modifyContact(c.index, { ...c, UserId: value }),
                                                    placeholder: trans("UserTeamsRoles.Choose_User"),
                                                    search: true
                                                }}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <RoleActionButton
                                        img="remove-circle"
                                        content={trans("UserTeamsRoles.Remove_This_Contact")}
                                        color="red"
                                        onClick={() => removeContact(c.index)}
                                    />
                                </React.Fragment>
                            ))}

                        {(hasUnusedProperty && !oneContact && pagination.currentPage >= pagination.totalPages - 1) && <EditButton add content="Add more contacts" onClick={handleAddContact}/>}

                        {(otherContacts.length > pagination.pageSize) && (
                            <div className="paginationBar">
                                <Pagination
                                    className="paginationDashboard paginationModal"
                                    totalPages={pagination.totalPages}
                                    activePage={pagination.currentPage + 1}
                                    onPageChange={(e, { activePage }) => pagination.setPage(activePage - 1)}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                />
                            </div>
                        )}

                    </>
                )}
            </div>
        </ModalLayout>
    );
};

export default RoleContactModalView;

export const RoleActionButton = ({ img, content, color, onClick }) => (
    <Button className="buttonIcon roleActionButton" color={color} onClick={onClick}>
        <img src={`/img/icon-${img}.svg`} alt={img} />
        {content}
    </Button>
);
