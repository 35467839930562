export const mapstedWeb = {
    web: "https://mapsted.com/",
    contact: "https://mapsted.com/contact-us",
    contactMallMApp: "http://www.mall-mapp.com/contact-us",
};

export const CATEGORIES = [
    { id: "position", heading: "Dashboard.Location_Positioning_Technology", subheading: "Dashboard.Location_Positioning_Technology_Heading" },
    { id: "marketing", heading: "Dashboard.Location_Marketing_Technology", subheading: "Dashboard.Location_Marketing_Technology_Heading", color: "cyan" },
    { id: "analytics", heading: "Dashboard.Location_Analytics_Technology", subheading: "Dashboard.Location_Analytics_Technology_Heading", color: "orange" },
];

export const PRODUCTS = [
    {
        name: "sdk",
        heading: "Mapsted Maps - Mobile",
        img: "maps-mobile",
        description: "Dashboard.Mapsted_Maps_Mobile_Desc" ,
        category: "position",
        // baseURL: "https://developer.mapsted.com/",
        licenceKey: ["sdk_licence"],
        active: true,
    },
    {
        name: "maps",
        heading: "Mapsted Maps - Web",
        img: "maps-web",
        description: "Dashboard.Mapsted_Maps_Web_Desc",
        category: "position",
        // baseURL: "https://developer.mapsted.com/",
        licenceKey: ["manage_cms"],
        active: true,
    },
    {
        name: "manageCms",
        heading: "Manage CMS",
        img: "manage-cms",
        description: "Dashboard.Manage_CMS_Desc",
        category: "position",
        // baseURL: "https://manage.mapsted.com/",
        needsToken: true,
        licenceKey: ["manage_cms"],
        active: true,
    },
    {
        name: "notify",
        heading: "Notify CRM",
        img: "notify-crm",
        description: "Dashboard.Notify_CRM_Desc",
        category: "marketing",
        // baseURL: "https://notify.mapsted.com/",
        needsToken: true,
        licenceKey: ["notify"],
        active: true
    },
    // {
    //     heading: "Mapsted Audience",
    //     img: "audience",
    //     description: "Comprehensive market segment insights",
    //     category: "marketing"
    // },
    {
        name: "analytics",
        heading: "Mapsted Analytics",
        img: "analytics",
        description: "Dashboard.Mapsted_Analytics_Desc",
        category: "analytics",
        // baseURL: "https://analytics.mapsted.com",
        licenceKey: ["analytics"],
        active: true,
    },
    // {
    //     heading: "Mapsted Monitor",
    //     img: "monitor",
    //     description: "Resource and incident management",
    //     category: "analytics",
    // }
];
