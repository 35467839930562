import React, { useContext, useState } from "react";
import { Tab, Input, Button } from "semantic-ui-react";
import { useQuery } from "react-query";
import { PaginationBar } from "../../../paymentBilling/components/PaginationBar";
import { usePagination } from "../../../../_utils/stateHooks";
import { InviteUserModal } from "./inviteUserModal/InviteUserModal";
import { TableTabUsers } from "./TableTabUsers";
import { QUERIES } from "../../../../_api/queries";
import { AuthContext } from "../../../../_store/AuthProvider";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { BulkDeleteModal } from "./newComponents/BulkDeleteModal";
import { useSetState } from "ahooks";
import { useTranslation } from "react-i18next";

export const TabUsers = () =>
{
    const trans = useTranslation().t;

    const { state: authCtxState } = useContext(AuthContext);
    const companyUID = authCtxState.userInfo.company.companyUID;
    const { data: userData, isLoading, status, refetch } = useQuery(QUERIES.utr.USERS(companyUID));
    const [state, setState] = useSetState({
        inviteUserModalOpen: false,
        bulkDeleteModalOpen: false,
        selectedUsers: [],
    });


    // Pagination + Search
    const [searchFilter, setSearchFilter] = useState("");
    const filteredUsers = userData
        ?.filter?.((user) => searchFilter === "" || `${user.firstName} ${user.lastName} ${user.email}`.toUpperCase().includes(searchFilter.toUpperCase()))
        ?.sort((a, b) =>
        {
            if (a.isCompanyAdmin)
            {
                return -1;
            }
            else if (b.isCompanyAdmin)
            {
                return 1;
            }
            return a?.firstName?.localeCompare(b?.firstName);
        }) || [];

    const { currentPage, totalPages, setPage, startIndex, endIndex } = usePagination(6, filteredUsers.length);
    const shownUsers = filteredUsers?.slice?.(startIndex, endIndex);
    const allUsersSelected = state.selectedUsers.length === userData?.length - 1;


    const handleSelectUser = (user) =>
    {
        const selectedUsers = [...state.selectedUsers];
        const i = state.selectedUsers.findIndex((u) => u.id === user.id);
        if (i === -1)
        {
            selectedUsers.push(user);
        }
        else
        {
            selectedUsers.splice(i, 1);
        }
        setState({ selectedUsers });
    };

    const handleSelectAllUsers = () =>
    {
        if (allUsersSelected)
        {
            return setState({ selectedUsers: [] });
        }

        setState({ selectedUsers: userData.filter((u) => !u.isCompanyAdmin) });
    };
    return (
        <Tab.Pane>
            <div className="defaultWrap flexible">
                <Input disabled={isLoading} light="true" className="inputForm searchDashboard" icon="search" placeholder={trans("UserTeamsRoles.Search_Users")} onChange={(e, { value }) => setSearchFilter(value)} />
                {/*Filter is for v5.1: <Button disabled className="buttonIcon iconFilter" icon={<img src={"/img/icon-filter.svg"} style={{ marginRight: 4 }} alt="add" />} content="Filter" />*/}

                <ButtonIcon
                    icon="bulk-delete"
                    content={trans("UserTeamsRoles.Delete_Bulk")}
                    className="bulkDelete"
                    disabled={state.selectedUsers.length === 0}
                    onClick={() => setState({ bulkDeleteModalOpen: true })}
                />
                <BulkDeleteModal
                    open={state.bulkDeleteModalOpen}
                    users={state.selectedUsers}
                    onToggleSelect={handleSelectUser}
                    onDeleteSuccess={() => setState({ selectedUsers: [] })}
                    onClose={() => setState({ bulkDeleteModalOpen: false })}
                />

                <Button disabled={isLoading} className="invite-user-button" color="orange" floated="right" content={trans("UserTeamsRoles.Invite_Users")} onClick={() => setState({ inviteUserModalOpen: true })} />
                <InviteUserModal
                    open={state.inviteUserModalOpen}
                    setOpen={(open) => setState({ inviteUserModalOpen: open })}
                    refetchUsers={refetch}
                />
            </div>
            <TableTabUsers
                loadingStatus={status}
                users={shownUsers}
                selectedUsers={state.selectedUsers}
                allUsersSelected={allUsersSelected}
                onSelectUser={handleSelectUser}
                onSelectAllUsers={handleSelectAllUsers}
            />
            {(status === "success") && (shownUsers.length > 0)
                && <PaginationBar
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setPage={setPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalRows={filteredUsers?.length}
                />
            }
        </Tab.Pane>
    );
};
