import { useSetState } from "ahooks";
import React from "react";
import { Button, Input } from "mapsted-components";
import { Segment, Button as SemanticButton } from "semantic-ui-react";
import LoginSection from "./LoginSection";
import { validatePassword } from "../../../_utils/misc";
import { useTranslation } from "react-i18next";


const ResetPassword = ({ errorMessage, successMessage, onGoBack, password, setPassword, onResetPassword }) =>
{
    const [state, setState] = useSetState({
        isValidLength: false,
        isUpperCase: false,
        isLowerCase: false,
        isDigit: false,
        isSpecial: false,
        confirmPassword: ""
    });

    const trans = useTranslation().t;

    const renderInputBody = () =>
    {

        const validPassword = state.isValidLength && state.isUpperCase && state.isLowerCase && state.isDigit && state.isSpecial;
        let isSubmitDisabled = state.loading
            || !password
            || password !== state.confirmPassword
            || !validPassword;

        const buttonCSS = isSubmitDisabled ? "disabled" : "";

        return (
            <>
                <div className="inputForm">
                    <b>{trans("Login.Password")}:</b>
                    <Input
                        type="password"
                        autoComplete="off"
                        name="password"
                        id="password"
                        onChange={(e) =>
                        {
                            setState(validatePassword(e.target.value));
                            setPassword(e.target.value);
                        }}
                    />
                </div>

                <div className="inputForm">
                    <b>{trans("Login.Confirm_Password")}:</b>
                    <Input
                        type="password"
                        autoComplete="off"
                        name="confirmPassword"
                        id="password"
                        onChange={(e) => setState({ confirmPassword: e.target.value })}
                    />
                </div>

                <ul className="password-requirements">
                    <li className={state.isDigit ? "validPassword" : "invalidPassword"}>{trans("Login.Password_Must_Contain_Numbers")}</li>
                    <li className={state.isUpperCase ? "validPassword" : "invalidPassword"}>{trans("Login.Password_Must_Contain_Uppercase_Letters")}</li>
                    <li className={state.isSpecial ? "validPassword" : "invalidPassword"}>{trans("Login.Password_Must_Contain_Symbols")}</li>
                    <li className={state.isValidLength ? "validPassword" : "invalidPassword"}>{trans("Login.Password_Must_Have_Eight_Characters")}</li>
                </ul>

                <div className="login-action" hidden={false}>
                    <SemanticButton
                        type="submit"
                        className={`loginButton ${buttonCSS}`}
                        onClick={onResetPassword}
                        disabled={isSubmitDisabled}
                        content={trans("Login.Submit")}
                    />
                </div>
                { state.password && state.confirmPassword && state.password !== state.confirmPassword && (
                    <span className="invalidPassword">{trans("Login.Password_Confirm_Password_Doesnot_Match")}</span> ) }
                <p className="para-login">
                    <Button onClick={onGoBack}>{trans("Login.Back_To_Login")}?</Button>
                </p>
            </>
        );
    };

    return (
        <LoginSection heading={trans("Login.Create_New_Password")}>
            <Segment basic className="login-wrap reset-wrap">
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}
                    <Button basic={false} onClick={onGoBack}> {trans("Login.Here")} </Button></p>}
                {!successMessage && renderInputBody()}
            </Segment>
        </LoginSection>
    );
};

export default ResetPassword;
