import React from "react";
import PropTypes from "prop-types";
import { useSetState } from "ahooks";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { Input, Dropdown } from "semantic-ui-react";
import { useQuery } from "react-query";
import examples from "libphonenumber-js/examples.mobile.json";
import { isSupportedCountry, getCountryCallingCode, getExampleNumber, parsePhoneNumberWithError, formatIncompletePhoneNumber, AsYouType } from "libphonenumber-js";

import "./PhoneBox.css";
import { QUERIES } from "../../../../../_api/queries";
import { useTranslation } from "react-i18next";

const PhoneBox = ({ value, error, onChange, onValidate, className }) =>
{

    const countries = useQuery(QUERIES.places.GET_COUNTRIES());

    const options = React.useMemo(() =>
    {
        if (countries.isSuccess)
        {

            return countries.data.reduce((acc, country) =>
            {
                const countryCode = get(country, "alpha2");

                if (!isEmpty(countryCode) && isSupportedCountry(countryCode))
                {
                    // const callingCodes = get(country, "countryCallingCodes", []);

                    const callingCode = "+" + getCountryCallingCode(countryCode);

                    acc.push({
                        text: callingCode,
                        key: `${countryCode}-${callingCode}`,
                        value: countryCode,
                        flag: countryCode.toLowerCase()
                    });

                }

                return acc;
            },[]);
        }
        return [];
    },[countries.data, countries.isSuccess]);

    const trans = useTranslation().t;

    const placeholder = React.useCallback((flag) => getExampleNumber(flag, examples)?.nationalNumber || trans("Phonebox.Enter_Mobile_Number"),[]);

    const values = React.useMemo(() =>
    {
        try 
        {

            const phoneNum = parsePhoneNumberWithError(value || "");

            return {
                flag: phoneNum.country,
                number: formatIncompletePhoneNumber(phoneNum.nationalNumber, phoneNum.country)
            };
        }
        catch (error) 
        {
            // if (error instanceof ParseError) {
            //     // Not a phone number, non-existent country, etc.
            //     console.log(error.message)
            // } else {
            //     console.log("eeeeee", error);
            // }

        }

        return {};

    },[value]);

    const [state, setState] = useSetState({ flag: values.flag || "CA", placeholder: placeholder(values.flag) });

    const handleChange = React.useCallback((num = "", flag = "") =>
    {

        const ayt = new AsYouType(flag);

        ayt.input(num);

        setState({ flag, placeholder: placeholder(flag) });

        onChange(ayt.getNumber()?.number || num);
        onValidate(Boolean(ayt.getNumber()?.isValid()));


    },[onChange, onValidate, placeholder, setState]);

    const handleFlagChange = React.useCallback((e, { value }) => handleChange(values.number, value),[handleChange, values.number]);

    const handleNumberChange = React.useCallback((e, { value }) => handleChange(value, state.flag), [handleChange, state.flag]);


    return (
        <Input
            className={className}
            label={<Dropdown selectOnBlur={false} search selection defaultValue={"CA"} value={state.flag} options={options} onChange={handleFlagChange} disabled={countries.isLoading} loading={countries.isLoading} />}
            labelPosition="left"
            placeholder={placeholder(state.flag)}
            onChange={handleNumberChange}
            value={values.number}
            error={error}
        />
    );

};

PhoneBox.propTypes = {
    /** Leave undefined for uncontrolled */
    value: PropTypes.string,
    error: PropTypes.bool.isRequired,
    /**
     * Fired when country or text is changed
     * `(val) => null`.
     * `val` has form `+{areacode} {number}`
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Fires when a validation check happens on the phone number.
     * Returns either `true` or `false`.
     */
    onValidate: PropTypes.func,
    /** classes added to the container */
    className: PropTypes.string
};
PhoneBox.defaultProps = {
    className: "",
    onValidate: () => null,
    error: false
};

export default PhoneBox;
