import React, { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Feedback, LoadingScreen } from "mapsted-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "react-notifications-component/dist/theme.css";

import serverApi from "../_api/server.api";
import { AuthContext } from "../_store/AuthProvider";

import Dashboard from "./dashboard";
import Settings from "./settings";
import Login from "./login/Login";
import { getCallbackFromUrl, windowRedirect } from "../_utils/browser";


const App = () =>
{
    const trans = useTranslation().t;

    const { pathname } = useLocation();
    const authContext = useContext(AuthContext);
    const callbackLoginLink = getCallbackFromUrl(window.location.href);

    const renderLoginPage = () => (
        <>
            <Helmet>
                <title>Login | Mapsted Hub</title>
                <meta name="description" content="Mapsted Hub - Indoor Positioning System Customer Login" />
            </Helmet>
            <Login cb={callbackLoginLink} />
        </>
    );

    if (authContext.state.validatingToken)
    {
        return <LoadingScreen />;
    }
    if (!authContext.state.token)
    {
        return renderLoginPage();
    }
    if (callbackLoginLink && !pathname.includes("login"))
    {
        windowRedirect(callbackLoginLink, authContext.state.token);
    }
    return (
        <>
            <Switch>
                <Route path="/login/:source?" render={() => renderLoginPage()} />
                <Route path="/settings" component={Settings}/>
                <Route path="/" exact component={Dashboard}/>
                <Redirect to="/" />
            </Switch>
            <Feedback handleSubmit={serverApi.sendFeedback}
                handleSubmitError={console.log}
                location="bottom-left"
                text={{
                    sendFeedbackButton: trans("App.Send_Feedback"),
                    thankYou: trans("App.Thank_You"),
                    header: trans("App.Your_opinion_is_important_to_us"),
                    highlightDesc: trans("App.Drag_to_highlight_part_of_the_page_"),
                    includeScreenshot: trans("App.Include_a_screenshot"),
                    textAreaPlaceholder: trans("App.We’d_love_to_hear_your_feedback!_Tell_us"),
                    submitButton: trans("App.Submit_Feedback"),
                    cancelButton: trans("App.Cancel"),
                }}
            />
        </>
    );
};

export default App;
