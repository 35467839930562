import React, { useContext, useState } from "react";
import { Tab, Input } from "semantic-ui-react";
import { PaginationBar } from "../../../paymentBilling/components/PaginationBar";
import { AddTeamModal } from "./addTeamModal/AddTeamModal";
import { TableTabTeams } from "./TableTabTeams";
import { AuthContext } from "../../../../_store/AuthProvider";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../_api/queries";
import { usePagination } from "../../../../_utils/stateHooks";
import { useTranslation } from "react-i18next";

export const TabTeams = () =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const { data: teamData, isLoading, status, refetch: refetchTeams } = useQuery(QUERIES.utr.TEAMS(state.userInfo.company.companyUID));

    // Pagination + Search
    const [searchFilter, setSearchFilter] = useState("");
    const filteredTeams = teamData
        ?.filter?.((team) => searchFilter === "" || team.name.toString().toUpperCase().includes(searchFilter.toUpperCase()))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [];
    const { currentPage, totalPages, setPage, startIndex, endIndex } = usePagination(6, filteredTeams.length);
    const shownTeams = filteredTeams?.slice?.(startIndex, endIndex);

    return (
        <Tab.Pane>
            <div className="defaultWrap flexible">
                <Input disabled={isLoading} light="true" className="inputForm searchDashboard" icon="search" placeholder={trans("UserTeamsRoles.Search_Teams")} onChange={(e, { value }) => setSearchFilter(value)} />
                {/*Filter is for v5.1: <Button className="buttonIcon iconFilter" icon={<img src={"/img/icon-filter.svg"} style={{ marginRight: 4 }} alt="add" />} content="Filter" />*/}
                <AddTeamModal refetchTeams={refetchTeams} disabled={isLoading} teamNames={teamData?.map?.((team) => team.name)} />
            </div>
            <TableTabTeams teams={shownTeams} status={status} refetchTeams={refetchTeams} />
            {(status === "success") && (shownTeams.length > 0)
                && <PaginationBar
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setPage={setPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalRows={filteredTeams?.length}
                />
            }
        </Tab.Pane>
    );
};