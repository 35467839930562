import React, { useContext } from "react";
import { Button } from "semantic-ui-react";
import { DefaultContact, DesignatedContact } from "./Contact";
import { RoleContactModal } from "./RoleContactModal";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../../_api/queries";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import { TextGroup } from "../../../../common/TextGroup";
import { useTranslation } from "react-i18next";

const Role = ({ category, contacts, properties, refreshContacts, propertyFilter }) =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const { data: users } = useQuery({ ...QUERIES.utr.USERS(state.userInfo.company.companyUID), refetchOnWindowFocus: false });

    const defaultContact = {
        AllProperties: true,
        CompanyUID: state.userInfo.company.companyUID,
        ContactType: category.id,
        PropertyId: "0",
        UserId: state.userInfo.id,
    };

    let usableContacts = contacts?.filter((c) =>
    {
        if ((propertyFilter !== "0") && c.PropertyId !== "0" && c.PropertyId !== propertyFilter)
        {
            return false;
        }
        let user = users?.find((u) => c.UserId === u.id);
        if (!user || user.isSuspended)
        {
            return false;
        }
        return true;
    });

    if (!usableContacts?.some((c) => c.AllProperties))
    {
        usableContacts = [defaultContact, ...usableContacts];
    }

    // const renderContacts = () => usableContacts?.map((c) => <Contact key={c.UserId + c.PropertyId} contact={c} users={users} properties={properties} />);
    const renderDefaultContact = () => usableContacts?.filter((contact) => contact.AllProperties)?.map((c) => <DefaultContact key={c.UserId + c.PropertyId} contact={c} users={users} />);
    const renderDesignatedContact = () => usableContacts?.filter((contact) => !contact.AllProperties)?.map((c) => <DesignatedContact key={c.UserId + c.PropertyId} contact={c} users={users} />);

    // we want to show default contact + all property contacts that exist for each role in the edit modal:
    let usableContactsForEditModal = contacts?.filter((c) =>
    {
        let user = users?.find((u) => c.UserId === u.id);
        if (!user || user.isSuspended)
        {
            return false;
        }
        return true;
    });

    if (!usableContactsForEditModal?.some((c) => c.AllProperties))
    {
        usableContactsForEditModal = [defaultContact, ...usableContactsForEditModal];
    }

    return (
        <div className="tableBoxRow">
            <TableBoxColumn>
                <b>{trans(category.title)}</b>
                <p>{trans(category.quote)}</p>
            </TableBoxColumn>
            <TableBoxColumn>
                {renderDefaultContact()}
            </TableBoxColumn>
            <TableBoxColumn>
                {renderDesignatedContact()}
                {usableContacts?.filter((contact) => !contact.AllProperties)?.length < 1 && <TextGroup title="—" />}
            </TableBoxColumn>
            <TableBoxColumn narrow>
                <RoleContactModal
                    category={category}
                    contacts={usableContactsForEditModal}
                    properties={properties}
                    trigger={<Button className="buttonIcon">
                        <img src="/img/icon-edit.svg" alt="edit" />
                    </Button>}
                    refreshContacts={refreshContacts}
                />
            </TableBoxColumn>
        </div>
    );
};

export default Role;
