import { isEmpty } from "lodash";
import { LoadingScreen } from "mapsted-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Radio } from "semantic-ui-react";
import { QUERIES, useMutationsList } from "../../../../../_api/queries";
import { ContentGroup } from "../../../../common/ContentGroup";
import { DividerLine } from "../../../../common/DividerLine";
import { SectionSettings } from "../../SectionSettings";
import { ManagedDomains } from "./managedDomains";
import SAMLConfiguration from "./SAML";
import { Trans, useTranslation } from "react-i18next";

const TITLE = "SamlSingleSignIn.Saml_Single_Signon";

const OPTIONS = {
    MANAGED_DOMAINS: "SamlSingleSignIn.Managed_Domains",
    SAML_CONFIG: "SamlSingleSignIn.Saml_Single_Signon"
};
export const SAMLSingleSignOn = ({ addBreadCrumb, removeBreadCrumbAfter }) =>
{
    const [selectedOption, setSelectedOption] = useState("");
    const { data: domains = [], isLoading: getDomainsLoading } = useQuery(QUERIES.GET_DOMAINS());
    const { data: samlConfig = {}, isLoading: samlConfigLoading } = useQuery(QUERIES.GET_SAML_CONFIG());
    const hasVerifiedDomain = domains.some((d) => d.isVerified);
    const trans = useTranslation().t;


    useEffect(() =>
    {
        addBreadCrumb({
            name: trans(TITLE),
            onClick()
            {
                setSelectedOption("");
                // remove all breadcrums after current one
                removeBreadCrumbAfter(trans(TITLE));
            }
        });
    }, []);

    return (
        <>
            {(getDomainsLoading || samlConfigLoading) && <LoadingScreen />}

            <SectionSettings>
                {isEmpty(selectedOption) && (
                    <InitialScreen
                        samlConfig={samlConfig}
                        hasVerifiedDomain={hasVerifiedDomain}
                        setSelectedOption={setSelectedOption}
                    />
                )}

                {selectedOption === trans(OPTIONS.MANAGED_DOMAINS) && (
                    <ManagedDomains
                        domains={domains}
                        addBreadCrumb={addBreadCrumb}
                        removeBreadCrumbAfter={removeBreadCrumbAfter}
                    />
                )}
                {selectedOption === trans(OPTIONS.SAML_CONFIG) && (
                    <SAMLConfiguration
                        samlConfig={samlConfig}
                        addBreadCrumb={addBreadCrumb}
                        removeBreadCrumbAfter={removeBreadCrumbAfter}
                    />
                )}

            </SectionSettings>
        </>
    );
};

const InitialScreen = ({ hasVerifiedDomain, samlConfig, setSelectedOption }) =>
{
    const trans = useTranslation().t;

    const { mutate: toggleSSO, isLoading } = useMutation(useMutationsList().TOGGLE_SSO());

    return (
        <>
            {isLoading && <LoadingScreen/>}

            <p className="paragraph">
                {trans("SamlSingleSignIn.Single_Signon_Desc")}
            </p>
            <div className="toggleSingleSignWrap">
                <Radio toggle className="toggleStepVerification"
                    disabled={!hasVerifiedDomain || isEmpty(samlConfig)}
                    checked={samlConfig.isEnabled}
                    onChange={(e, { checked }) => toggleSSO(checked)}
                />
                <p>
                    {trans("SamlSingleSignIn.Saml_Single_Signon")}
                    {/* 2 Jul 2021 5:14 PM */}
                    {samlConfig.isEnabled && <span>
                        <Trans 
                            i18nKey="SamlSingleSignIn.You_Have_Kept_Account_Secure"
                            values={{ date: moment.utc(samlConfig.enabledAt).local().format("D MMM YYYY h:mm A") }}
                        />
                    </span>}
                    {/* {samlConfig.isEnabled && <span>You’ve kept your account extra secure with a SAML single sign-on</span>} */}
                </p>
            </div>

            <DividerLine gap="30px 0" />

            {isEmpty(samlConfig) && <SAMLInfo />}

            <div className="securityButtonsGroup">
                <Button color="orange" basic content={trans("SamlSingleSignIn.Managed_Domains")} onClick={() => setSelectedOption(trans(OPTIONS.MANAGED_DOMAINS))} />
                <Button color="orange" basic
                    disabled={!hasVerifiedDomain}
                    content={`${isEmpty(samlConfig) ? trans("SamlSingleSignIn.Add_SAML_Configuration") : trans("SamlSingleSignIn.Edit_SAML_Configuration")}`}
                    onClick={() => setSelectedOption(trans(OPTIONS.SAML_CONFIG))}
                />

            </div>
        </>
    );
};

const SAMLInfo = () => 
{
    const trans = useTranslation().t;

    return (
        <>
            <ContentGroup heading={trans("SamlSingleSignIn.SAML_Configuration")} asHeading="h5">
                <h3>{trans("SamlSingleSignIn.You_Need_To_Verify_Domain")}</h3>
                <p className="paragraph">
                    {trans("SamlSingleSignIn.Configure_Saml_Info")}
                </p>
            </ContentGroup>
            <DividerLine isEmpty gap="5px 0" />
            <ContentGroup heading={trans("SamlSingleSignIn.Add_SAML_Configuration")} asHeading="h5">
                <p className="paragraph">
                    {trans("SamlSingleSignIn.After_Initial_Setup_Desc")}
                </p>
            </ContentGroup>
        </>
    );
};
