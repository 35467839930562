import { isEmpty, isInteger } from "lodash";
import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Button, Icon, Input, Pagination, Placeholder, Popup } from "semantic-ui-react";
import { QUERIES } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { DEFAULT_SESSION_DURATION } from "../../../../../_utils/constants";
import { usePagination } from "../../../../../_utils/stateHooks";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import { SettingsPopupLayout } from "../../SettingsPopupLayout";
import { Trans, useTranslation } from "react-i18next";

export const UserBasedConfiguration = ({ usersDurationMins, onDurationChange }) =>
{
    const trans = useTranslation().t;

    const { state: { userInfo } } = useContext(AuthContext);
    const { data: users = [], isLoading } = useQuery({
        ...QUERIES.utr.USERS(userInfo.company.companyUID),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select: (data) => sortUsers(data)
    });
    const { currentPage, totalPages, setPage, startIndex, endIndex } = usePagination(5, users.length);

    const currentPageUsers = useMemo(() => users.slice(startIndex, endIndex));

    return (
        <div className="userBasedConfiguration">
            <div className="tableboxCover">
                <div className="tableBox tableBoxUserBased">
                    <div className="tableBoxRow">
                        <TableBoxColumn detail={trans("SessionConfiguration.Full_Name")} />
                        <TableBoxColumn detail={trans("SessionConfiguration.Team")} />
                        <TableBoxColumn detail={trans("SessionConfiguration.Session_Timeout")} />
                    </div>

                    {isLoading ? <EmptyRows /> : currentPageUsers.map((user) => (
                        <UserRow
                            key={user.id}
                            user={user}
                            duration={isInteger(usersDurationMins[user.id]) ? usersDurationMins[user.id] : DEFAULT_SESSION_DURATION}
                            onDurationChange={onDurationChange}
                        />
                    ))}

                </div>
                {!isLoading && (
                    <div className="paginationBar">
                        <Pagination
                            className="paginationDashboard"
                            totalPages={totalPages}
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name="angle left"/>, icon: true }}
                            nextItem={{ content: <Icon name="angle right"/>, icon: true }}
                            activePage={currentPage + 1}
                            onPageChange={(e, { activePage }) => setPage(activePage - 1)}
                            boundaryRange={1}
                            siblingRange={1}
                        />
                        <div className="displayText">
                            <span>
                                <Trans 
                                    i18nKey="SessionConfiguration.Pagination_Text"
                                    values={{ startIndex: startIndex + 1, endIndex: endIndex + 1, users: users.length }}
                                />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};


const UserRow = ({ user, duration, onDurationChange }) => (
    <div className="tableBoxRow">
        <TableBoxColumn>
            <div className="checkboxUserBased">
                {/* <CheckboxGroup /> */}
                <p>{`${user.firstName} ${user.lastName}`}
                    <span>{user.email}</span>
                </p>
            </div>
        </TableBoxColumn>

        <TableBoxColumn>
            {isEmpty(user.teams) ? "—" : <TeamsPopup teams={user.teams} />}
        </TableBoxColumn>

        <TableBoxColumn>
            <Input
                className="formControl"
                placeholder="e.g. 3600"
                value={duration}
                onChange={(e, { value }) => onDurationChange(user, value)}
            />
        </TableBoxColumn>
    </div>
);

const TeamsPopup = ({ teams }) => 
{
    const trans = useTranslation().t;
    return (
        <Popup className="popupBox hasPopup"
            trigger={<Button className="buttonIcon" content={`${teams.length} teams`} />}
            on="click"
            position="bottom center"
        >
            <SettingsPopupLayout heading={trans("SessionConfiguration.Teams")}>
                {teams.map((team) => <p key={team.id}>{team.name}</p>)}
            </SettingsPopupLayout>
        </Popup>
    );
};

const EmptyRows = ({ count = 5 }) => new Array(count).fill(undefined).map((_, i) => (
    <div key={i} className="tableBoxRow">
        <TableBoxColumn>
            <Placeholder></Placeholder>
        </TableBoxColumn>

        <TableBoxColumn>
            <Placeholder></Placeholder>
        </TableBoxColumn>

        <TableBoxColumn>
            <Placeholder></Placeholder>
        </TableBoxColumn>
    </div>
));

const sortUsers = (users = []) => users.sort((a, b) =>
{
    if (a.isCompanyAdmin) return -1;
    if (b.isCompanyAdmin) return 1;
    return a?.firstName?.localeCompare(b?.firstName);
});
