export const stripeSupportedCurrencies = new Set(
    ["USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BIF", "BMD", "BND", "BOB", "BRL", "BSD", "BWP", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", "ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF", "IDR", "ILS", "INR", "ISK", "JMD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "MAD", "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MRO", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "PAB", "PEN", "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD", "SCR", "SEK", "SGD", "SHP", "SLL", "SOS", "SRD", "STD", "SZL", "THB", "TJS", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU", "UZS", "VND", "VUV", "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR", "ZMW"]
);

export const currencyOptions = [
    { value: "ALL", text: "Albania Lek (Lek)" },
    { value: "AFN", text: "Afghanistan Afghani (؋)", noAMX: true },
    { value: "ARS", text: "Argentina Peso ($)", noAMX: true },
    { value: "AWG", text: "Aruba Guilder (ƒ)" },
    { value: "AUD", text: "Australia Dollar ($)" },
    { value: "AZN", text: "Azerbaijan New Manat (ман)" },
    { value: "BSD", text: "Bahamas Dollar ($)" },
    { value: "BBD", text: "Barbados Dollar ($)" },
    { value: "BYR", text: "Belarus Ruble (p.)" },
    { value: "BZD", text: "Belize Dollar (BZ$)" },
    { value: "BMD", text: "Bermuda Dollar ($)" },
    { value: "BOB", text: "Bolivia Boliviano ($b)", noAMX: true },
    { value: "BAM", text: "Bosnia and Herzegovina Convertible Marka (KM)" },
    { value: "BWP", text: "Botswana Pula (P)" },
    { value: "BGN", text: "Bulgaria Lev (лв)" },
    { value: "BGL", text: "Bulgaria Lev (лв)" },
    { value: "BRL", text: "Brazil Real (R$)", noAMX: true },
    { value: "BND", text: "Brunei Darussalam Dollar ($)" },
    { value: "KHR", text: "Cambodia Riel (៛)" },
    { value: "CAD", text: "Canada Dollar ($)" },
    { value: "KYD", text: "Cayman Islands Dollar ($)" },
    { value: "CLP", text: "Chile Peso ($)", noAMX: true, precision: 0 },
    { value: "CNY", text: "China Yuan Renminbi (¥)" },
    { value: "COP", text: "Colombia Peso ($)" },
    { value: "CRC", text: "Costa Rica Colon (₡)" },
    { value: "HRK", text: "Croatia Kuna (kn)" },
    { value: "CUP", text: "Cuba Peso (₱)" },
    { value: "CZK", text: "Czech Republic Koruna (Kč)" },
    { value: "DKK", text: "Denmark Krone (kr)" },
    { value: "DOP", text: "Dominican Republic Peso (RD$)" },
    { value: "XCD", text: "East Caribbean Dollar ($)" },
    { value: "EGP", text: "Egypt Pound (£)" },
    { value: "SVC", text: "El Salvador Colon ($)" },
    { value: "EEK", text: "Estonia Kroon (kr)" },
    { value: "EUR", text: "Euro Member Countries (€)" },
    { value: "FKP", text: "Falkland Islands (Malvinas) Pound (£)", noAMX: true },
    { value: "FJD", text: "Fiji Dollar ($)" },
    { value: "GHC", text: "Ghana Cedis (¢)" },
    { value: "GIP", text: "Gibraltar Pound (£)" },
    { value: "GTQ", text: "Guatemala Quetzal (Q)", noAMX: true },
    { value: "GGP", text: "Guernsey Pound (£)" },
    { value: "GYD", text: "Guyana Dollar ($)" },
    { value: "HNL", text: "Honduras Lempira (L)", noAMX: true },
    { value: "HKD", text: "Hong Kong Dollar ($)" },
    { value: "HUF", text: "Hungary Forint (Ft)", noAMX: true },
    { value: "ISK", text: "Iceland Krona (kr)" },
    { value: "INR", text: "Indian Rupee (₹)", noAMX: true },
    { value: "IDR", text: "Indonesia Rupiah (Rp)" },
    { value: "IRR", text: "Iran Rial (﷼)" },
    { value: "IMP", text: "Isle of Man Pound (£)" },
    { value: "ILS", text: "Israel Shekel (₪)" },
    { value: "JMD", text: "Jamaica Dollar (J$)" },
    { value: "JPY", text: "Japan Yen (¥)", precision: 0 },
    { value: "JEP", text: "Jersey Pound (£)" },
    { value: "KZT", text: "Kazakhstan Tenge (лв)" },
    { value: "KPW", text: "Korea (North) Won (₩)" },
    { value: "KRW", text: "Korea (South) Won (₩)", precision: 0 },
    { value: "KGS", text: "Kyrgyzstan Som (лв)" },
    { value: "LAK", text: "Laos Kip (₭)", noAMX: true },
    { value: "LVL", text: "Latvia Lat (Ls)" },
    { value: "LBP", text: "Lebanon Pound (£)" },
    { value: "LRD", text: "Liberia Dollar ($)" },
    { value: "LTL", text: "Lithuania Litas (Lt)" },
    { value: "MKD", text: "Macedonia Denar (ден)" },
    { value: "MYR", text: "Malaysia Ringgit (RM)" },
    { value: "MUR", text: "Mauritius Rupee (₨)", noAMX: true },
    { value: "MXN", text: "Mexico Peso ($)" },
    { value: "MNT", text: "Mongolia Tughrik (₮)" },
    { value: "MZN", text: "Mozambique Metical (MT)" },
    { value: "NAD", text: "Namibia Dollar ($)" },
    { value: "NPR", text: "Nepal Rupee (₨)" },
    { value: "ANG", text: "Netherlands Antilles Guilder (ƒ)" },
    { value: "NZD", text: "New Zealand Dollar ($)" },
    { value: "NIO", text: "Nicaragua Cordoba (C$)", noAMX: true },
    { value: "NGN", text: "Nigeria Naira (₦)" },
    { value: "NOK", text: "Norway Krone (kr)" },
    { value: "OMR", text: "Oman Rial (﷼)" },
    { value: "PKR", text: "Pakistan Rupee (₨)" },
    { value: "PAB", text: "Panama Balboa (B/.)", noAMX: true },
    { value: "PYG", text: "Paraguay Guarani (Gs)", noAMX: true, precision: 0 },
    { value: "PEN", text: "Peru Nuevo Sol (S/.)", noAMX: true },
    { value: "PHP", text: "Philippines Peso (₱)" },
    { value: "PLN", text: "Poland Zloty (zł)" },
    { value: "QAR", text: "Qatar Riyal (﷼)" },
    { value: "RON", text: "Romania New Leu (lei)" },
    { value: "RUB", text: "Russia Ruble (руб)" },
    { value: "SHP", text: "Saint Helena Pound (£)", noAMX: true },
    { value: "SAR", text: "Saudi Arabia Riyal (﷼)" },
    { value: "RSD", text: "Serbia Dinar (Дин.)" },
    { value: "SCR", text: "Seychelles Rupee (₨)" },
    { value: "SGD", text: "Singapore Dollar ($)" },
    { value: "SBD", text: "Solomon Islands Dollar ($)" },
    { value: "SOS", text: "Somalia Shilling (S)" },
    { value: "ZAR", text: "South Africa Rand (R)" },
    { value: "LKR", text: "Sri Lanka Rupee (₨)" },
    { value: "SEK", text: "Sweden Krona (kr)" },
    { value: "CHF", text: "Switzerland Franc (CHF)" },
    { value: "SRD", text: "Suriname Dollar ($)", noAMX: true },
    { value: "SYP", text: "Syria Pound (£)" },
    { value: "TWD", text: "Taiwan New Dollar (NT$)" },
    { value: "THB", text: "Thailand Baht (฿)" },
    { value: "TTD", text: "Trinidad and Tobago Dollar (TT$)" },
    { value: "TRY", text: "Turkey Lira (TL)" },
    { value: "TRL", text: "Turkey Lira (₤)" },
    { value: "TVD", text: "Tuvalu Dollar ($)" },
    { value: "UAH", text: "Ukraine Hryvna (₴)" },
    { value: "GBP", text: "United Kingdom Pound (£)" },
    { value: "USD", text: "United States Dollar ($)" },
    { value: "UYU", text: "Uruguay Peso ($U)", noAMX: true },
    { value: "UZS", text: "Uzbekistan Som (лв)" },
    { value: "VEF", text: "Venezuela Bolivar Fuerte (Bs)" },
    { value: "VND", text: "Viet Nam Dong (₫)", precision: 0 },
    { value: "YER", text: "Yemen Rial (﷼)" },
    { value: "ZWD", text: "Zimbabwe Dollar (Z$)" },
]
    .filter((item) => stripeSupportedCurrencies.has(item.value) && !item.noAMX)
    .map((item) => ({ ...item, key: item.value }));