// eslint-disable-next-line react/display-name
import { useSetState } from "ahooks";
import React, { useEffect, useState } from "react";
import { Button, Input, Message, Modal, Tab } from "semantic-ui-react";
import { useMutation } from "react-query";
import { ManagedDomainsDNSTab } from "./DNSTab";
import { useMutationsList } from "../../../../../../_api/queries";
import { LoadingScreen } from "mapsted-components";
import { ContentGroup } from "../../../../../common/ContentGroup";
import { TableBoxColumn } from "../../../../../common/TableBoxColumn";
import { ButtonIcon } from "../../../../../common/ButtonIcon";
import { DividerLine } from "../../../../../common/DividerLine";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const TITLE = "Managed Domains";

const TABS = {
    DNS_TXT: "DNS TXT",
    HTML_FILE: "HTML File"
};

export const ManagedDomains = ({ domains = [], addBreadCrumb, removeBreadCrumbAfter }) =>
{

    const trans = useTranslation().t;
    const [state, setState] = useSetState({
        currentTab: TABS.DNS_TXT,
        addDomainModalOpen: false,
    });
    const { mutate: deleteDomain, isLoading: deleteDomainLoading } = useMutation(useMutationsList().DELETE_DOMAIN());
    const { mutate: verifyDomain, isLoading: verifyDomainLoading } = useMutation(useMutationsList().VERIFY_DOMAIN());
    const { mutate: addDomain, isLoading: addDomainLoading } = useMutation(
        useMutationsList().ADD_DOMAIN(() => setState({ addDomainModalOpen: false }))
    );

    useEffect(() =>
    {
        addBreadCrumb({
            name: TITLE,
            onClick: () => removeBreadCrumbAfter(TITLE)
        });
    }, []);

    return (
        <>
            {(addDomainLoading || deleteDomainLoading || verifyDomainLoading) && <LoadingScreen />}

            <p className="paragraph">
                {trans("SamlSingleSignIn.Domain_Info")}
            </p>

            <Tab className="tabManaged"
                menu={{ secondary: true, pointing: true }}
                onTabChange={(e, { activeIndex }) => setState({ currentTab: activeIndex  === 0 ? TABS.DNS_TXT : TABS.HTML_FILE })}
                panes={[
                    {
                        menuItem: "DNS TXT",
                        // eslint-disable-next-line react/display-name
                        render: () => <ManagedDomainsDNSTab />
                    },
                    // {
                    //     menuItem: "HTTPS",
                    //     // eslint-disable-next-line react/display-name
                    //     render: () => <ManagedDomainsHTTPSTab />
                    // },
                ]}
            />

            <Button
                color="orange"
                floated="left"
                content={trans("SamlSingleSignIn.Add_Domain")}
                onClick={() => setState({ addDomainModalOpen: true })}
            />

            <DividerLine gap="35px 0 30px" />

            <DomainsList domains={domains} onDelete={deleteDomain} onVerify={verifyDomain} />

            <AddDomainModal
                open={state.addDomainModalOpen}
                onClose={() => setState({ addDomainModalOpen: false })}
                domains={domains}
                onAddDomain={(domain) => addDomain({ domain, verificationMethod: state.currentTab })}
            />
        </>
    );
};


const DomainsList = ({ domains = [], loading, onDelete, onVerify }) => 
{
    const trans = useTranslation().t;

    return (
        <ContentGroup heading={trans("SamlSingleSignIn.verified_Domains")} asHeading="h5">
            <div className="stepVerificationWrap">
                <div className="tableBox tableBoxDNS">
                    <div className="tableBoxRow">
                        <TableBoxColumn detail={trans("SamlSingleSignIn.DOMAIN")} />
                        <TableBoxColumn detail={trans("SamlSingleSignIn.STATUS")} />
                        <TableBoxColumn detail={trans("SamlSingleSignIn.VERIFICATION_METHOD")} />
                        <TableBoxColumn detail={trans("SamlSingleSignIn.ACTION")} />
                    </div>
    
                    {loading && (
                        <div className="tableBoxRow">
                            <div className="loadingColumn">
                                {trans("SamlSingleSignIn.Loading")}...
                            </div>
                        </div>
                    )}
                    {!loading && isEmpty(domains) && (
                        <div className="tableBoxRow">
                            <div className="loadingColumn">
                                {trans("SamlSingleSignIn.No_Varified_Domains")}
                            </div>
                        </div>
                    )}
                    {/* rows */}
                    {domains.map((domain) => (
                        <div key={domain.name} className="tableBoxRow">
                            <TableBoxColumn detail={domain.name} />
                            <TableBoxColumn>
                                <div className={`statusDNS ${domain.isVerified ? "active" : ""}`}>{domain.isVerified ? trans("SamlSingleSignIn.Verified") : trans("SamlSingleSignIn.Unverified")}</div>
                            </TableBoxColumn>
                            <TableBoxColumn detail={domain.verificationMethod} />
                            <TableBoxColumn>
                                <div className="actionsDNS">
                                    {!domain.isVerified && <Button color="orange" basic content={trans("SamlSingleSignIn.Verify")} onClick={() => onVerify(domain.name)} />}
                                    <ButtonIcon icon="trash-grey" onClick={() => onDelete(domain.name)} />
                                </div>
                            </TableBoxColumn>
                        </div>
                    ))}
    
                </div>
            </div>
    
        </ContentGroup>
    );
};

const AddDomainModal = ({ domains = [], open, onClose, onAddDomain }) =>
{
    const trans = useTranslation().t;

    const [domain, setDomain] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const handleAdd = () =>
    {
        if (!domain) return;

        const existing = domains.find((d) => d.name === domain);
        if (existing)
        {
            setErrorMsg(trans("SamlSingleSignIn.Domain_Already_Exists"));
            return;
        }

        setErrorMsg("");
        onAddDomain(domain);
        setDomain("");
    };
    const handleClose = () =>
    {
        setDomain("");
        onClose();
    };

    return (
        <Modal
            size="mini"
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{trans("SamlSingleSignIn.Please_Provide_Domain")}</Modal.Header>
            <Modal.Content>
                <Input
                    placeholder="example.com"
                    value={domain}
                    onChange={(e, { value }) => setDomain(value.trim())}
                    error={!!errorMsg}
                />
                {errorMsg && <Message error content={errorMsg} />}

            </Modal.Content>
            <Modal.Actions style={{ display: "flex", justifyContent: "center" }}>
                <Button negative onClick={handleClose}>
                    {trans("SamlSingleSignIn.Cancel")}
                </Button>
                <Button color="orange" onClick={handleAdd}>
                    {trans("SamlSingleSignIn.Add")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
