/**
 * @enum
 */
export const CLOSE_ACCOUNT_TYPES = {
    CLOSE: "close",
    HOLD: "hold"
};

export const CLOSE_ACCOUNT_REASONS = {
    NO_LONGER_NEEDED: "I no longer want location-based solutions for my property",
    OPTING_ANOTHER_SOLUTION: "I have found another location-based solution",
    TOO_EXPENSIVE: "My budget is tight right now",
    OTHER: "Other"
};

export const DELETE_DATA_ACKNOWLEDGE = [
    {
        heading: "Location Positioning Data",
        description: "All your floor plan(s), meta data associated with your property and building such as building and property name, hours of operation, POI data etc. will be permanently deleted."
    },
    {
        heading: "Location Marketing Data",
        description: "All your campaign content, campaign analytics, geofence data, and behavioural data from your visitors will be permanently deleted."
    },
    {
        heading: "Location Analytics Data",
        description: "All your data surrounding your visitors such as dwell time and location analytics will be permanently deleted."
    },
    {
        heading: "Mapsted Data",
        description: "All Mapsted specific data and applications such as Mall mApp, CampusGO, and Mapsted Maps will be permanently deleted."
    },
    {
        heading: "Personal Data",
        description: "All your secured personal data such as profile picture, security settings, billing and payment options, and account preferences will be permanently deleted."
    }
];

export const ACKNOWLEDGE_TIMER = 10_000; // 10 seconds
export const REDIRECT_TIMER = 3_000; // 3 seconds