import { mapstedWeb } from "../_constants/productsData";
import { windowRedirect } from "./browser";
import serverApi from "../_api/server.api";
import Permissions from "../permissions";
import { isWithinInterval, format } from "date-fns";
import { formatDistanceStrict } from "date-fns";
import { PRODUCTS } from "../_constants/productsData";

export const handleProductClick = (product, token, userInfo) =>
{
    if (product.active)
    {
        let targetUrl = product.baseURL;

        if (product.needsToken)
        {
            targetUrl += `?t=${token}`;
        }
        windowRedirect(targetUrl, serverApi.token, true);
    }
    else
    {
        redirectToContactUsPage(userInfo);
    }
};

export const redirectToContactUsPage = (userInfo) =>
{
    windowRedirect(redirectToContactPageLink(userInfo), undefined, true);
};

export const redirectToContactPageLink = (userInfo) =>
{
    const { firstName, lastName, company, email } = userInfo;
    const params = new URLSearchParams();
    firstName && params.set("fname", firstName);
    lastName && params.set("lname", lastName);
    company.name && params.set("company", company.name);
    company.phone && params.set("phone", company.phone);
    email && params.set("email", email);

    let targetUrl = `${mapstedWeb.contact}?${params}`;

    return targetUrl;
};

/**
 * Using constant PRODUCTS array and given user's licenceHash, returns array of products that current user has a licence for.
 * @param licenceHash
 */
export const getLicencedProducts = (productUrls, licenceHash) =>
{
    if (Permissions.isSuperAdmin)
        return PRODUCTS;

    return PRODUCTS.map((product) =>
    {
        let userProduct = { ...product, active: false, baseURL: productUrls[product.name]  };
        // userProduct.active = false;

        const productKey = licenceHash && userProduct?.licenceKey?.find((key) => licenceHash[key]);
        if (productKey)
        {
            const endDate = new Date(licenceHash[productKey].endDate);
            const startDate = new Date(licenceHash[productKey].startDate);
            if (!isNaN(startDate) && !isNaN(endDate))
            {
                userProduct.active = isWithinInterval(new Date(), { start: startDate, end: endDate });
            }
        }

        return userProduct;
    });

};

export const getExpiryMessages = ({ productLicenceKey, userLicenceHash }) =>
{
    const productLicenceEndDate = userLicenceHash?.[productLicenceKey]?.endDate;
    return {
        timeLeft: formatDistanceStrict(new Date(), new Date(productLicenceEndDate)),
        endDate: productLicenceEndDate ? format(new Date(productLicenceEndDate), "MM/dd/yyyy"): undefined
    };
};
