import React from "react";
import { useQuery } from "react-query";
import { Dropdown } from "semantic-ui-react";
import { QUERIES } from "../../../../../_api/queries";
import { isEmpty } from "../../../../../_utils/misc";
import { stripeSupportedCurrencies } from "../../../../../_constants/currencies";


/**
 * 
 * @type {React.FC<import("semantic-ui-react").DropdownProps>}
 */
const CurrencyDropdown = ({ value, onChange, className, ...rest }) => 
{
    
    const currencies = useQuery(QUERIES.places.GET_COUNTRIES());

    const options = React.useMemo(() => 
    {
        if (currencies.isSuccess)
        {
            
            return currencies.data.reduce((acc, currency, index) => 
            {
                
                if (!isEmpty(currency) && stripeSupportedCurrencies.has(currency.currencyCode))
                {
                    const { currencyCode: code, currency: name, alpha2: countryCode, country } = currency; 

                    let entity = { 
                        text: `${country}: ${code} - ${name}`, 
                        key: `${name}-${index}`,
                        value: code,
                        flag: countryCode.toLowerCase()
                    };

                    acc.push(entity);

                }
                
                return acc;
            },[]);

        }
        return [];
    },[currencies.data, currencies.isSuccess]);

    const handleChange = React.useCallback((e,{ value }) => 
    {
        onChange(value);
    },[onChange]);

    return (
        <Dropdown dropdown={currencies.isLoading} loading={currencies.isLoading} selection options={options} onChange={handleChange} value={value} {...rest} />
    );
};

export default CurrencyDropdown;
