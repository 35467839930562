import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../_store/AuthProvider";
import { ContentGroup } from "../../../common/ContentGroup";
import CopyButton from "../../../common/CopyButton";

const CompanyDetails = () =>
{
    const trans = useTranslation().t("Settings");
    const { state: { userInfo } } = useContext(AuthContext);
    const companyUID = userInfo?.company?.companyUID;

    return (
        <>
            <ContentGroup heading={trans["Company_Id"]}>
                <div className="accesskey">
                    <div
                        key={companyUID}
                        className="apiKeybox copybutton-parent"
                        style={{
                            padding: "12px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "10px",
                            maxWidth: "400px",
                            border: "dashed 2px lightgray",
                        }}
                    >
                        <span style={{ fontWeight: 500, fontSize: 15 }}>{companyUID}</span>
                        <CopyButton text={companyUID} />
                    </div>
                </div>
             
            </ContentGroup>
        </>
    );
};


export default CompanyDetails;
