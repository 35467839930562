import React from "react";
import { Button, Popup, Icon, Placeholder } from "semantic-ui-react";
import { TableBoxColumn } from "../../../common/TableBoxColumn";
import { EditTeamModal } from "./editTeamModal/EditTeamModal";
import { DeleteTeamModal } from "./editTeamModal/DeleteTeamModal";
import { SettingsPopupLayout } from "../SettingsPopupLayout";
import { Trans, useTranslation } from "react-i18next";

export const TableTabTeams = ({ teams, status, refetchTeams }) => 
{
    const trans = useTranslation().t;

    return (
        <div className="tableBox tableBoxUser teamsTable ">
            <div className="tableBoxRow">
                <TableBoxColumn detail={trans("UserTeamsRoles.TEAM")} />
                <TableBoxColumn detail={trans("UserTeamsRoles.USERS")} />
                <TableBoxColumn detail={trans("UserTeamsRoles.PROPERTIES")} />
                <TableBoxColumn narrow />
            </div>
            {(status === "success") && (teams || []).map((team) => (
                <TableBoxRow key={team.id} team={team} refetchTeams={refetchTeams} />
            ))}
            {(status === "success") && teams?.length === 0 && <EmptyTeamsRow />}
            {(status === "error") && <ErrorTeamsRow />}
            {(status === "loading") && new Array(6).fill(undefined).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key -- Array is hardcoded to be length 6
                <div key={i} className="tableBoxRow">
                    <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                    <TableBoxColumn style={{ alignItems: "inherit", display: "block" }}><Placeholder><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                    <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                    <TableBoxColumn narrow />
                </div>
            ))}
        </div>
    );
};

export const TableBoxRow = ({ team, refetchTeams }) =>
{
    const renderUsers = () =>
    {
        if (team?.users?.length < 1)
            return "—";
        if (team?.users?.length === 1)
            return `${team.users[0].firstName} ${team.users[0].lastName}`;
        else
            return <InfoTeamTabPopup type="users" number={team.users.length} list={team.users}/>;
    };

    const renderProperties = () =>
    {
        if (team?.properties?.length < 1)
            return "—";
        if (team?.properties?.length === 1)
            return team?.properties[0].name;
        else
            return <InfoTeamTabPopup type="properties" number={team?.properties?.length} list={[...team?.properties]?.sort((a,b) => a?.name?.localeCompare(b?.name))}/>;
    };

    return (
        <div className="tableBoxRow">
            <TableBoxColumn detail={team?.name}/>
            <TableBoxColumn>{/*<UsersColumn />*/}{renderUsers()}</TableBoxColumn>
            <TableBoxColumn>{renderProperties()}</TableBoxColumn>
            <TableBoxColumn narrow>
                <DeleteTeamModal teamName={team?.name} teamId={team?.id} refetchTeams={refetchTeams} />
                <EditTeamModal team={team} refetchTeams={refetchTeams} />
            </TableBoxColumn>
        </div>
    );
};

/**
 * @param type {"users" | "properties"}
 * @param number {number}
 * @param list {[]}
 * @returns {JSX.Element}
 */
const InfoTeamTabPopup = ({ number, type, list }) =>
{
    const capitalize = (word) => word[0].toUpperCase() + word.slice(1).toLowerCase();

    const renderList = () =>
    {
        switch (type)
        {
        case "users":
            return list.map((user) => <p key={user.id}>{`${user.firstName} ${user.lastName}`}</p>);
        case "properties":
            return list.map((property) => <p key={property.id}>{property.name}</p>);
        default:
            return "";
        }
    };

    return (
        <Popup className="popupBox"
            trigger={<Button className="buttonIcon" content={`${number} ${type}`} />}
            on="click"
            position="bottom center"
        >
            <SettingsPopupLayout heading={capitalize(type)}>
                {renderList()}
            </SettingsPopupLayout>
        </Popup>
    );
};

const EmptyTeamsRow = () => (
    <div className="tableBoxRow emptyTableList">
        <Icon circular name="users" size="big" />
        <p>
            <Trans
                i18nKey="UserTeamsRoles.No_Teams_Found"
            />
        </p>
    </div>
);

const ErrorTeamsRow = () => (
    <div className="tableBoxRow emptyTableList">
        <Icon circular name="users" size="big" />
        <p>
            <Trans
                i18nKey="UserTeamsRoles.Cannot_Retrive_Team_Data"
            />
        </p>
    </div>
);

// Do we need avatars? Assuming same behaviour as teams/properties
// const UsersColumn = () =>
// {
//     return (
//         <>
//             <Image as="span" src="/img/example-user.png" />
//             <Image as="span" src="/img/example-user.png" />
//             <Image as="span" src="/img/example-user.png" />
//             <UsersPopup />
//         </>
//     );
// }

// const UsersPopup = () =>
// {
//     return (
//         <Popup className="popupBox"
//             trigger={<Button className="buttonIcon" content="+ 3 more" />}
//             on="click"
//             position="bottom center"
//         >
//             <Header content="Users" />
//             <p>Name Surname</p>
//             <p>Name Surname</p>
//             <p>Name Surname</p>
//         </Popup>
//     );
// }
