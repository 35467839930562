import React from "react";
import { Segment } from "semantic-ui-react";
import { Input, Button } from "mapsted-components";

import LoginSection from "./LoginSection";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ loading, username, setUsername, onGoBack, onSubmit }) =>
{
    const buttonCSS = loading ? "btn-loading" : "";
    const trans = useTranslation().t("ForgotPassword");

    return (
        <LoginSection heading={trans["header"]}>
            <span className="loginDetail">{trans["subHeader1"]}<br />
                {trans["subHeader2"]}</span>
            <Segment basic className="login-wrap">
                <div className="inputForm">
                    {/* <b>Email:</b> */}
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder={trans["Username"]}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="login-action">
                    <Button className={`loginButton ${buttonCSS}`}
                        onClick={onSubmit}
                        disabled={loading ? "disabled" : undefined}
                        content={trans["buttonText"]}
                    />
                </div>
                <p className="para-login para-login2">
                    <Button
                        onClick={onGoBack}
                        basic={false}
                        content={trans["Or_Back_To_Login"]}
                    />
                </p>
            </Segment>
        </LoginSection>
    );
};

export default ForgotPassword;
