import React from "react";
import { useTranslation } from "react-i18next";
import { ContentGroup } from "../../../common/ContentGroup";
import { DividerLine } from "../../../common/DividerLine";
import { LINKS } from "../../constants";
import { SectionSettings } from "../SectionSettings";
import { SettingsLayout } from "../SettingsLayout";
import ManageRestrictions from "./ManageRestrictions";
import { MobileIntegration } from "./MobileIntegration";
import { WebIntegration } from "./WebIntegration";
import AdvancedMapstedMaps from "./AdvancedMapsedMaps";
import ManageApiKeys from "./ManageApiKeys";
import CompanyDetails from "./CompanyDetails";

export const DevelopersSettings = () =>
{
    const trans = useTranslation().t;
    return (
        <SettingsLayout className="accountSettings">
            <div className="settingsWrapper developersWrapper">
                <SectionSettings icon="developers" heading={trans("DevelopersSettings.Developers")}>
                    <CompanyDetails />
                    <DividerLine gap="30px 0 40px" />

                    <WebIntegration />
                    <DividerLine gap="30px 0 40px" />

                    <ManageApiKeys />
                    <DividerLine gap="30px 0 40px" />

                    <ManageRestrictions />
                    <DividerLine gap="30px 0 40px" />

                    <AdvancedMapstedMaps />
                    <DividerLine gap="30px 0 40px" />

                    <MobileIntegration />
                    <DividerLine gap="30px 0 40px" />
                    <ContentGroup heading={trans("DevelopersSettings.Developer_Links")}>
                        <AppLinkItem url={LINKS.ANDROID_DOCS} icon="android" content={trans("DevelopersSettings.Android")} />
                        <AppLinkItem url={LINKS.IOS_DOCS} icon="ios" content={trans("DevelopersSettings.iOS")} />
                    </ContentGroup>
                </SectionSettings>
            </div>
        </SettingsLayout>
    );
};
const AppLinkItem = ({ url, content, icon }) => (
    <a href={url} className="appLinkItem" target="_blank" rel="noreferrer">
        <img src={`/img/icon-device-${icon}.svg`} alt="" />
        {content}
        <img src="/img/icon-open.svg" alt="" />
    </a>
);
