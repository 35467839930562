import React from "react";
import { TextGroup } from "../../../../common/TextGroup";
import { useTranslation } from "react-i18next";

const Contact = ({ contact, users, properties }) =>
{
    const trans = useTranslation().t;

    const propertyName = contact.AllProperties ? "All Properties" : properties.find((prop) => prop.propertyId.toString() === contact.PropertyId)?.propertyName;
    const user = users.find((u) => u.id === contact.UserId);
    const userName = user ? `${user.firstName} ${user.lastName}` : trans("UserTeamsRoles.None_Selected");

    if (contact.AllProperties)
    {
        return (
            <TextGroup title={trans("UserTeamsRoles.Default_Contact")} content={userName} />
        );
    }

    return (
        <>
            <TextGroup title={trans("UserTeamsRoles.Property")} content={propertyName} />
            <TextGroup title={trans("UserTeamsRoles.Designated_Contact")} content={userName} />
        </>
    );
};


export const DefaultContact = ({ contact, users }) =>
{
    const trans = useTranslation().t;

    const user = users.find((u) => u.id === contact.UserId);
    const userName = user ? `${user.firstName} ${user.lastName}` : trans("UserTeamsRoles.None_Selected");
    return (
        <TextGroup title={userName} />
    );
};

export const DesignatedContact = ({ contact, users }) =>
{
    const trans = useTranslation().t;

    // const propertyName = contact.AllProperties ? "All Properties" : properties.find((prop) => prop.propertyId.toString() === contact.PropertyId)?.propertyName;
    const user = users.find((u) => u.id === contact.UserId);
    const userName = user ? `${user.firstName} ${user.lastName}` : trans("UserTeamsRoles.None_Selected");
    return (
        <>
            {/*<TextGroup title="Property" content={propertyName} />*/}
            <TextGroup title={userName} />
        </>
    );
};


export default Contact;
