import { omit } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { Icon, Popup } from "semantic-ui-react";
import Permissions from "../../../permissions";
import { QUERIES } from "../../../_api/queries";
import { AuthContext } from "../../../_store/AuthProvider";
import { AccountAccessColumn } from "../Dashboard";
import AccessLoader from "./AccessLoader";
import AccountAccessListItem from "./AccountAccessListItem";
import Entities from "./Entities";
import { useTranslation } from "react-i18next";


const contactTypesToDescriptions = {
    primary: "Roles.You_Are_In_Primary_Contact",
    billing: "Roles.You_Are_In_Billing_Contact",
    decision: "Roles.You_Are_In_Decision_Maker",
    onboarding: "Roles.You_Are_The_Onboarding_Contact",
    technical: "Roles.You_Are_The_Technical_Contact"    // "technical" is an old contact type that we are not using now, but text is still here just in case it is in db
};

const contactTypesToResponsibilities = {
    primary: "Roles.Responsibility_Primary",
    billing: "Roles.Responsibility_Billing",
    decision: "Roles.Responsibility_Decision",
    onboarding: "Roles.Responsibility_Onboarding",
    technical: "Roles.Responsibility_Technical"
};

const Roles = () => 
{

    const { state: authState } = React.useContext(AuthContext);
    const properties = React.useMemo(() => Object.values(authState?.properties || {}),[authState?.properties]);
    const trans = useTranslation().t;

    const selectContacts = React.useCallback((data) =>
    {
        const userContacts = data?.filter?.((contact) => contact?.UserId === authState?.userInfo?.id);

        if (Permissions.isCompanyAdmin)
        {
            // When all default contacts are admin at the start, it is implicit - those rows are not initially stored in db - so add them on to list if user = admin
            const defaultContact = (type) => ({ AllProperties: true, ContactType: type, PropertyId: "0" });

            for (const type of Object.keys(omit(contactTypesToDescriptions, "technical")))
            {
                if (!data?.some?.((contact) => contact?.PropertyId === "0" && contact?.ContactType === type))
                {
                    userContacts.push(defaultContact(type));
                }
            }
        }

        return userContacts.reduce((acc, contact) =>
        {
            let content;
            const property = properties?.find((p) => p?.propertyId.toString() === contact?.PropertyId.toString());
            if (contact?.AllProperties)
            {
                content = "All Properties";
            }
            else if (property?.name)
            {
                content = property.name;
            }
            else
            {
                content = undefined;
            }

            if (content)
            {
                acc.push({
                    key: contact?.ContactType + contact?.PropertyId,
                    content,
                    detail: (<div style={{ display: "flex", marginTop: "-5px" }}><span>{trans(contactTypesToDescriptions?.[contact?.ContactType])}</span><Popup
                        trigger={<Icon size="tiny" style={{ marginLeft: "5px", marginTop: "7px" }} circular name="help" />}
                        content={trans(contactTypesToResponsibilities?.[contact?.ContactType])}
                        size="small"
                    /></div>),
                    icon: "/img/icon-access-role.svg"

                });
            }

            return acc;
        },[]);
    },[authState?.userInfo?.id, properties]);

    const { data: contacts, status } = useQuery({
        ...QUERIES.utr.ROLES(authState.userInfo.company.companyUID),
        select: selectContacts
    });

    const RolesError = ({ content, detail }) => 
    {
        const trans = useTranslation().t;
        return (
            <AccountAccessColumn heading={trans("Roles.Roles")} subheading={trans("Roles.You_Will_Be_Informed_Of_Updates")}>
                <AccountAccessListItem content={content} detail={detail} />
            </AccountAccessColumn>
        );
    };

    /**
     *
     * @param {Array} contacts
     */
    const renderList = (contacts, trans) =>
    {
        if (contacts?.length === 0)
        {
            return (
                <AccountAccessColumn heading={trans("Roles.Roles")} subheading={trans("Roles.You_Will_Be_Informed_Of_Updates")}>
                    <div className="noneItem">{trans("Roles.No_Roles_Assiged")}</div>
                </AccountAccessColumn>
            );
        }
        return <Entities heading={trans("Roles.Roles")} subHeading={trans("Roles.You_Will_Be_Informed_Of_Updates")} entities={contacts} searchPlaceholder={trans("Roles.Search_Role")} />;
    };

    return ({
        loading: <AccessLoader heading={trans("Roles.Roles")} subheading={trans("Roles.You_Will_Be_Informed_Of_Updates")}/>,
        error: <RolesError content={trans("Roles.Error_Getting_Roles")} detail={trans("Roles.Refresh_The_Page")} />,
        success: renderList(contacts, trans)
    })[status];
};

export default Roles;
