import React, { useState, useContext } from "react";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import "./AddTeamModal.css";
import { Form, FormikProvider, getIn, useFormik, Field } from "formik";
import * as Yup from "yup";
import { LoadingScreen } from "mapsted-components";
import { useMutation } from "react-query";
import { MUTATIONS } from "../../../../../_api/queries";
import { NotificationContext } from "../../../../notifications";
import { useTranslation } from "react-i18next";

export const AddTeamModal = ({ disabled = false, teamNames = [], refetchTeams }) =>
{
    const trans = useTranslation().t;

    const [modalOpen, setModalOpen] = useState(false);
    const notification = useContext(NotificationContext);

    const formik = useFormik({
        initialValues: { addTeam: { teamName: "", teamDesc: "" } },
        onSubmit: () => onFormSubmit().then(() => null, () => null),
        validationSchema: Yup.object().shape({
            addTeam: Yup.object().shape({
                teamName: Yup.string().required("Team Name required"),
                teamDesc: Yup.string().required("Team Description required")
            }).required()
        }).required()
    });
    const { values, submitForm, errors, handleSubmit, handleReset, touched, isSubmitting } = formik;

    const { mutateAsync, reset } = useMutation(MUTATIONS.utr.TEAM_CREATE(refetchTeams));

    const onFormSubmit = async () => await mutateAsync(values.addTeam)
        .then(() => handleModalClose())
        .catch((err) =>
        {
            handleModalClose();
            notification.addNotification("danger", "Add Team Failed", "Try again later");
        });

    const validateTeamName = (value) =>
    {
        if (teamNames.includes(value))
        {
            return trans("AddTeamModal.Team_Already_Exists");
        }
    };

    function getStyles(fieldName)
    {
        if (getIn(touched, fieldName) && getIn(errors, fieldName))
            return { borderColor: "#F14850" };
    }

    const handleModalClose = () =>
    {
        setModalOpen(false);
        handleReset();
        reset();
    };

    const renderErrors = () =>
    {
        const fields = ["addTeam.teamName", "addTeam.teamDesc"];
        return <>
            {/* If a team field is touched and has error then show error message. */}
            {fields.map((f) => getIn(touched, f) && getIn(errors, f) && (
                <div className="field-error">
                    {getIn(errors, f)}
                </div>
            ))}
        </>;
    };

    return (
        <ModalLayout className="addTeamModal"
            trigger={<Button onClick={() => setModalOpen(true)} disabled={disabled} color="orange" floated="right" content={trans("AddTeamModal.Add_Team")} />}
            onClose={handleModalClose}
            open={modalOpen}
            heading={trans("AddTeamModal.Add_Team")}
            actions={<Button color="orange" floated="right" content={trans("AddTeamModal.Add")} onClick={() => submitForm()} />}
        >
            {isSubmitting && <LoadingScreen />}
            <p className="pModal">
                {trans("AddTeamModal.Adding_Team_Will_Allow_Info")}
            </p>

            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    <TextGroup contentClassName="ui input inputForm" className="limitedGroupCover" heading={trans("AddTeamModal.Team_Name")}>
                        <Field
                            name="addTeam.teamName"
                            type="text"
                            className="inputForm"
                            placeholder={trans("AddTeamModal.Enter_Team_Name")}
                            style={getStyles("addTeam.teamName")}
                            validate={validateTeamName}
                        />
                    </TextGroup>

                    <TextGroup contentClassName="ui input inputArea" heading={trans("AddTeamModal.Team_Description")}>
                        <Field
                            name="addTeam.teamDesc"
                            type="text"
                            className="inputArea"
                            placeholder={trans("AddTeamModal.Responsible_For_Notify")}
                            style={getStyles("addTeam.teamDesc")}
                        />
                    </TextGroup>

                    {renderErrors()}
                </Form>
            </FormikProvider>
        </ModalLayout>
    );
};
