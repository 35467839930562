import React from "react";
import { Header, Button } from "semantic-ui-react";

export const SectionSettings = ({
    className, icon,
     heading,
    breadCrumbs = [], children
}) =>
{
    const renderBreadCrumbs = () =>
    {
        const { length } = breadCrumbs;
        const parents = breadCrumbs.slice(0, length - 1);
        const current = breadCrumbs[length - 1];

        return (
            <Header className="securityBreadCrumb">
                {parents.map(({ name, onClick }) => <Button key={name} onClick={onClick}>{name}</Button>)}
                {current?.name}
            </Header>
        );

    };

    return (
        <div className={`sectionSettings${className ? " "+className:""}`}>
            <img src={`/img/icon-${icon}.svg`} alt="" />
            {heading && <Header as="h2" content={heading} />}
            {renderBreadCrumbs()}
            {children}
        </div>
    );
};
