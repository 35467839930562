import React, { useState } from "react";
import { Button as SButton } from "semantic-ui-react";
import { Button, Input } from "mapsted-components";
import LoginSection from "./LoginSection";
import { useTranslation } from "react-i18next";


const MainLogin = ({ loading, onSubmit, onForgotPassword, setUsername, setPassword }) =>
{
    const buttonCSS = loading ? "btn-loading" : "";

    const [loginWithOrg, setLoginWithOrg] = useState(false);
    const trans = useTranslation().t("Login");

    return (
        <LoginSection heading={trans["Welcome_To_Mapsted"]}>
            <span className="loginDetail">
                {trans["Login_Desc"]}<br />
                {trans["Sign_In_Google"]}
            </span>
            <div className="login-wrap">
                <div className="inputForm">
                    <b>{trans["Email"]}:</b>
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="name@example.com"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                {!loginWithOrg && (
                    <>
                        <div className="inputForm">
                            <b>{trans["Password"]}:</b>
                            <Input
                                type="password"
                                autoComplete="off"
                                name="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <p className="para-login">
                            <Button
                                type="button"
                                onClick={onForgotPassword}
                                basic={false}
                                content={trans["Forgot_Password"]}
                            />
                        </p>
                    </>
                )}

                <div className="login-action">
                    <SButton type="submit" className={`loginButton ${buttonCSS}`}
                        onClick={(e) => onSubmit(e, loginWithOrg)}
                        disabled={loading ? "disabled" : undefined}
                        content={trans["Login"]}
                    />
                </div>

                <Button className="loginSecondaryButton"
                    type="button"
                    content={loginWithOrg ? trans["Go_Back"] : trans["Login_With_SAML"]}
                    onClick={() => setLoginWithOrg(!loginWithOrg)}
                />


                {/* {
                        state.isMallMapp && (
                            <div>
                                <p>
                                    Not using our location-based services for your business? Let&apos;s change that! {" "}
                                    <a href="/mall-mapp/signup">Sign Up</a>
                                </p>
                            </div>
                        )
                    } */}
            </div>
        </LoginSection>
    );
};
export default MainLogin;
