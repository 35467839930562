import React, { useMemo } from "react";

export const YoutubePlayer = ({ videoId, caption, width = 200, height = 200, params = {} }) =>
{
    const src = useYoutubeEmbedSrc(videoId, params);

    return (
        <div className="videoWrap">
            <div className="videoThumb">
                <iframe
                    width={width}
                    height={height}
                    src={src}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                />
            </div>
            <p>{caption}</p>
        </div>
    );
};

const DEFAULT_PARAMS = {
    rel: "0",
    modestBranding: "0"
};
const BASE_URL = "https://www.youtube-nocookie.com/embed/";
function useYoutubeEmbedSrc(videoId, params)
{
    return useMemo(() =>
    {
        const mergedParams = { ...DEFAULT_PARAMS, ...params };
        const url = new URL(videoId, BASE_URL);
        const paramsStr = new URLSearchParams(mergedParams).toString();

        return `${url}?${paramsStr}`;
    }, [videoId, params]);
}
