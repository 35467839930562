import { useSetState } from "ahooks";
import { isEmpty } from "lodash";
import { LoadingScreen } from "mapsted-components";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Form, Header, Input, TextArea, Popup } from "semantic-ui-react";
import { QUERIES, useMutationsList } from "../../../../../../_api/queries";
import { SERVER_URL } from "../../../../../../_utils/constants";
import { ButtonIcon } from "../../../../../common/ButtonIcon";
import { ContentGroup } from "../../../../../common/ContentGroup";
import { DividerLine } from "../../../../../common/DividerLine";
import { DropdownForm } from "../../../../../common/DropdownForm";
import { TextGroup } from "../../../../../common/TextGroup";
import { useTranslation } from "react-i18next";

const TITLE = "SAML Identity Provider";

const IDPs = {
    AUTH_0: "Auth0",
    ONE_LOGON: "OneLogin",
    OKTA: "Okta"
};

const MAPSTED_CONFIG = {
    AUDIENCE: "hub.mapsted.com",
    RECIPIENT: `${SERVER_URL}/api/v5/sso/login/callback`,
    ACS_URL: `${SERVER_URL}/api/v5/sso/login/callback`,
    DOWNLOAD_CERT_URL: `${SERVER_URL}/api/v5/security/certificate?download=true`
};

const DUMMY_CERTIFICATE = `
-----BEGIN CERTIFICATE-----
4gCA00awyybDpnuG0h9RSgv6rsv942MyFBjLYyx2H9GEwD9arn0M3GWGmSyP
UFnypjVcfAP2nukwtMzFDoypxLzcvC2XkBGoWqpicF7JnLOMWoiGiFRvSR92
VGc5SIQ9EKFvNj5fpqjTU11FCawTWKs78ORPLCgkSOYPwzTxZ4D9z7I3FR6Z
Itks2FM1ZQnFyaFhG6eG3TmvapX6TEmirYjVaEbpgBIpcyxLfBYvt2V7gZoK
RslBEXWrtkrDvEfe68zm7qnNSfLgOIOeWxleoyXHINNLjxK6Dyu9Yuol2Y4f
oIjwdffX1pI5XaSdB63PcGfPeJ75uobfhfI5t70L66WSoSFyAxrd5duovaPs
4gCA00awyybDpnuG0h9RSgv6rsv942MyFBjLYyx2H9GEwD9arn0M3GWGmSyP
UFnypjVcfAP2nukwtMzFDoypxLzcvC2XkBGoWqpicF7JnLOMWoiGiFRvSR92
VGc5SIQ9EKFvNj5fpqjTU11FCawTWKs78ORPLCgkSOYPwzTxZ4D9z7I3FR6Z
Itks2FM1ZQnFyaFhG6eG3TmvapX6TEmirYjVaEbpgBIpcyxLfBYvt2V7gZoK
RslBEXWrtkrDvEfe68zm7qnNSfLgOIOeWxleoyXHINNLjxK6Dyu9Yuol2Y4f
oIjwdffX1pI5XaSdB63PcGfPeJ75uobfhfI5t70L66WSoSFyAxrd5duovaPs
-----END CERTIFICATE-----
`.trim();

const SAMLConfiguration = ({ addBreadCrumb, removeBreadCrumbAfter, samlConfig }) =>
{
    const trans = useTranslation().t;

    const [state, setState] = useSetState({
        idpName: "",
        issuerUrl: "",
        // entityId: "",
        ssoEndpoint: "",
        sloEndpoint: "",
        idpCertificate: "",
        dirty: false
    });
    const { data: certificate, isLoading: certificateLoading } = useQuery(QUERIES.GET_CERTIFICATE());
    const { mutate: saveSamlConfig, isLoading: saveSamlConfigLoading } = useMutation(
        useMutationsList().SAVE_SAML_CONFIG(() => setState({ dirty: false }))
    );

    useEffect(() =>
    {
        addBreadCrumb({
            name: TITLE,
            onClick: () => removeBreadCrumbAfter(TITLE)
        });
    }, []);

    useEffect(() =>
    {
        setState(samlConfig);
    }, [samlConfig]);

    const handleChange = (key, value) =>
    {
        setState({ [key]: value.trim(), dirty: true });
    };

    const handleSave = () =>
    {
        const { dirty, ...data } = state;

        const validationMsg = validateFields(data, trans);
        if (validationMsg)
        {
            return alert(`Please provide ${validationMsg}`);
        }

        saveSamlConfig(data);
    };

    return (
        <>
            <LoadingScreen active={saveSamlConfigLoading} />

            <p className="paragraph">
                {trans("SamlSingleSignIn.Using_Single_IDP_To_Login")}
            </p>
            {/* Hidden until functionality is clear */}
            {/* <ContentGroup heading="Authentication Type" asHeading="h5">
                <TextGroup className="tgIdentityProvider" heading="SAML Single Sign-on"
                    content="Connect to a SAML identity provider for single sign-on, such as OneLogin.">
                </TextGroup>
                <Button color="orange" basic content="Change Authentication Type" />
            </ContentGroup> */}

            <DividerLine gap="25px 0 35px" />

            <ContentGroup className="identityProviderFlex" heading={trans("SamlSingleSignIn.Connect_To_SAML_Identity_Provider")} asHeading="h5">
                <div className="idtentityProviderColumn">
                    <TextGroup heading={trans("SamlSingleSignIn.Provider")}>
                        <DropdownForm
                            onChange={(e, { value }) => handleChange("idpName", value)}
                            options={Object.values(IDPs).map((idp) => ({ key: idp, text: idp, value: idp }))}
                            placeholder={trans("SamlSingleSignIn.Select_Identity_Provider")}
                            value={state.idpName}
                            selectOnBlur={false}
                        />
                    </TextGroup>

                    <TextGroup className="tgInputCover" heading={trans("SamlSingleSignIn.Issuer_URL")}>
                        <Input
                            placeholder="https://app.loremipsum.com/saml/metadata/..."
                            value={state.issuerUrl}
                            onChange={(e, { value }) => handleChange("issuerUrl", value)}
                        />
                    </TextGroup>

                    {/* <TextGroup heading="Entity ID">
                        <Input
                            placeholder="Entity ID"
                            value={state.entityId}
                            onChange={(e, { value }) => setState({ entityId: value })}
                        />
                    </TextGroup> */}

                    <TextGroup className="tgInputCover" heading={trans("SamlSingleSignIn.SSO_Endpoint")}>
                        <Input
                            placeholder="https://app.loremipsum.com/saml/metadata/..."
                            value={state.ssoEndpoint}
                            onChange={(e, { value }) => handleChange("ssoEndpoint", value)}
                        />
                    </TextGroup>

                    <TextGroup heading={trans("SamlSingleSignIn.SLO_Endpoint")}>
                        <Input
                            placeholder="https://app.loremipsum.com/saml/metadata/..."
                            value={state.sloEndpoint}
                            onChange={(e, { value }) => handleChange("sloEndpoint", value)}
                        />
                    </TextGroup>

                    <TextGroup className="tgInputCover" heading={trans("SamlSingleSignIn.Public_Certificate")}>
                        <Form>
                            <TextArea
                                value={state.idpCertificate}
                                placeholder={DUMMY_CERTIFICATE}
                                rows={15}
                                onChange={(e, { value }) => handleChange("idpCertificate", value)}
                            />
                        </Form>
                    </TextGroup>
                    <Button
                        color="orange"
                        floated="right"
                        content={trans("SamlSingleSignIn.Save")}
                        disabled={!state.dirty}
                        onClick={handleSave}
                    />
                </div>
                <div className="idtentityProviderBoxColumn">
                    <Header content={trans("SamlSingleSignIn.SSO_URLs")} />
                    <p className="para">
                        {trans("SamlSingleSignIn.Paste_These_URLs")}
                    </p>
                    <TextGroup title={trans("SamlSingleSignIn.Audience")}>
                        <div className="urlcodecover">{MAPSTED_CONFIG.AUDIENCE}</div>
                        <CopyButton text={MAPSTED_CONFIG.AUDIENCE} />
                    </TextGroup>
                    <TextGroup title={trans("SamlSingleSignIn.Recipient")}>
                        <div className="urlcodecover">{MAPSTED_CONFIG.RECIPIENT}</div>
                        <CopyButton text={MAPSTED_CONFIG.RECIPIENT} />
                    </TextGroup>
                    <TextGroup title={trans("SamlSingleSignIn.ACS_Validation_URL")}>
                        <div className="urlcodecover">{MAPSTED_CONFIG.ACS_URL}</div>
                        <CopyButton text={MAPSTED_CONFIG.ACS_URL} />
                    </TextGroup>
                    <TextGroup title={trans("SamlSingleSignIn.ACS_URL")}>
                        <div className="urlcodecover">{MAPSTED_CONFIG.ACS_URL}</div>
                        <CopyButton text={MAPSTED_CONFIG.ACS_URL} />
                    </TextGroup>
                    {/* TODO: Check if its required */}
                    {/* <TextGroup title="Single Logout URL">
                        <div className="urlcode">https://loremipsumdolor.com/metad.</div>
                        <ButtonIcon icon="copy2" />
                    </TextGroup> */}
                    <TextGroup className="urlcodeWrap" title={trans("SamlSingleSignIn.Public_Certificate")}>
                        <div className="urlcodecover">
                            <pre>
                                {certificateLoading ? `${trans("SamlSingleSignIn.Loading")}...` : certificate}
                            </pre>
                        </div>
                        <CopyButton text={certificate} />
                    </TextGroup>
                    <TextGroup className="tgCertificate" flexible title={trans("SamlSingleSignIn.OR")}>
                        <ButtonIcon icon="download" content={trans("SamlSingleSignIn.Download_Certificate")}  onClick={() => window.open(MAPSTED_CONFIG.DOWNLOAD_CERT_URL)}/>
                    </TextGroup>

                </div>

            </ContentGroup>
        </>
    );
};

export default SAMLConfiguration;


const CopyButton = ({ text }) =>
{
    const [copyPopupOpen, setCopyPopupOpen] = useState(false);

    const handleCopy = () =>
    {
        navigator.clipboard.writeText(text).then(() =>
        {
            setCopyPopupOpen(true);
            setTimeout(() => setCopyPopupOpen(false), 1500);
        });

    };

    const trans = useTranslation().t;

    return (
        <Popup
            trigger={<ButtonIcon icon="copy2" onClick={handleCopy} />}
            content={trans("SamlSingleSignIn.Copied")}
            position="top center"
            open={copyPopupOpen}
        />
    );
};


const validateFields = (values,trans) =>
{
    const requiredFields = [{ key: "idpName", msg: trans("SamlSingleSignIn.IDP_Name") }, { key: "issuerUrl", msg: trans("SamlSingleSignIn.Issuer_URL") }, { key: "ssoEndpoint", msg: trans("SamlSingleSignIn.SSO_Endpoint") }, /* {key: "sloEndpoint", msg: "SLO Endpoint" },*/ { key: "idpCertificate", msg: trans("SamlSingleSignIn.IDP_Certificate") }];

    for (const field of requiredFields)
    {
        const { key, msg } = field;
        if (isEmpty(values[key]))
        {
            return msg;
        }
    }
    return null;
};
