import React, { useEffect } from "react";
import { Pagination, Icon, Radio } from "semantic-ui-react";
import { CheckboxGroup } from "../../../../common/CheckboxGroup";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import { SectionSettings } from "../../SectionSettings";
import { VerificationApplicationModal } from "./VerificationApplicationModal";
import { VerificationConfigureModal } from "./VerificationConfigureModal";
import { Trans, useTranslation } from "react-i18next";

export const TwoStepVerification = ({ title, addBreadCrumb, removeBreadCrumbAfter }) =>
{
    const trans = useTranslation().t;

    useEffect(() =>
    {
        addBreadCrumb({
            name: title,
            onClick()
            {
                // remove all breadcrums after current one
                removeBreadCrumbAfter(title);
            }
        });
    }, []);

    return (
        <SectionSettings>
            <p className="paragraph">{trans("TwoStepVerification.Two_Step_Verification_Info")}</p>
            <div className="stepVerificationWrap">
                <div className="tableBox tableBoxVerification">
                    <div className="tableBoxRow">
                        <TableBoxColumn detail={trans("TwoStepVerification.FULL_NAME")} />
                        <TableBoxColumn detail={trans("TwoStepVerification.TWO_STEP_VERIFICATION")} />
                    </div>
                    <TableBoxStepVerificationRow />
                    <TableBoxStepVerificationRow />
                    <TableBoxStepVerificationRow />
                    <TableBoxStepVerificationRow />
                    <TableBoxStepVerificationRow />
                </div>
                <div className="paginationBar">
                    <Pagination
                        className="paginationDashboard"
                        totalPages={5}
                        firstItem={null}
                        lastItem={null}
                        prevItem={{ content: <Icon name="angle left"/>, icon: true }}
                        nextItem={{ content: <Icon name="angle right"/>, icon: true }}
                    />
                    <div className="displayText">
                        <span>
                            <Trans 
                                i18nKey="TwoStepVerification.Pagination_Text"
                            /></span>
                    </div>
                </div>
            </div>

            <VerificationConfigureModal />
            <VerificationApplicationModal />

        </SectionSettings>
    );
};

const TableBoxStepVerificationRow = () => {
    const trans = useTranslation().t;

    return (
        <div className="tableBoxRow">
            <TableBoxColumn>
                <CheckboxGroup secondary title={trans("TwoStepVerification.Name_Surname")} />
            </TableBoxColumn>
            <TableBoxColumn>
                <Radio toggle className="toggleStepVerification" />
                {trans("TwoStepVerification.Enabled_July")}
            </TableBoxColumn>
        </div>
    );
    
}