import React, { useContext } from "react";
import { Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { AuthContext } from "../../_store/AuthProvider";
import { Section } from "../common/Section";
import { CATEGORIES } from "../../_constants/productsData";
import { getLicencedProducts, handleProductClick } from "../../_utils/dashboardCards";

import MapstedHeader from "../layout/Header";
import "./Dashboard.css";
import { ProductRow, ProductColumn } from "./components/DashboardProduct";
import Properties from "./AccountAccess/Properties";
import Roles from "./AccountAccess/Roles";
import Teams from "./AccountAccess/Teams";
import { useQuery } from "react-query";
import { QUERIES } from "../../_api/queries";

const Dashboard = () =>
{
    const { state: authState } = useContext(AuthContext);
    const trans = useTranslation().t;
    const { data: productUrls } = useQuery(QUERIES.GET_PRODUCT_URLS());

    const renderCategory = (category) =>
    {
        const { heading, color, subheading } = category;
        return (
            <ProductRow heading={trans(heading)} color={color} subheading={trans(subheading)} key={heading}>
                {
                    getLicencedProducts(productUrls, authState?.licence?.licenceHash)
                        .filter((p) => p.category === category.id)
                        .map((p) => renderProduct(p))
                }
            </ProductRow>
        );
    };

    const renderProduct = (product) => (
        <ProductColumn
            key={product.img}
            heading={product.heading}
            productImg={product.img}
            description={trans(product.description)}
            isActive={product.active}
            onClick={() => handleProductClick(product, authState?.token, authState.userInfo)}
        />
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{trans("Dashboard.Dashboard_Page_Title")}</title>
                <meta name="description" content={trans("Dashboard.Dashboard_Page_Desc")} />
            </Helmet>
            <MapstedHeader />
            <div className="dashboardBanner">
                <Header as="h1">{trans("Dashboard.Mapsted_HUB")}</Header>
                <span>{trans("Dashboard.Powerful_software_for_all_your_business_")}</span>
            </div>
            <div className="sectionAccess">
                <div className="container">
                    <AccountAccessSection />
                </div>
            </div>
            <Section className="dashboardSection">

                {CATEGORIES.map((c) => renderCategory(c))}
            </Section>
        </React.Fragment>
    );
};



const AccountAccessSection = () => 
{
    const trans = useTranslation().t;

    return  (
        <ProductRow className="mapstedProductAccess" color="purple" heading={trans("Dashboard.Account_Access")} subheading={trans("Dashboard.Properties_Roles_Team")}>
            <Properties />
            <Roles />
            <Teams />
    
        </ProductRow>
    );
};

export const AccountAccessColumn = ({ heading, subheading, children }) => (
    <div className="mapstedProductColumn">
        <Header>
            {heading}
            {subheading && <span>{subheading}</span>}
        </Header>
        <div className="accountAccessList">
            {/* this needs to be changes with place */}
            <div className="accessAligner">
                {children}
            </div>
        </div>
    </div>
);

export default Dashboard;
