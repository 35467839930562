import React from "react";
import "./TextGroup.css";

export const TextGroup = ({
    className = "",
    contentClassName = "",
    flexible = false,
    title = undefined,
    heading = undefined,
    content = undefined,
    children = undefined
}) => (
    <div className={`textGroup${flexible ? " tgFlexible":""} ${className}`}>
        {(title) && <p>{title}</p>}
        {(heading) && <b>{heading}</b>}
        <div className={`textGroupContent ${contentClassName}`}>
            {content}
            {children}
        </div>
    </div>
);
