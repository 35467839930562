/* eslint-disable react/display-name */
import { useSetState } from "ahooks";
import { isEmpty, cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Tab } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useMutation } from "react-query";
import isValidUrl from "validator/es/lib/isURL";
import { LoadingScreen } from "mapsted-components";
import { usePropertyOptions } from "../../../../../_utils/stateHooks";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { DropdownForm } from "../../../../common/DropdownForm";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import CopyButton from "../../../../common/CopyButton";
import { useMutationsList } from "../../../../../_api/queries";
import { Scrollbars } from "react-custom-scrollbars";
import { isValidIpv4 } from "../../../../../_utils/misc";

const DATE_FORMAT = "DD-MM-YYYY";

const getRestrictionObject = () => ({
    propertyId: -1,
    keys: [crypto.randomUUID()],
    ips: [""],
    referers: [""],
    dates: [{ start: null, end: null }],
    dirty: false,
});

const CreateUpdateRestrictionModal = ({ open, onClose, allRestrictions, isCopy, propertyId }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal");
    const isCreate = propertyId === -1;
    const isEdit = propertyId !== -1 && !isCopy;
    const propertyOptions = usePropertyOptions();

    const filteredOptions = useMemo(() =>
    {
        // Exlude all properties already having restrictions
        const exclude = (isCreate || isCopy) ? allRestrictions.map((r) => r.propertyId) : [];
        return propertyOptions.filter(({ value }) => !exclude.includes(value));
    }, [propertyId, propertyOptions, allRestrictions]);

    const [state, setState] = useSetState(getRestrictionObject());
    const [confirmModalOpen, setConfimModalOpen] = useState(false);

    const { mutate: saveRestriction , isLoading } = useMutation(
        useMutationsList().CREATE_UPDATE_RESTRICTION(closeModals)
    );

    useEffect(() =>
    {
        if (!open) return;

        const initialValues = getRestrictionObject();
        if (isCreate)
        {
            setState(initialValues);
            return;
        }

        let { restriction = {} } = cloneDeep(allRestrictions.find((r) => r.propertyId === propertyId));

        initialValues.keys = [];
        restriction = { ...initialValues, ...restriction };

        if (isCopy)
        {
            // Generate same no. of new access keys when copying restriction
            restriction.keys = restriction.keys.map((_) => crypto.randomUUID());
        }

        restriction.dates = restriction.dates.map(({ start, end }) => ({
            start: start ? moment(start, DATE_FORMAT).toDate() : null,
            end: end ? moment(end, DATE_FORMAT).toDate(): null
        }));

        setState({ ...restriction, propertyId: isCopy ? -1 : propertyId });

    }, [allRestrictions, open, propertyId, isCreate, isCopy]);

    const handleChange = (updates) =>
    {
        setState({ ...updates, dirty: true });
    };

    const handleSave = () =>
    {
        let { propertyId, keys, ips, referers, dates } = state;

        // Exclude empty rows
        ips = ips.filter((ip) => !isEmpty(ip));
        referers = referers.filter((ref) => !isEmpty(ref));
        dates = dates.filter(({ start, end }) => start || end);

        if (!isValidData({ propertyId, keys, ips, referers, dates, trans })) return;

        saveRestriction({
            propertyId,
            restriction: {
                keys,
                ips,
                referers,
                dates: dates.map(({ start, end }) => ({
                    start: moment(start).format(DATE_FORMAT),
                    end: moment(end).format(DATE_FORMAT)
                }))
            }
        });
    };


    const handleClose = () =>
    {
        if (state.dirty)
        {
            setConfimModalOpen(true);
        }
        else
        {
            closeModals();
        }
    };

    function closeModals ()
    {
        setConfimModalOpen(false);
        onClose();
    }

    return (
        <>
            <LoadingScreen active={isLoading} />
            <ModalLayout
                className="mapsWebApiModal"
                heading={trans[`${isEdit ? "Update": "Create"}_Maps_Web_Access_Restriction`]}
                actions={<Button disabled={!state.dirty} primary color="orange" floated="right" content={trans["Save"]} onClick={handleSave} />}
                open={open}
                onClose={handleClose}
            >
                <p className="paragraph">
                    {trans["To_configure_the_restriction_criteria"]}
                </p>

                <div className="modalRow">
                    <TextGroup className="tgRestrictionModal" heading={trans["Property_Name"]}>
                        <DropdownForm
                            disabled={isEdit}
                            icon="chevron down"
                            options={filteredOptions}
                            placeholder={trans["Select_Property"]}
                            onChange={(e, { value }) => handleChange({ propertyId: value })}
                            value={state.propertyId}
                            search
                            selectOnBlur={false}
                        />
                    </TextGroup>
                </div>

                <TextGroup heading={trans["Set_Application_Restrictions"]}>
                    <p className="paragraph">
                        {trans["Next_configure_the_criteria"]}
                    </p>
                </TextGroup>

                <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={[
                        {
                            menuItem: trans["AccessKeyTab"]["Access_Key"],
                            render: () => <AccessKeyTab keys={state.keys} setKeys={(keys) => handleChange({ keys })} />
                        },
                        {
                            menuItem: trans["HTTPReferersTab"]["HTTP_Referer"],
                            render: () => <HTTPReferersTab referers={state.referers} setReferers={(referers) => handleChange({ referers })} />
                        },
                        {
                            menuItem: trans["IPAddressesTab"]["IP_Addresses"],
                            render: () => <IPAddressesTab ips={state.ips} setIps={(ips) => handleChange({ ips })} />
                        },
                        {
                            menuItem: trans["DateRangesTab"]["Date_Ranges"],
                            render: () => <DateRangesTab dates={state.dates} setDates={(dates) => handleChange({ dates })} />
                        },
                    ]}
                    className="accessTabBox"
                />

            </ModalLayout>
            <ConfirmActionModal
                text={trans["Are_you_sure"]}
                open={confirmModalOpen}
                onClose={() => setConfimModalOpen(false)}
                confirmAction={closeModals}
            />
        </>
    );
};

const AccessKeyTab = ({ keys, setKeys }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal.AccessKeyTab");
    const { showConfirmModal, allowAddRow, handleDelete, handleAddRow, confirmDelete, cancelDelete } = useInputRows(keys, setKeys);

    return (
        <>
            <Tab.Pane attached={false}>
                <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"230px"} autoHeightMax={"230px"}>
                    <div className="alignerAccessTab">
                        <p className="paragraph">{trans["Requests_with_access_key"]}</p>

                        <div className="accessRow">
                            <div className="accessColumn ac1">
                                {keys.map((key, i) => (
                                    <div key={i} className="accessGroup">
                                        <div key={key} className="apiKeybox">
                                            <span>{key}</span>
                                            <CopyButton text={key} />
                                        </div>
                                        <ButtonIcon icon="trash2" onClick={() => handleDelete(i)} />
                                    </div>
                                ))}
                            </div>

                            <ButtonIcon
                                disabled={!allowAddRow}
                                icon="plus-filled"
                                content={trans["Add"]}
                                onClick={() => handleAddRow(crypto.randomUUID())}
                            />
                        </div>
                    </div>
                </Scrollbars>
            </Tab.Pane>
            <ConfirmActionModal
                text={trans["Are_you_sure"]}
                open={showConfirmModal}
                onClose={cancelDelete}
                confirmAction={confirmDelete}
            />
        </>

    );
};

const HTTPReferersTab = ({ referers, setReferers }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal.HTTPReferersTab");
    const { showConfirmModal, allowAddRow, handleChange, handleDelete, handleAddRow, confirmDelete, cancelDelete } = useInputRows(referers, setReferers);

    return (
        <>
            <Tab.Pane attached={false}>
                <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"230px"} autoHeightMax={"230px"}>
                    <div className="alignerAccessTab">
                        <p className="paragraph">{trans["Add_the_URLs_of_websites_approved"]}</p>

                        <div className="accessRow">
                            <div className="accessColumn">
                                {referers.map((referer, i) => (
                                    <div key={i} className="accessGroup">
                                        <Input
                                            className="inputField"
                                            placeholder="https://www.example.com"
                                            value={referer}
                                            onChange={({ target }) => handleChange(i, target.value)}
                                        />
                                        <ButtonIcon icon="trash2" onClick={() => handleDelete(i)} />
                                    </div>
                                ))}
                            </div>
                            <ButtonIcon
                                disabled={!allowAddRow}
                                icon="plus-filled"
                                content={trans["Add"]}
                                onClick={() => handleAddRow("")}
                            />
                        </div>
                    </div>
                </Scrollbars>
            </Tab.Pane>
            <ConfirmActionModal
                text={trans["Are_you_sure"]}
                open={showConfirmModal}
                onClose={cancelDelete}
                confirmAction={confirmDelete}
            />
        </>
    );
};

export const IPAddressesTab = ({ ips, setIps }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal.IPAddressesTab");
    const { showConfirmModal, allowAddRow, handleChange, handleDelete, handleAddRow, confirmDelete, cancelDelete } = useInputRows(ips, setIps);

    return (
        <>
            <Tab.Pane attached={false}>
                <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"230px"} autoHeightMax={"230px"}>
                    <div className="alignerAccessTab">
                        <p className="paragraph">{trans["Specify_the_ip_address"]}</p>
                        <div className="accessRow">
                            <div className="accessColumn">
                                {ips.map((ip, i) => (
                                    <div key={i} className="accessGroup">
                                        <Input
                                            className="inputField"
                                            value={ip}
                                            onChange={({ target }) => handleChange(i, target.value)}
                                            placeholder="192.0.2.146"
                                        />
                                        <ButtonIcon icon="trash2" onClick={() => handleDelete(i)} />
                                    </div>
                                ))}
                            </div>
                            <ButtonIcon
                                disabled={!allowAddRow}
                                icon="plus-filled"
                                content={trans["Add"]}
                                onClick={() => handleAddRow("")}
                            />
                        </div>
                    </div>
                </Scrollbars>
            </Tab.Pane>
            <ConfirmActionModal
                text={trans["Are_you_sure"]}
                open={showConfirmModal}
                onClose={cancelDelete}
                confirmAction={confirmDelete}
            />
        </>
    );
};

export const DateRangesTab = ({ dates, setDates }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal.DateRangesTab");
    const { showConfirmModal, allowAddRow, handleChange, handleDelete, handleAddRow, confirmDelete, cancelDelete } = useInputRows(dates, setDates, (val) => !val?.start && !val?.end);

    return (
        <>
            <Tab.Pane attached={false}>
                <div className="alignerAccessTabScroll">
                    <p className="paragraph">{trans["Add_a_start_and_end_date"]}</p>
                    <div className="accessRow accessRowDate">
                        <div className="accessColumn">
                            {dates.map(({ start, end }, i) => (
                                <>
                                    <div className="accessDateGroup">
                                        <TextGroup flexible title={trans["Start_Date"]}>
                                            <DatePicker
                                                dateFormat="dd-MMM-yyyy"
                                                // popperPlacement="top"
                                                customInput={<Input className="inputField" icon="calendar outline" />}
                                                selected={start}
                                                onChange={(date) => handleChange(i, { start: date, end })}
                                                selectsStart
                                                startDate={start}
                                                endDate={end}
                                                minDate={new Date()}
                                            />
                                        </TextGroup>
                                        <span>-</span>
                                        <TextGroup flexible title={trans["End_Date"]}>
                                            <DatePicker
                                                dateFormat="dd-MMM-yyyy"
                                                // popperPlacement="top"
                                                customInput={<Input className="inputField" icon="calendar outline" />}
                                                selected={end}
                                                onChange={(date) => handleChange(i, { start, end: date })}
                                                selectsEnd
                                                startDate={start}
                                                endDate={end}
                                                minDate={start}
                                            />
                                        </TextGroup>
                                        <ButtonIcon icon="trash2" onClick={() => handleDelete(i)} />
                                    </div>
                                </>
                            ))}


                        </div>
                        <ButtonIcon
                            disabled={!allowAddRow}
                            icon="plus-filled"
                            content={trans["Add"]}
                            onClick={() => handleAddRow({ start: null, end: null })}
                        />
                    </div>
                </div>
            </Tab.Pane>
            <ConfirmActionModal
                text={trans["Are_you_sure"]}
                open={showConfirmModal}
                onClose={cancelDelete}
                confirmAction={confirmDelete}
            />
        </>
    );
};


const ConfirmActionModal = ({ open, onClose, confirmAction, text }) =>
{
    const trans = useTranslation().t("ManageRestrictions.CreateUpdateRestrictionModal");

    return (
        <ModalLayout
            className="alignCenterModal"
            open={open}
            onClose={onClose}
            heading={trans["Confirm_Action"]}
            size="mini"
            // centered
            actions={
                <>
                    <Button onClick={onClose} color="grey" floated="left" content={trans["No"]} />
                    <Button onClick={confirmAction} color="orange" floated="right" content={trans["Yes"]} />
                </>
            }
        >
            <p className="pModal">{text}</p>
        </ModalLayout>
    );
};



export default CreateUpdateRestrictionModal;


function useInputRows(array, setArray, checkEmpty = isEmpty)
{
    const [selectedRow, setSelectedRow] = useState(-1);
    // No rows or last row not empty
    const allowAddRow = isEmpty(array) || !checkEmpty(array[array.length - 1]);

    const handleChange = (idx, newValue) =>
    {
        setArray(array.map((oldVal, i) => i === idx ? newValue : oldVal));
    };

    const deleteRow = (idx) => setArray(array.filter((v) => v !== array[idx]));

    const handleDelete = (idx) =>
    {
        if (checkEmpty(array[idx]))
        {
            deleteRow(idx);
        }
        else
        {
            setSelectedRow(idx);
        }
    };

    const confirmDelete = () =>
    {
        if (selectedRow === -1) return;

        deleteRow(selectedRow);
        setSelectedRow(-1);
    };

    const cancelDelete = () => setSelectedRow(-1);

    const handleAddRow = (newValue) =>
    {
        if (allowAddRow)
        {
            setArray([...array, newValue]);
        }
    };

    return { showConfirmModal: selectedRow !== -1, allowAddRow, handleChange, handleDelete, handleAddRow, confirmDelete, cancelDelete };
}


function isValidData({ propertyId, keys, ips, referers, dates, trans })
{
    // TODO: Add translations
    if ([-1, undefined, ""].includes(propertyId))
    {
        return alert(trans("AccountSettings.Please_Select_Property"));
    }

    const hasInvalidReferer = referers.some((ref) => !isValidUrl(ref, {
        require_protocol: true,
        require_valid_protocol: true,
        protocols: ["http","https"]
    }));
    if (hasInvalidReferer)
    {
        return alert(trans("AccountSettings.Please_Enter_Valid_Referer"));
    }

    const hasInvalidIp = ips.some((ip) => !isValidIpv4(ip));
    if (hasInvalidIp)
    {
        return alert(trans("AccountSettings.Please_Enter_Valid_Ip_Address"));
    }

    const hasInvalidDate = dates.some(({ start, end }) => (
        !moment(start).isValid()
        || !moment(end).isValid()
        || moment().isAfter(moment(start), "days")
        || moment(start).isAfter(moment(end), "days")
    ));
    if (hasInvalidDate)
    {
        return alert(trans("AccountSettings.Please_Enter_Valid_Date"));
    }

    // Validations (atleast 1 is required)
    if (isEmpty(keys) && isEmpty(ips) && isEmpty(referers) && isEmpty(dates))
    {
        return alert(trans("AccountSettings.Please_Configure_Atleast_One_Restriction"));
    }

    return true;
}
