
import { CloseAccountProvider } from "./CloseAccountContext";
import { useSetState } from "ahooks";
import { CloseAccountTypeModal } from "./CloseAccountTypeModal";
import { Button } from "semantic-ui-react";
import React from "react";
import { LoadingScreen } from "mapsted-components";
import { useQuery } from "react-query";

import { CloseAccountModalStep3 } from "./CloseAccountModalStep3";
import { CloseAccountConfirmModal } from "./CloseAccountConfirmModal";
import { CLOSE_ACCOUNT_TYPES } from "./closeAccountConfig";
import { ReasonSelectionModal } from "./ReasonSelectionModal";
import { DeferPaymentConfirmModal } from "./DeferPaymentConfirmModal";
import DeleteDataModal from "./DeleteDataModal";
import RedirectModal from "./RedirectModal";
import { QUERIES } from "../../../../../_api/queries";
import { useTranslation } from "react-i18next";


const CloseAccount = () => 
{

    const holdStatus = useQuery(QUERIES.accounts.HOLD_STATUS());

    const [state, setState] = useSetState({
        openedModal: -1,
        closeAccountType: CLOSE_ACCOUNT_TYPES.HOLD,
        closeAccountData: null,
        loading: false
    });

    const openModal = React.useCallback((index) => setState({ openedModal: index }), [setState]);
    const onClose = React.useCallback(() => setState({ openedModal: -1 }),[setState]);
    
    const nextStep = React.useCallback(() => setState((prev) => ({ openedModal: prev.openedModal + 1 })), [setState]);
    const prevStep = React.useCallback(() => setState((prev) => ({ openedModal: prev.openedModal - 1 })), [setState]);
    
    const modalOpenProps = React.useCallback((index) => ({ open: state.openedModal === index, onClose }) ,[onClose, state.openedModal]);
    
    const showLoader = React.useCallback(() => setState({ loading: true }),[setState]);
    const hideLoader = React.useCallback(() => setState({ loading: false }),[setState]);
    const trans = useTranslation().t;

    return (
        <>
            <CloseAccountProvider value={{ 
                state,
                holdStatus: holdStatus.data || { data: {} },
                handlers: { setState, nextStep, prevStep },
                loader: {
                    show: showLoader,
                    hide: hideLoader
                }
            
            }}>
                <p className="paragraph blackPara">{trans("CloseAccount.Close_Account_Desc")} <br />
                    <Button className="clickHereButton" onClick={() => openModal(0)} disabled={holdStatus.isLoading} loading={holdStatus.isLoading}>{trans("CloseAccount.Click_Here")}</Button>
                </p>
                <CloseAccountTypeModal {...modalOpenProps(0)}/>
                <ReasonSelectionModal {...modalOpenProps(1)} />
                {
                    {
                        [CLOSE_ACCOUNT_TYPES.HOLD]: <>
                            <DeferPaymentConfirmModal {...modalOpenProps(2)} />
                        </>,
                        [CLOSE_ACCOUNT_TYPES.CLOSE]: <>
                            {/* <CloseAccountModal {...modalOpenProps()} /> */}
                            {/* <CloseAccountModalStep2 {...modalOpenProps(1)} /> */}
                            <DeleteDataModal {...modalOpenProps(2)} />
                            <CloseAccountModalStep3 {...modalOpenProps(3)} />
                            <CloseAccountConfirmModal {...modalOpenProps(4)} />
                            <RedirectModal {...modalOpenProps(5)} />
                        </>,
                    }[state.closeAccountType]
                }
            </CloseAccountProvider>
            <LoadingScreen active={state.loading} />
        </>
    );
};

export default CloseAccount;
