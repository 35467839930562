
import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import ClipboardJS from "clipboard";
import { ButtonIcon } from "./ButtonIcon";
import { useTranslation } from "react-i18next";

const CopyButton = ({ text, disableClass = false }) =>
{
    const [showCopied, setShowCopied] = useState(false);
    useEffect(() =>
    {
        const clipboard = new ClipboardJS("#webCopyButton");
        return () => clipboard.destroy();
    }, []);

    // on close reset to Copy to Clipboard if it is showing Copied
    const handleClose = () =>
    {
        if (showCopied)
        {
            setShowCopied(false);
        }
    };

    const trans = useTranslation().t;

    return (
        <Popup
            content={showCopied ? trans("CopyPopup.Copied"): trans("CopyPopup.Copy_To_Clipboard") }
            open={showCopied || undefined}
            basic
            position="top center"
            size="small"
            inverted
            on="hover"
            onClose={handleClose}
            trigger={(
                <ButtonIcon
                    className={`${disableClass ? "" : "webCopyButton"}`}
                    icon="copy"
                    onClick={() => setShowCopied(true)}
                    id="webCopyButton"
                    data-clipboard-text={text}
                    data-clipboard-action="copy"
                />
            )}
        />
    );
};
export default CopyButton;
