import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { DEVICE_TYPES, YOUTUBE_IDS } from "../../constants";

import { ContentGroup } from "../../../common/ContentGroup";
import { MobileOsIntegration } from "./MobileOsIntegration";

export const MobileIntegration = () =>
{
    const trans = useTranslation().t;

    return (
        <ContentGroup heading={trans("MobileIntegration.Integrate_Mapsted_Maps_on_Mobile_App")}>
            <p className="paragraph">
                <Trans i18nKey="MobileIntegration.Please_refer_to_the_instructional_video_" components={{
                    //eslint-disable-next-line jsx-a11y/anchor-has-content -- has content when rendered
                    mail: <a href="mailto:support@mapsted.com" target="_blank" rel="noreferrer" />
                }} />
            </p>
            <p className="paragraph">
                <Trans i18nKey="MobileIntegration.When_a_new_licence" components={{ em: <b /> }} />
            </p>
            <MobileOsIntegration
                deviceType={DEVICE_TYPES.ANDROID}
                inputLabel={trans("MobileIntegration.Android_Package_Name")}
                youtubeId={YOUTUBE_IDS.ANDROID_INTEGRATION}
                videoCaption={trans("MobileIntegration.Watch_this_video_to_integrate_Mapsted_Ma_Android")}
            />
            <MobileOsIntegration
                deviceType={DEVICE_TYPES.IOS}
                inputLabel={trans("MobileIntegration.iOS_Bundle_ID")}
                youtubeId={YOUTUBE_IDS.IOS_INTEGRATION}
                videoCaption={trans("MobileIntegration.Watch_this_video_to_integrate_Mapsted_Ma_iOS")}
            />
        </ContentGroup>
    );
};
