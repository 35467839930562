import React, { useEffect } from "react";
import { useSetState } from "ahooks";
import { Button, Checkbox, Image, Input } from "semantic-ui-react";
import { ContentGroup } from "../../../../common/ContentGroup";
import { TextGroup } from "../../../../common/TextGroup";
import { SectionSettings } from "../../SectionSettings";
import { toNumber, isFinite, isInteger } from "lodash";
import { useMutation, useQuery } from "react-query";
import { QUERIES, useMutationsList } from "../../../../../_api/queries";
import { LoadingScreen } from "mapsted-components";
import { UserBasedConfiguration } from "./UserBasedConfiguration";
import { DEFAULT_SESSION_DURATION, MAX_SESSION_DURATION } from "../../../../../_utils/constants";
import { useTranslation } from "react-i18next";



const TITLE =  "SessionConfiguration.Session_Configuration";

const OPTIONS = {
    DEFAULT: { title: "Default", content: `${DEFAULT_SESSION_DURATION / 60} hrs`, icon: "icon-security-default", value: 0 },
    COMAPANY_BASED: { title: "Custom", content: null, icon: "icon-security-company", value: 1 },
    USER_BASED: { title: "User Based", content: null, icon: "icon-security-user", value: 2 }
};


export const SessionConfiguration = ({ addBreadCrumb, removeBreadCrumbAfter }) =>
{
    const trans = useTranslation().t;

    const { isLoading: getConfigLoading } = useQuery({
        ...QUERIES.GET_SESSION_CONFIG(),
        refetchOnWindowFocus: false,
        // refetchOnMount: false,
        onSuccess: (data) =>
        {
            const { type, ...rest } = data;
            setState({
                selectedOption: Object.values(OPTIONS).find((option) => option.value === type),
                ...rest
            });
        }
    });
    const { mutate: updateSessionConfig, isLoading: updateConfigLoading } = useMutation({
        ...useMutationsList().UPDATE_SESSION_CONFIG(),
        onSettled: (updated) =>
        {
            setState({ errorMsg: updated ? null : trans("SessionConfiguration.Something_Went_Wrong"),  dirty: !updated });
        }
    });

    const [state, setState] = useSetState({
        selectedOption: OPTIONS.DEFAULT,
        errorMsg: null,
        dirty: false,
        // for company based
        companyDurationMins: DEFAULT_SESSION_DURATION,
        // for user based
        usersDurationMins: {}
    });

    useEffect(() =>
    {
        addBreadCrumb({
            name: trans(TITLE),
            onClick()
            {
                setState({ selectedOption: OPTIONS.DEFAULT });
                // remove all breadcrums after current one
                removeBreadCrumbAfter(trans(TITLE));
            }
        });
    }, []);

    const handleDurationChange = (user, value) =>
    {
        value  = toNumber(value);
        if (!isFinite(value) || value > MAX_SESSION_DURATION) return;
        const newState = { dirty: true };
        if (state.selectedOption === OPTIONS.COMAPANY_BASED)
        {
            newState.companyDurationMins = value;
        }
        // Option Selected -> User Based
        else
        {
            newState.usersDurationMins = {
                ...state.usersDurationMins,
                [user.id]: value
            };
        }

        setState(newState);
    };

    const handleSave = () =>
    {
        if (state.selectedOption === OPTIONS.COMAPANY_BASED && !isValidDuration(state.companyDurationMins))
        {
            // return setState({ errorMsg: "Expiration must be greater than 0" });
            return alert(trans("SessionConfiguration.Duration_Must_Be_Greater_Than_0"));
        }

        if (state.selectedOption === OPTIONS.USER_BASED && !isValidUsersDurations(state.usersDurationMins))
        {
            // return setState({ errorMsg: "Expiration must be greater than 0" });
            return alert(trans("SessionConfiguration.Session_Timeout_Must_Be_Greater_Than_0"));
        }

        setState({ errorMsg: null });

        const { selectedOption, companyDurationMins, usersDurationMins } = state;
        updateSessionConfig({
            type: selectedOption.value,
            companyDurationMins,
            usersDurationMins
        });
    };

    return (
        <>
            <LoadingScreen active={getConfigLoading || updateConfigLoading} />
            <div className="sessionConfigWrapper">
                <SectionSettings className="sectionSettingsConfiguration">
                    <p className="paragraph">
                        {trans("SessionConfiguration.Automatically_Logs_Info")}
                    </p>
                    <ContentGroup heading={trans("SessionConfiguration.JWT_Configuration")}>
                        <div className="chooseConfigurationGroup">
                            {Object.values(OPTIONS).map((option) => (
                                <ChooseConfigurationItem
                                    key={option.title}
                                    {...option}
                                    checked={state.selectedOption === option}
                                    onChange={() => setState({ selectedOption: option, dirty: true })}
                                />
                            ))}

                        </div>
                        <p className="paragraph">
                            {trans("SessionConfiguration.Security_Info")}
                        </p>
                        {state.selectedOption === OPTIONS.COMAPANY_BASED && (
                            <TextGroup className="tgExpiration" title={trans("SessionConfiguration.Duration")}>
                                <Input
                                    placeholder="e.g. 3600"
                                    value={state.companyDurationMins}
                                    onChange={(e, { value }) => handleDurationChange(null, value)}
                                />
                                <span>{trans("SessionConfiguration.Session_Lasts_Info")}</span>
                            </TextGroup>
                        )}

                        {state.selectedOption === OPTIONS.USER_BASED && (
                            <UserBasedConfiguration
                                usersDurationMins={state.usersDurationMins}
                                onDurationChange={handleDurationChange}
                            />
                        )}

                    </ContentGroup>

                    <Button
                        color="orange"
                        floated="right"
                        content={trans("SessionConfiguration.Save")}
                        onClick={handleSave}
                        disabled={!state.dirty}
                    />

                </SectionSettings>
            </div>
        </>
    );
};

const ChooseConfigurationItem = ({ title, icon, content, checked, onChange }) => (
    <div className={`chooseConfigurationItem${checked ? " active": ""}`}>
        <Checkbox radio label={title} checked={checked} onChange={onChange}/>
        <div className="chooseConfigurationBox">
            <Image src={`/img/${icon}.svg`} />
            {content && <span>{content}</span>}
        </div>
    </div>
);

const isValidDuration = (duration) => isInteger(duration) && duration > 0 && duration <= MAX_SESSION_DURATION;
const isValidUsersDurations = (durations) => Object.values(durations).every(isValidDuration);
