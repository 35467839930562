import React from "react";
import { Button } from "semantic-ui-react";

export const ButtonIcon = ({ className = "", icon, content = undefined, ...rest }) => (
    <Button {...rest} className={`buttonIcon${className ? " "+className:""}`}>
        {
            icon && <img src={`/img/icon-${icon}.svg`} alt="icon" />
        }
        {content}
    </Button>
);
