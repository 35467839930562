/**
 * Retrieves the token from url params
 * removes it if remove is true
 * @param {boolean} [remove = false]
 * @returns {string | null}
 */
export const getSilentLoginTokenFromUrl = (remove = true) =>
{
    var searchParams = new URLSearchParams(window.location.search);
    const userToken = searchParams.get("t");
    if (userToken && remove)
    {
        searchParams.delete("t");
        if (window.history.replaceState)
        {
            let params = searchParams.toString();
            if (params.length > 0)
                params = `?${params}`;
            window.history.replaceState({}, "", `${window.location.origin}${params}`);
        }
    }
    return userToken;
};


/**
 * Redirects the browser to targetUrl with token in url param if supplied
 * @param {*} targetURL
 * @param {string} token user token to add as a url param
 * @param {*} windowName Opens in a targeted tab if not falsey
 */
export const windowRedirect = (targetURL, token, windowName = undefined) =>
{
    if (targetURL.startsWith("/"))
    {
        return window.location = targetURL;
    }

    if (windowName)
    {
        window.open(targetURL, windowName);
    }
    else if (token)
    {
        const url = new URL(targetURL);
        url.searchParams.set("t", token);
        window.location = url.toString();
    }
    else
    {
        window.location = `${targetURL}`;
    }
};

/**
 * Gets callback url from the url keeping the query params of the callback intact
 * @param {string} url
 * @returns {string} callback url
 */
export const getCallbackFromUrl = (url) =>
{
    const queryParams = new URL(url).search;
    const callbackUrl = new URLSearchParams(queryParams).toString().replace("cb=", "");
    return decodeURIComponent(callbackUrl);
};

/**
 * Securely opens a url in a new tab
 * @param url
 */
export const openInNewTab = (url) =>
{
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};
