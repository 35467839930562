import React, { useMemo } from "react";
import ReactNotification, { store } from "react-notifications-component";
import { useLocalStorageState } from "ahooks";
import serverApi from "../../_api";

/** @type {React.Context<Partial<import("../../../types/store").NotificationContextProps>>} */
const NotificationContext = React.createContext({});

// data stored in localstorage
const BLANK_NOTIFICATION = {
    id: 0,
    // date: new Date(),
    // read: false,
    type: "error",
    heading: "",
    message: "",
    // dismissed: false,
    // expiry: 1000 * 60 * 24 * 3
};

/** options for react-notifications-component
 * @type {import("react-notifications-component").ReactNotificationOptions} */
const DEFAULT_OPTIONS = {
    container: "top-right",
    dismiss: {
        duration: 3000,
        // onScreen: true,
        pauseOnHover: true,
        showIcon: true
    },
    dismissable: { click: true },
};

const NotificationProvider = ({ children }) =>
{
    const userId = serverApi?.userData?.user?.userInfo?.id;
    const [allNotifications, setNotifications] = useLocalStorageState("notifications", { defaultValue: [] });
    const notifications = useMemo(() => allNotifications.filter((n) => n.userId === userId || n.userId === undefined), [allNotifications, userId]);

    /**
     * Wrapper around react-notifications-component for defaults
     * @param {"success" | "danger" | "info" | "default" | "warning"} type
     * @param {string} title
     * @param {string} message
     * @returns {string} created message id
     */
    const addNotification = (type, title, message) =>
    {
        const notification = {
            type,
            title,
            message,
        };
        /** @type {import("react-notifications-component").ReactNotificationOptions} */
        const options = {
            ...notification,
            ...DEFAULT_OPTIONS,
            onRemoval: (id, removedBy) =>
            {
                if (removedBy === "click")
                {
                    removeNotification(id);
                }
            }
        };
        const id = store.addNotification(options);
        setNotifications([...notifications, { ...BLANK_NOTIFICATION, date: new Date(), id, ...notification }]);
        return id;
    };

    const removeNotification = (id) => setNotifications(notifications.filter((n) => n.id !== id));

    const value = {
        notifications,
        addNotification,
        removeNotification
    };

    return (
        <NotificationContext.Provider value={value}>
            <ReactNotification />
            {children}
        </NotificationContext.Provider>
    );
};

export { NotificationProvider, NotificationContext };
