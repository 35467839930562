import React from "react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { useTranslation } from "react-i18next";

/**
 *
 * @type {React.FC<import("semantic-ui-react").ModalProps>}
 */
export const DeferPaymentConfirmModal = (props) => 
{
    const trans = useTranslation().t;

    return (
        <ModalLayout {...props} success>
            <span className="successIcon">
                <img src="/img/icon-mail.svg" alt="" />
            </span>
            <p className="paraModal">{trans("CloseAccount.ThankYou_For_Submitting")}<br />
                {trans("CloseAccount.Confirmation_Email_Send")}</p>
        </ModalLayout>
    );
    
};