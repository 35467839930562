import React from "react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { ACKNOWLEDGE_TIMER } from "./closeAccountConfig";
import CloseAccountContext from "./CloseAccountContext";
import { useTranslation } from "react-i18next";

/**
 * 
 * @type {React.FC<import("semantic-ui-react").ModalProps>}  
 */
export const CloseAccountConfirmModal = (props) =>
{

    const timerId = React.useRef(null);

    const { handlers } = React.useContext(CloseAccountContext);

    const goToRedirectPage = React.useCallback(() => 
    {
        // clear timerId
        clearTimeout(timerId.current);
        handlers.nextStep();
    },[handlers]);

    const handleModalOpen = React.useCallback(() => 
    {
        timerId.current = setTimeout(goToRedirectPage, ACKNOWLEDGE_TIMER);
    },[goToRedirectPage]);

    const trans = useTranslation().t;

    return (
        <ModalLayout 
            {...props} 
            success 
            onClose={goToRedirectPage}
            onMount={handleModalOpen}
            onUnmount={() => clearTimeout(timerId.current)}
        >
            <span className="successIcon">
                <img src="/img/icon-account-delete.svg" alt="" />
            </span>
            <p className="paraModal">{trans("CloseAccount.Account_Been_Closed")}<br />
                {trans("CloseAccount.You_Will_Recieve_Email")}</p>
        </ModalLayout>
    );
};
