import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import resources from "../../../_intl/resources.json";
import "./LanguageDropdown.css";
import { LANGUAGES } from "../../../_intl/constants";

const LanguageDropdown = ({ t: trans, i18n }) =>
{
    const languageOptions = Object.keys(resources).map((lang) =>
    {
        if (Object.keys(LANGUAGES).includes(lang))
        {
            const langObj = LANGUAGES[lang];
            return ({
                icon: <i className={`${langObj.flag} flag`}></i>,
                text: langObj.title,
                value: langObj.value,
                key: langObj.value
            });
        }
        else
        {
            return null;
        }
    }).filter((val) => val != null);

    const changeLanguage = (lang) => i18n.changeLanguage(lang);

    return (
        <Dropdown button pointing
            className="languageDropdown"
            icon="angle down"
            onChange={(e, { value }) => changeLanguage(value)}
            header={trans("select language label")}
            options={languageOptions}
            text={i18n.language?.toUpperCase()}
            value={i18n.language}
        >
        </Dropdown>
    );
};

export default withTranslation()(LanguageDropdown);
