import React from "react";
import { Button } from "semantic-ui-react";
import CloseAccountContext from "./CloseAccountContext";
import { useTranslation } from "react-i18next";
/**
 * @typedef {Object} ActionButtonsProps
 * @property {Boolean} hasLeftButton true
 * @property {Boolean} hasRightButton true
 * @property {import("semantic-ui-react").ButtonProps} leftButtonProps Back
 * @property {import("semantic-ui-react").ButtonProps} rightButtonProps Next
 *
 */

/**
 *
 * @type {React.FC<ActionButtonsProps>}
 */
const ActionButtons = ({
    hasLeftButton = true,
    hasRightButton = true,
    leftButtonProps = {},
    rightButtonProps = {}
}) =>
{

    const { handlers: { nextStep, prevStep } } = React.useContext(CloseAccountContext);

    const trans = useTranslation().t;

    const { leftButtonDefaultProps, rightButtonDefaultProps } = React.useMemo(() =>
    {

        const leftButtonDefaultProps = {
                negative: true,
                floated: "left",
                onClick: prevStep,
                content: trans("ActionButton.Back")
            },
            rightButtonDefaultProps ={
                color: "orange",
                floated: "right",
                onClick: nextStep,
                content: trans("ActionButton.Next")
            };
        return { leftButtonDefaultProps, rightButtonDefaultProps };
    },[nextStep, prevStep]);

    const mergeProps = React.useCallback((props, defaultProps) => 
    {

        for (let key in defaultProps)
        {
            if ([null, undefined].includes(props[key]))
            {
                props[key] = defaultProps[key];
            }
        }

        return props;

    },[]);

    return (
        <>
            {hasLeftButton && <Button {...mergeProps(leftButtonProps, leftButtonDefaultProps)} />}
            {hasRightButton && <Button {...mergeProps(rightButtonProps, rightButtonDefaultProps)} />}
        </>
    );
};

export default ActionButtons;
