import React from "react";
import classNames from "classnames";

export const TableBoxColumn = ({
    detail = undefined,
    children = undefined,
    narrow = false,
    className = "",
    style = undefined
}) => (
    <div className={classNames("tableBoxColumn", { narrow }, className)} style={style}>
        {(detail) && <p>{detail}</p>}
        {children}
    </div>
);
