import React from "react";
import { Button, Popup, Image } from "semantic-ui-react";
import { SettingsPopupLayout } from "../SettingsPopupLayout";

export const EDIT_USER_ITEM_TYPES = {
    BUILDING: "building",
    PRODUCT: "product"
};

export const EditUserItem = ({
    content,
    detail = undefined,
    imgSrc = undefined,
    items = undefined,
    checkbox = undefined,
    link = undefined,
    type = EDIT_USER_ITEM_TYPES.BUILDING
}) => (
    <div className="editUserItem">
        {checkbox}
        {(imgSrc) && <Image as="span" src={imgSrc} />}
        <div className="editUserContent">
            <p>{content}</p>
            <span>{detail}</span>
            {link}
        </div>
        {(items) && (
            <Popup className="popupBox hasPopup"
                trigger={<Button className="buttonIcon" content={triggerText(items, type)} />}
                on="click"
                position="bottom center"
            >
                <SettingsPopupLayout heading={label(type, 2)}>
                    {items?.map((item) => <p key={item}>{item}</p>)}
                </SettingsPopupLayout>
            </Popup>
        )}
    </div>
);

function triggerText(items, type)
{
    const number = items?.length;

    return `${number} ${label(type, items.length)}`;
}

function label(type, number)
{
    if (type === EDIT_USER_ITEM_TYPES.BUILDING)
    {
        if (number === 1)
        {
            return "Building";
        }
        return "Buildings";
    }
    if (type === EDIT_USER_ITEM_TYPES.PRODUCT)
    {
        if (number === 1)
        {
            return "Product";
        }
        return "Products";
    }
}
