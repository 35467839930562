import React from "react";
import { Dropdown } from "semantic-ui-react";

export const DropdownForm = ({ className = undefined, icon = "caret down", options = defaultOptions, ...rest }) => (
    <Dropdown
        className={`dropdownGroup${className ? " "+className:""}`}
        options={options}
        icon={icon}
        {...rest}
    />

);

const defaultOptions = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
];
