import React from "react";
import { Input, Dropdown } from "semantic-ui-react";

import "./InputFormGroup.css";

export const InputWrapper = ({ className, label, info, detail, children, description, inputProps }) => (
    <div className={`inputFormGroup${className ? " "+className:""}`}>
        {
            (label) && (
                <b>{label}
                    {info && info}
                </b>
            )
        }
        {inputProps && <Input {...inputProps} />}
        {detail && <p>{detail}</p>}
        {children}
        {description && <span>{description}</span>}
    </div>
);
/*** dropdown options ***/

const defaultOptions = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
];

// placeholder ,options, search, value, icon
export const DropdownGroup = ({ dropdownProps }) =>
{
    const defaultDropdownProps = {
        options: defaultOptions,
        icon: "angle down"
    };

    let className = dropdownProps.className || "";
    if (dropdownProps.icon)
    {
        className += " icon";
    }
    const mergedProps = {
        ...defaultDropdownProps,
        ...dropdownProps,
        className
    };
    return (
        <Dropdown {...mergedProps} />
    );
};

export const TextGroup = ({ title, detail, children, extra, className }) => (
    <div className={`textGroup${className ? " "+className: ""}`}>
        {title && <b>{title}</b>}
        {detail && <p>{detail}</p>}
        {children}
        {extra && <span>{extra}</span>}
    </div>
);
