import React, { useContext } from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import zxcvbn from "zxcvbn";
import { Button, Icon, Input } from "semantic-ui-react";
import { ModalLayout } from "../../../common/ModalLayout";
import { TextGroup } from "../../../common/TextGroup";
import { LoadingScreen } from "mapsted-components";
import { AuthContext } from "../../../../_store/AuthProvider";
import { useMutation } from "react-query";
import { MUTATIONS } from "../../../../_api/queries";
import Permissions from "../../../../permissions";
import { useTranslation } from "react-i18next";

const PasswordInput = ({ field, placeholder }) =>
{
    const [visible, setVisible] = React.useState(false);
    return (
        <Input
            type={visible ? "text": "password"}
            autoComplete="off"
            icon={<Icon name={visible ? "eye slash" : "eye"} link onClick={() => setVisible((v) => !v)} />}
            placeholder={placeholder}
            {...field}
        />
    );
};

export const ChangePasswordV2 = ({ modalProps, onSuccess }) =>
{
    const ctx = useContext(AuthContext);
    const trans = useTranslation().t;

    const ERRORS = {
        currentPasswordEmpty: "PersonalPreferences.Please_Enter_Your_Password",
        newPassword: "PersonalPreferences.Password_Does_Not_Meet_Policy",
        confirm: "PersonalPreferences.Password_Do_Not_Match",
        sameAsBefore: "PersonalPreferences.New_Password_Must_Be_Different",
        tooWeak: "PersonalPreferences.Password_Too_Week",
        commonlyUsed: "PersonalPreferences.Commonly_Used_Password",
        addMoreWords: "PersonalPreferences.Add_More_Words",
        failed: "PersonalPreferences.Failed_Changing_Password",
        repeats: "PersonalPreferences.Repeated_Password"
    };

    const isAdmin = Permissions.isAdmin || Permissions.isCompanyAdmin || Permissions.isSuperAdmin;

    const formik = useFormik({
        initialValues: { changePassword: { currentPassword: "", newPassword: "", newPasswordConfirm: "", isAdmin: isAdmin } },
        onSubmit: () => onFormSSubmit(),
        validationSchema: Yup.object().shape({
            changePassword: Yup.object().shape({
                currentPassword: Yup.string()
                    .when("isAdmin", {
                        is: true,
                        then: Yup.string(),
                        otherwise: Yup.string().required(trans(ERRORS.currentPasswordEmpty))
                    }),
                newPassword: Yup.string()
                    .required(trans(ERRORS.newPassword))
                    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*([^\w\d]|_))/, trans(ERRORS.newPassword))
                    .min(8, trans(ERRORS.newPassword))
                    .test("new-is-different-from-current", trans(ERRORS.sameAsBefore), function(value)
                    {
                        return this.parent.currentPassword !== value;
                    })
                    .test("zxcvbn", trans(ERRORS.newPassword), function (value) 
                    {
                        if (value == null)
                        {
                            return false;
                        }

                        const zxcvbnResult = zxcvbn(value);

                        if (zxcvbnResult.score < 3)
                        {
                            let message = zxcvbnResult.feedback.warning
                                || zxcvbnResult.feedback.suggestions[0]
                                || trans(ERRORS.tooWeak);

                            if (/This is a top-[0-9]* common password/g.test(message))
                            {
                                message = trans(ERRORS.commonlyUsed);
                            }
                            if (/Add another word or two. Uncommon words are better./g.test(message))
                            {
                                message = trans(ERRORS.addMoreWords);
                            }
                            if (/This is similar to a commonly used password/g.test(message))
                            {
                                message = trans(ERRORS.commonlyUsed);
                            }
                            if (/Repeats like "aaa" are easy to guess/g.test(message))
                            {
                                message = trans(ERRORS.repeats);
                            }

                            return this.createError({ message, path: this.path });
                        }
                        else
                        {
                            return true;
                        }
                    }),
                newPasswordConfirm: Yup.string()
                    .test("confirm-matches-new", trans(ERRORS.confirm), function(value)
                    {
                        return this.parent.newPassword === value;
                    })
                    .required(trans(ERRORS.confirm))
            }).required()
        }).required()
    });

    const { values, submitForm, handleSubmit, handleReset, isSubmitting, setFieldError } = formik;

    const { mutateAsync, reset } = useMutation(MUTATIONS.CHANGE_PASSWORD);

    const onFormSSubmit = async () => await mutateAsync( {
        email: ctx.state.userInfo.email,
        currentPassword: values.changePassword.currentPassword,
        newPassword: values.changePassword.newPassword
    })
        .then(({ success }) => 
        {
            if (!success)
            {
                throw new Error();
            }
        })
        .then(() => handleModalClose())
        .catch(() =>
        {
            setFieldError("changePassword.newPasswordConfirm", trans(ERRORS.failed));
        });

    const handleModalClose = () =>
    {
        handleReset();
        reset();
        onSuccess();
    };

    return (
        <ModalLayout
            className="changePassword"
            {...modalProps}
            onClose={handleModalClose}
            heading="Change Password"
            actions={<Button color="orange" floated="right" onClick={() => submitForm()} content={trans("PersonalPreferences.Change_Password")} />}
        >
            {isSubmitting && <LoadingScreen />}
            <p className="pModal">{trans("PersonalPreferences.Please_Enter_Your_Password_Below")}
                <br /><br />
                {trans("PersonalPreferences.Password_Must_Be_Atleast_Eight_Character")}
            </p>
            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    {!isAdmin && <TextGroup className="passwordField" title={trans("PersonalPreferences.Current_Password")}>
                        <Field
                            name="changePassword.currentPassword"
                            placeholder=""
                            component={PasswordInput}
                        />
                    </TextGroup>}
                    {!isAdmin && <ErrorMessage name="changePassword.currentPassword" component="div" className="field-error" />}
                    <TextGroup className="passwordField" title={trans("PersonalPreferences.New_Password")}>
                        <Field
                            name="changePassword.newPassword"
                            placeholder={trans("PersonalPreferences.Type_New_Password")}
                            component={PasswordInput}
                        />
                    </TextGroup>
                    <ErrorMessage name="changePassword.newPassword" component="div" className="field-error" />
                    <TextGroup className="passwordField" title={trans("PersonalPreferences.Confirm_New_Password")}>
                        <Field
                            name="changePassword.newPasswordConfirm"
                            placeholder={trans("PersonalPreferences.Retype_Your_New_Password")}
                            component={PasswordInput}
                        />
                    </TextGroup>
                    <ErrorMessage name="changePassword.newPasswordConfirm" component="div" className="field-error" />
                </Form>
            </FormikProvider>
        </ModalLayout>
    );
};