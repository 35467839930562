import React from "react";
import { Checkbox } from "mapsted-components";
import { ModalLayout } from "../../../../common/ModalLayout";
import ActionButtons from "./ActionButtons";
import { DELETE_DATA_ACKNOWLEDGE } from "./closeAccountConfig";
import { useTranslation } from "react-i18next";

/**
 *
 * @type {React.FC<import("semantic-ui-react").ModalProps>}
 */
const DeleteDataModal = (props) =>
{

    const [checkboxes, setCheckboxes] = React.useState(DELETE_DATA_ACKNOWLEDGE.map((item) => ({ ...item, checked: false })));

    const toggleCheckbox = React.useCallback((index) => setCheckboxes((prev) =>
    {
        const arrayClone = [...prev];
        const indexItem = { ...arrayClone[index] };
        indexItem.checked = !indexItem.checked;

        arrayClone[index] = indexItem;

        return arrayClone;

    }),[]
    );

    const isInvalid = React.useCallback((state) => state.some((item) => !item.checked),[]);
    const trans = useTranslation().t;

    return (
        <ModalLayout {...props} className="deleteDataModal"
            heading={trans("CloseAccount.Delete_Data")}
            actions={<ActionButtons rightButtonProps={{ disabled: isInvalid(checkboxes) }} />}>
            <p className="paraModal">
                {trans("CloseAccount.You_Are_About_To_Close")}
            </p>
            <span className="warningPara">{trans("CloseAccount.Warning")}</span>
            {checkboxes.map((checkbox, index) => (
                <DeleteDataItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    heading={checkbox.heading}
                    description={checkbox.description}
                    checked={checkbox.checked}
                    onChange={() => toggleCheckbox(index)}
                />
            ))}
        </ModalLayout>
    );
};

const DeleteDataItem = ({ heading, description, checked, onChange }) => (
    <div className="deleteDataItem">
        <Checkbox checked={checked} onChange={onChange} name={heading} className="secondary" />
        <p>
            <strong>{heading}</strong>
            {description}
        </p>
    </div>
);

export default DeleteDataModal;
