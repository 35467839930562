import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { ContentGroup } from "../../../common/ContentGroup";

const LINK = "https://mapi.mapsted.com/v2.2.1/docs/";

const AdvancedMapstedMaps = () =>
{
    const trans = useTranslation().t("AdvancedMapstedMaps");

    return (
        <ContentGroup heading={trans["Intergrate_Masted_On_Web"]}>
            <p className="paragraph">
                <Trans i18nKey="AdvancedMapstedMaps.To_integrate_Mapsted_Maps_on" components={{
                    //eslint-disable-next-line jsx-a11y/anchor-has-content -- has content when rendered
                    mail: <a href="mailto:support@mapsted.com" target="_blank" rel="noreferrer" />
                }} />
            </p>

            <div className="appRow">
                <div className="appRowBody">
                    <ButtonIcon icon="open2" content={trans["Mapsted_Maps_Library"]} onClick={() => window.open(LINK, "_blank")} />
                </div>
            </div>
        </ContentGroup>
    );
};

export default AdvancedMapstedMaps;
