/* eslint-disable react/display-name */
import { useSetState } from "ahooks";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, GridColumn, Input, Modal } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useMutation } from "react-query";
import { LoadingScreen } from "mapsted-components";
import { DropdownForm } from "../../../../common/DropdownForm";
import CopyButton from "../../../../common/CopyButton";
import { useMutationsList } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";

const Modules = [
    {
        key: "CMS",
        text: "CMS",
        value: "CMS"
    },
];

const keyMap = {
    name: "Name",
    expiry: "Expiry",
    accessModes: "AccessModes",
    active: "Active"
};

const getRestrictionObject = () => ({
    name: "",
    expiry: "",
    accessModes: "",
    modules: "CMS",
    key: crypto.randomUUID(),
});

const MODAL_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    DELETE: "DELETE",
    COPY: "COPY"
};

const AvailableAccessModes = ["Create", "Update", "Read", "Delete"];

const CreateUpdateApiKeyModal = ({ open, onClose, allApiKeys, selectedApiKey, modelType }) =>
{
    const trans = useTranslation().t("ManageApplicationApiKeys.CreateUpdateApiKeyModal");
    const isCreate = modelType === MODAL_TYPE.CREATE;

    const { state: { userInfo } } = useContext(AuthContext);
    const companyId = userInfo?.company?.id;
    
    const [state, setState] = useSetState(getRestrictionObject());

    const { mutate: createApiKey , isLoading: createApiKeyLoading } = useMutation(
        useMutationsList().CREATE_API_KEY({ ...state, company: companyId }, () => 
        {
            setModes({});
            onClose();
        })
    );

    const { mutate: updateApiKey , isLoading: updateApiKeyLoading } = useMutation(
        useMutationsList().UPDATE_API_KEY(selectedApiKey?.Id, state, () => 
        {
            setModes({});
            onClose();
        })
    );

    const [modes, setModes] = useState({});

    const handleCheckboxChange = (event) => 
    {
        const { name, checked } = event.target;
        setModes({
            ...modes,
            [name]: checked
        });

    };  
    
    useEffect(() => 
    {
        const activeModes = generateModesString();
        setState({ accessModes: activeModes });
    }, [modes]);
    

    const generateModesString = () => 
    {
        const checkedNames = [];
    
        AvailableAccessModes.forEach((name) => 
        {
            if (modes[name]) 
            {
                // Pushes the first character of the AccessModes (e.g., "C" for "Create")
                checkedNames.push(name.charAt(0)); 
            }
        });
    
        return checkedNames.join(", ");
    };
    
    useEffect(() =>
    {
        if (!open) return;

        const initialValues = getRestrictionObject();
        if (isCreate)
        {
            setState(initialValues);
            return;
        }
        else 
        {
            let { Name = "", Expiry = "", Modules = "CMS", AccessModes = "", Active = false, Key = crypto.randomUUID() } = cloneDeep(allApiKeys?.find((r) => r.Id === selectedApiKey?.Id )) || {};

            const checkedModes = {};
            
            AvailableAccessModes.forEach((name) => 
            {
                checkedModes[name] = AccessModes.includes(name.charAt(0));
            });

            setModes(checkedModes);

            const activeKey = Active ? true : false;

            let apiKeyDetails = { ...initialValues, name: Name, expiry: Expiry, modules: Modules, accessModes: AccessModes, active: activeKey, key: Key };
            
            setState({ ...apiKeyDetails });

        }

    }, [allApiKeys, open, selectedApiKey, isCreate, modelType === MODAL_TYPE.COPY]);

    const IsNotDirty = (state, apiKey) => 
    {
        if (!state || !apiKey) return false;
        return Object.entries(keyMap).every(([stateKey, apiKeyKey]) => 
        {
            if (!(stateKey in state && apiKeyKey in apiKey)) return true;
            const stateValue = state[stateKey];
            const apiKeyValue = apiKeyKey === "Active" ? !!apiKey[apiKeyKey] : apiKey[apiKeyKey];
            return stateValue === apiKeyValue;
        });
    };
    
    const handleChange = (updates) =>
    {   
        setState({ ...updates });
    };

    const handleSave = () =>
    {
        if (isCreate)
        {
            createApiKey();
        }
        else updateApiKey();
    };

    return (
        <>
            <LoadingScreen active={createApiKeyLoading || updateApiKeyLoading} />

            <Modal
                size="tiny"
                closeIcon={<Button className="closeBtn" />}
                open={open}
                onClose={() => 
                {
                    setModes({});
                    onClose();
                }}
                className={"modalLayout mapsWebApiModal"}
                closeOnDimmerClick={false}
                closeOnEscape={false}
            >
                <Modal.Header>{isCreate ? `${trans["Create_Api_Key"]}` : `${trans["Update_Api_Key"]}`}</Modal.Header>
                <Modal.Content>
                    <p className="modal2-label-text">
                        { `${trans["To_Configure_Api_Key"]}`}
                    </p>
                    <div className="modal2-main-div">
                        <div className="fieldsContainer">
                            <div className="modal2-div">
                                <p className="modal2-label">{`${trans["Key_Name"]}`}</p>
                                <Input
                                    className="normal-input"
                                    value={state.name}
                                    maxLength={25}
                                    placeholder={`${trans["Api_Key_Name"]}`}
                                    onChange={(event, { value }) => 
                                    {
                                        setState({
                                            name: value
                                        });
                                    } }
                                />
                            </div>
                            <div className="modal2-div">
                                <p className="modal2-label">{`${trans["Expiry_Date"]}`}</p>
                                <Input
                                    value={moment(state.expiry).format("YYYY-MM-DD")}
                                    type={"date"}
                                    min={new Date().toISOString().split("T")[0]}
                                    placeholder={`${trans["Expiry_Date"]}`}
                                    onChange={(event, { value }) => 
                                    {
                                        setState({
                                            expiry: moment(value).format("YYYY-MM-DD HH:mm:ss")
                                        });
                                    } }
                                />
                            </div>
                        </div>
                        
                        <div className="fieldsContainer">
                            <div className="modal2-div modules-dropdown">
                                <p className="modal2-label">{`${trans["Modules"]}`}</p>
                                <DropdownForm
                                    fluid
                                    placeholder={`${trans["Modules"]}`}
                                    className="dropdown-option"
                                    value={state.modules}
                                    search
                                    selectOnBlur={false}
                                    options={Modules}
                                    onChange={(e, { value }) => handleChange({ modules: value })}
                                    icon="chevron down"
                                />
                            </div>
                            {
                                !isCreate && <div className="modal2-div">
                                    <p className="modal2-label">{`${trans["Activate_Key"]}`}</p>
                                
                                    <Input
                                        type="checkbox"
                                        checked={state.active}
                                        onChange={(event) => 
                                        {
                                            const { checked } = event.target;
                                            setState({
                                                active: checked
                                            });
                                        }}
                                    />

                                </div> 
                            }
                        </div>
                     
                        <div className="modal2-div">
                            <p className="modal2-label">{`${trans["Access_Restriction"]}`}</p>

                            <Grid columns={4} >
                                <GridColumn>
                                    <CheckboxExampleCheckbox label={"Create"} onChange={handleCheckboxChange} checked={modes["Create"] || false}/>
                                </GridColumn>
                                <GridColumn>
                                    <CheckboxExampleCheckbox label={"Read"} onChange={handleCheckboxChange} checked={modes["Read"] || false}/>
                                </GridColumn>
                                <GridColumn>
                                    <CheckboxExampleCheckbox label={"Update"} onChange={handleCheckboxChange} checked={modes["Update"] || false}/>
                                </GridColumn>
                                <GridColumn>
                                    <CheckboxExampleCheckbox label={"Delete"} onChange={handleCheckboxChange} checked={modes["Delete"] || false}/>
                                </GridColumn>
                            </Grid>
                        </div>

                        <div className="modal2-div">
                            <p className="modal2-label">{`${trans["Access_Key"]}`}</p>
                            <div className="accesskey">
                                <div
                                    key={state.key}
                                    className="apiKeybox copybutton-parent"
                                    style={{
                                        padding: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        border: "dashed 2px lightgray",
                                    }}
                                >
                                    <span style={{ fontWeight: 500, fontSize: 15 }}>{state.key}</span>
                                    <CopyButton text={state.key} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal2-actions">
                        <Button primary color="orange" disabled={!state.accessModes || !state.name || (isCreate ? false : IsNotDirty(state, selectedApiKey))} onClick={handleSave} floated="right"
                            style={{ padding: "15px", width: "130px", borderRadius: "8px" }}
                        >
                            {`${trans["Save"]}`}
                        </Button>
                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};

const CheckboxExampleCheckbox = ({ label, onChange, checked }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
        <input
            type="checkbox"
            style={{
                marginRight: "5px",
                width: "15px",
                height: "15px",
                accentColor: "limegreen",
            }}
            checked={checked}
            name={label}
            onChange={onChange}
        />
        <label htmlFor="" className="modal2-label-text">
            {label}
        </label>
        <svg
            viewBox="0 0 24 24"
            style={{
                width: "20px",
                height: "20px",
                fill: "green",
                display: "none",
            }}
        >
            <path d="M9.9 16.2l-3.4-3.4-1.4 1.4 4.8 4.8 10.6-10.6-1.4-1.4z" />
        </svg>
    </div>
);

export default CreateUpdateApiKeyModal;