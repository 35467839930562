import React from "react";
import { useQuery } from "react-query";
import { QUERIES } from "../../../_api/queries";
import { AuthContext } from "../../../_store/AuthProvider";
import { AccountAccessColumn } from "../Dashboard";
import AccessLoader from "./AccessLoader";
import AccountAccessListItem from "./AccountAccessListItem";
import Entities from "./Entities";
import { useTranslation } from "react-i18next";

const Teams = () =>
{

    const { state: authState } = React.useContext(AuthContext);
    const { data: teams, status } = useQuery({
        ...QUERIES.utr.TEAMS(authState.userInfo.company.companyUID),
        select: (teamsData) => teamsData?.filter?.((team) => team?.users?.some((user) => user.id === authState?.userInfo?.id))
            .map((team, index) => ({
                key: team.name + index,
                content: team.name,
                detail: team.desc,
                // icons: ful icon url
            }))
    });

    const trans = useTranslation().t;

    const TeamsError = ({ content, detail, trans }) => (
        <AccountAccessColumn heading={trans("Teams.Teams")} subheading={trans("Teams.You_Are_Member_Of_Following_Teams")}>
            <AccountAccessListItem content={content} detail={detail} />
        </AccountAccessColumn>
    );

    const renderTeams = React.useCallback((teams) =>
    {
        if (teams?.length === 0)
        {
            return (
                <AccountAccessColumn heading={trans("Teams.Teams")} subheading={trans("Teams.You_Are_Not_Member_Of_Following")}>
                    <div className="noneItem" >{trans("Teams.You_Are_Not_Member")}</div>
                </AccountAccessColumn>
            );
        }

        return <Entities heading={trans("Teams.Teams")} subHeading={trans("Teams.You_Are_Not_Member_Of_Following")} entities={teams} searchPlaceholder={trans("Teams.Search_Team")}/>;
    },[]);

    return ({
        loading: <AccessLoader heading={trans("Teams.Teams")} subheading={trans("Teams.You_Are_Not_Member_Of_Following")}/>,
        error: <TeamsError content={trans("Teams.Error_Getting_Teams")} detail={trans("Teams.Refresh_The_Page")} trans={trans} />,
        success: renderTeams(teams)
    })[status];
};

export default Teams;
