import React, { useEffect } from "react";
import { Button, Checkbox, Image, Input, Radio } from "semantic-ui-react";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { ContentGroup } from "../../../../common/ContentGroup";
import { DividerLine } from "../../../../common/DividerLine";
import { TextGroup } from "../../../../common/TextGroup";
import { SectionSettings } from "../../SectionSettings";
import { useTranslation } from "react-i18next";

export const IPRangeRestrictions = ({ title, addBreadCrumb, removeBreadCrumbAfter }) =>
{
    useEffect(() =>
    {
        addBreadCrumb({
            name: title,
            onClick()
            {
                // remove all breadcrums after current one
                removeBreadCrumbAfter(title);
            }
        });
    }, []);

    const trans = useTranslation().t;

    return (
        <SectionSettings>
            <p className="paragraph">
                {trans("AuditLogs.Increate_Security_Desc")}
            </p>
            <div className="checkboxGroup">
                <Radio toggle className="toggleStepVerification" label={trans("AuditLogs.IP_Range")} />
            </div>
            <TextGroup className="tgRangeRestrictions" flexible title={trans("AuditLogs.Your_Current_IP")}>
                <div className="urlcodebox">108.168.25.16</div>
            </TextGroup>
            <p className="paragraph paragraphSmall">{trans("AuditLogs.You_Will_Not_Allowed")}</p>
            <DividerLine gap="20px 0 35px" />

            <ContentGroup className="contentIPRange" heading={trans("AuditLogs.IP_Ranges")} asHeading="h5">
                <Button className="buttonIPRange" color="orange" basic content={trans("AuditLogs.Add")} />
                <div className="ipRangeGroup">
                    <div className="ipRangeItem">
                        <Input value="108.168.25.16" />
                        <img src="/img/icon-arrow-right.svg" alt=""/>
                        <Input value="108.168.25.17" />
                        <ButtonIcon icon="trash-fill" />
                    </div>

                    <div className="ipRangeItem">
                        <Input placeholder={trans("AuditLogs.Enter_Start_Range")} />
                        <img src="/img/icon-arrow-right.svg" alt=""/>
                        <Input placeholder={trans("AuditLogs.Enter_End_Range")} />
                        <ButtonIcon icon="trash-fill" />
                    </div>
                </div>
            </ContentGroup>
        </SectionSettings>
    );
};

const ChooseConfigurationItem = ({ content, icon, detail }) => (
    <div className="chooseConfigurationItem active">
        <Checkbox radio label={content} />
        <div className="chooseConfigurationBox">
            <Image src={`/img/${icon}.svg`} />
            {detail && <span>{detail}</span>}
        </div>
    </div>
);
