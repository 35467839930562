import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Tab } from "semantic-ui-react";

export const SettingsLayout = ({ children, className = "" }) => (
    <Tab.Pane>
        <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 62px)"}>
            <div className={`settingsLayout ${className}`}>
                {children}
            </div>
        </Scrollbars>
    </Tab.Pane>
);