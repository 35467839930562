

/**
 * Returns "Bob Dylan", "Bob", "Dylan" or "" depending what data exists
 * @param {any} userInfo  object with data firstName and lastName
 * @returns {string}
 */
export function getFullName(userInfo)
{
    return [userInfo?.firstName, userInfo?.lastName].filter((s) => s).join(" ");
}
