import React from "react";

const SNIPPET_PART_1 = `<head>
    <!-- Import -->
    <script src="https://maps.mapsted.com/api/v1/integration/js?id=`;
const SNIPPET_PART_2 = `"></script>
</head>

<!-- Usage -->
<script>
        // For a full-page map use:
        mapstedLib.integration.mapPage();
        // For placing the map into another element use:
        // mapstedLib.integration.mapInto(".{MAP_CONTAINER_CLASS_NAME}");
</script>
`;

export const WEB_INTEGRATION_CODE_SNIPPET = (propertyIdSpot) =>
{
    if (typeof propertyIdSpot === "string" || typeof propertyIdSpot === "number")
    {
        return `${SNIPPET_PART_1}${propertyIdSpot}${SNIPPET_PART_2}`;
    }
    else
    {
        return <>
            {SNIPPET_PART_1}
            {propertyIdSpot}
            {SNIPPET_PART_2}
        </>;
    }
};

// same enum as in C#
export const DEVICE_TYPES = {
    UNKNOWN: 0,
    ANDROID: 1,
    IOS: 2
};

export const DEVICE_TYPE_NAMES = {
    [DEVICE_TYPES.UNKNOWN]: "Unknown",
    [DEVICE_TYPES.ANDROID]: "Android",
    [DEVICE_TYPES.IOS]: "iOS",

};

export const LINKS = {
    IOS_DOCS: "https://developer.mapsted.com/mobile-sdk/getting-started/#ios",
    ANDROID_DOCS: "https://developer.mapsted.com/mobile-sdk/getting-started/#android",
};

export const YOUTUBE_IDS = {
    WEB_INTEGRATION: "0qFe53RnkeU",
    ANDROID_INTEGRATION: "oYVrEfW1M9U",
    IOS_INTEGRATION: "tttK5wIe6Qk",
};
