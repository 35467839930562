import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSetState } from "ahooks";

// import Mapsted from "./../LoadingIcons/Mapsted";

import serverApi from "../../_api";
import { windowRedirect } from "../../_utils/browser";
import { AuthContext } from "../../_store/AuthProvider";
// import { updatePageDescription } from "../../api/common";
import { Section } from "../common/Section";

import "../../mapsted.ui.css";
import "./Login.css";
import MainLogin from "./components/MainLogin";
import ForgotPassword from "./components/ForgotPassword";
import RecoveryEmailSent from "./components/RecoveryEmailSent";
import ResetPassword from "./components/ResetPassword";
import { NotificationContext } from "../notifications";
import { LoadingScreen } from "mapsted-components";
import { HeaderLogin } from "../layout/HeaderLogin";
import { FooterLogin } from "../layout/FooterLogin";
import { SERVER_URL } from "../../_utils/constants";
import { useTranslation } from "react-i18next";
// import { HeaderLogin } from "../UserPortal/Components/Header/HeaderLogin";
// import { Footer } from "../UserPortal/Components/Footer/Footer";

function passwordRecoveryToken(remove = true)
{
    var searchParams = new URLSearchParams(window.location.search);
    const recoveryToken = searchParams.get("token");

    if (remove)
    {
        searchParams.delete("token");
        if (window.history.replaceState)
        {
            let params = searchParams.toString();
            if ( params.length > 0 ) params = `?${params}`;
            window.history.replaceState({}, null, `${window.location.origin}${params}`);
        }
    }

    return recoveryToken;
}

const SCREENS = {
    LOGIN: 0,
    FORGOT_PASSWORD: 1,
    PASSWORD_RESET: 2,
    RECOVERY_EMAIL_SENT: 3
};
const Login = ({ cb }) =>
{
    const history = useHistory();
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);
    const trans = useTranslation().t;

    const [state, setState] = useSetState({
        loading: false,
        screen: SCREENS.LOGIN,
        errorMessage: undefined,
        successMessage: undefined,
        username: "",
        password: "",
        recoveryToken: "",
    });

    useEffect(() =>
    {
        serverApi.clear();

        const recoveryToken = passwordRecoveryToken();

        if (recoveryToken)
        {
            setState({ recoveryToken, screen: SCREENS.PASSWORD_RESET, loading: true });

            const validatePasswordRecoveryToken = async () =>
            {
                try
                {
                    const result = await serverApi.IsPasswordResetTokenExists(recoveryToken);

                    console.log("password reset token validation result", result);

                    setState({ loading: false });

                    if (!result)
                    {
                        setState({
                            errorMessage: trans("Login.Password_Reset_Link_Expired_Desc"),
                            recoveryToken: undefined,
                            screen: SCREENS.LOGIN
                        });
                        notificationContext.addNotification("danger", "Link Expired", trans("Login.Password_Reset_Link_Expired_Desc"));
                    }
                }
                catch (e)
                {
                    setState({
                        errorMessage: trans("Login.Server_Error"),
                        recoveryToken: undefined,
                        screen: SCREENS.LOGIN
                    });
                    notificationContext.addNotification("danger", "Password Reset Error", trans("Login.Server_Error"));
                }
            };

            validatePasswordRecoveryToken();
        }
    }, [setState, notificationContext]);

    const handleLogin = async (e, loginWithOrg) =>
    {
        e.preventDefault();
        setState({ loading: true });

        try
        {
            if (loginWithOrg)
            {
                if (!state.username)
                {
                    setState({ loading: false });
                    return;
                }

                window.open(`${SERVER_URL}/api/v5/sso/login?email=${state.username}&redirectUrl=${cb || window.location.origin}`, "_self");
            }
            else
            {
                const { token } = await authContext.login(state.username, state.password);

                if (token)
                {
                    if (cb)
                        windowRedirect(cb, token);
                    else
                        history.push("/");
                }
                else
                {
                    setState({ loading: false });
                }

            }
        }
        catch (e)
        {
            setState({ loading: false });
        }
    };

    const handleForgotPassword = (e) =>
    {
        e.preventDefault();
        setState({ screen: SCREENS.FORGOT_PASSWORD });
    };

    const handleGoBackToLogin = () => setState({
        screen: SCREENS.LOGIN,
        successMessage: undefined,
        errorMessage: undefined,
    });

    const handleSendRecoveryEmail = async (e) =>
    {
        e.preventDefault();

        setState({ loading: true });

        try
        {
            const result = await serverApi.forgotPassword(state.username);

            console.log("handle send recovery email", result);

            setState({
                loading: false,
                screen: SCREENS.RECOVERY_EMAIL_SENT
            });
        }
        catch (e)
        {
            setState({
                loading: false
            });
        }
    };

    const handlePasswordReset = async(e) =>
    {
        e.preventDefault();

        setState({ loading: true });

        try
        {
            const isSucceeded = await serverApi.resetPassword(state.password, state.recoveryToken);

            console.log("password reset", isSucceeded);

            if (isSucceeded)
            {
                if (location.pathname === "/activation")
                {
                    windowRedirect("/");
                }
                else
                {
                    notificationContext.addNotification("success", "Password Reset", trans("Login.Password_Changed"));
                    handleGoBackToLogin();
                }
            }
            else
            {
                setState({
                    errorMessage: trans("Login.Password_Could_Not_Be_Changed"),
                    successMessage: undefined
                });
            }
        }
        catch (e)
        {
            setState({
                errorMessage: trans("Login.Password_Could_Not_Be_Changed_Server_Error"),
                successMessage: undefined
            });
        }

        setState({ loading: false });
    };

    if (state.loading)
    {
        return <LoadingScreen />;
    }

    const { screen } = state;
    return (
        <React.Fragment>
            <HeaderLogin />
            <Section className={`add-login${state.RecoveryEmailSent ? " add-recovery" :""}`}>
                {(screen === SCREENS.LOGIN) && (
                    <MainLogin
                        loading={state.loading}
                        onSubmit={handleLogin}
                        onForgotPassword={handleForgotPassword}
                        setUsername={(value) => setState({ username: value })}
                        setPassword={(value) => setState({ password: value })}
                    />
                )}
                {(screen === SCREENS.FORGOT_PASSWORD) && (
                    <ForgotPassword
                        loading={state.loading}
                        onSubmit={handleSendRecoveryEmail}
                        onGoBack={handleGoBackToLogin}
                        username={state.username}
                        setUsername={(value) => setState({ username: value })}
                    />
                )}
                {(screen === SCREENS.RECOVERY_EMAIL_SENT) && (
                    <RecoveryEmailSent username={state.username} />
                )}
                {(screen === SCREENS.PASSWORD_RESET) && (
                    <ResetPassword
                        password={state.password}
                        setPassword={(value) => setState({ password: value })}
                        onResetPassword={handlePasswordReset}
                        onGoBack={handleGoBackToLogin}
                        errorMessage={state.errorMessage}
                        successMessage={state.successMessage}
                    />
                )}
            </Section>
            <FooterLogin />
        </React.Fragment>
    );
};

export default Login;
