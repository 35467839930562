import React from "react";
import { Header } from "semantic-ui-react";

const LoginSection = ({ className, heading, children }) => (
    <form className={className ? className:""}>
        <Header className="login" as="h1" content={heading} />
        {children}
    </form>
);

export default LoginSection;
