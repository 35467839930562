import React from "react";
import { Pagination, Icon } from "semantic-ui-react";
import "./PaginationBar.css";

export const PaginationBar = ({ currentPage, totalPages, setPage, startIndex, endIndex, totalRows, preview }) => (
    <div className="paginationBar">
        <Pagination
            className="paginationDashboard"
            boundaryRange={1}
            activePage={currentPage + 1}
            firstItem={null}
            lastItem={null}
            siblingRange={2}
            totalPages={totalPages}
            onPageChange={(e, { activePage }) => setPage(activePage - 1)}
            prevItem={{ content: <Icon name="angle left"/>, icon: true }}
            nextItem={{ content: <Icon name="angle right"/>, icon: true }}
        />
        {!preview && <div className="displayText">
            <span>Showing <strong>{`${startIndex + 1} - ${endIndex}`}</strong>{` of ${totalRows}`}</span>
            {/*<span><Trans i18nKey="dashboard.pagination txt" values={{ visibleCampaignsRange: `${startIndex + 1} - ${endIndex}`, totalCampaigns }} components={{ bold: <strong /> }} /></span>*/}
        </div>}
    </div>
);