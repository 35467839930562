import React from "react";
import { Button } from "semantic-ui-react";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { DividerLine } from "../../../../common/DividerLine";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import { useTranslation } from "react-i18next";

export const VerificationConfigureModal = () =>
{
    const trans = useTranslation().t;

    return (
        <ModalLayout className="verificationModal" 
            scrolling
            trigger={<Button color="orange" content={trans("TwoStepVerification.Verification_Configuration_Modal")} style={{ margin: "10px 10px 10px 0", float: "left" }} />}
            customHeading={trans("TwoStepVerification.Two_Step_Verification_Configure")}
            actions={<Button color="orange" floated="right" content={trans("TwoStepVerification.Next")} />}>
            <div className="aligner">
                <p className="paraModal pModal">
                    {trans("TwoStepVerification.In_Order_To_Access")}
                    <br /><br />
                    {trans("TwoStepVerification.Download_Instruction")}
                    <br /><br />
                    <ButtonIcon content={trans("TwoStepVerification.Google_Authenticator_For_IOS")} /> <br />
                    <ButtonIcon content={trans("TwoStepVerification.Google_Authenticator_For_Android")} /> <br />
                    <ButtonIcon content={trans("TwoStepVerification.Authy_For_IOS")} /> <br />
                    <ButtonIcon content={trans("TwoStepVerification.Authy_For_Android")} /> <br />
                    <ButtonIcon content={trans("TwoStepVerification.Auth_For_Microsoft_Windows")} /> 
                </p>

                <DividerLine gap="20px 0 20px" />

                <TextGroup heading={trans("TwoStepVerification.What_U_Need_To_Know")}>
                    <ul className="listingOrange">
                        <li>
                            {trans("TwoStepVerification.Prompt_Info")}<br /> 
                            {trans("TwoStepVerification.Code_Info")}
                        </li>
                        <li>
                            {trans("TwoStepVerification.Prompt_For_Two_Step_Auth")}
                        </li>
                        <li>
                            {trans("TwoStepVerification.Verification_Info")} <ButtonIcon content={trans("TwoStepVerification.Read_More")} />.
                        </li>
                    </ul>
                </TextGroup>
            </div>
        </ModalLayout>
    );
};