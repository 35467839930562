import React from "react";
import { Checkbox } from "mapsted-components";
import { useQueryClient } from "react-query";
import { ModalLayout } from "../../../../common/ModalLayout";
import ActionButtons from "./ActionButtons";
import { useSetState } from "ahooks";
import { CLOSE_ACCOUNT_REASONS, CLOSE_ACCOUNT_TYPES } from "./closeAccountConfig";
import serverApi from "../../../../../_api/server.api";
import CloseAccountContext from "./CloseAccountContext";
import { NotificationContext } from "../../../../notifications";
import { QUERIES } from "../../../../../_api/queries";
import { useTranslation } from "react-i18next";

/**
 *
 * @type {React.FC<import("semantic-ui-react").ModalProps>}
 */
export const ReasonSelectionModal = (props) =>
{

    const queryClient = useQueryClient();

    const { addNotification } = React.useContext(NotificationContext);
    const closeAccountContext = React.useContext(CloseAccountContext);

    const isClosingAccount = React.useMemo(() => closeAccountContext.state.closeAccountType === CLOSE_ACCOUNT_TYPES.CLOSE, [closeAccountContext.state.closeAccountType]);

    const [state, setState] = useSetState({
        reason: undefined,
        feedback: "",
        subscribe: false
    });

    const isChecked = React.useCallback((reason) => reason === state.reason,[state.reason]);

    const isValid = React.useCallback((state) =>
    {
        if (state.reason)
        {
            if (state.reason === CLOSE_ACCOUNT_REASONS.OTHER)
            {
                return Boolean(state.feedback);
            }
            return true;
        }
        return false;
    },[]);

    const handleChange = React.useCallback((reason) =>
    {
        setState({ reason });
    },[setState]);

    const handleTextChange = React.useCallback(({ target }) => setState({ feedback: target.value }),[setState]);

    const handleSubmit = React.useCallback((data) =>
    {
        if (!isClosingAccount)
        {
            delete data.subscribe;
            closeAccountContext.loader.show();

            serverApi.suspendDeleteAccount(data, "suspend")
                .then(() =>
                {
                    queryClient.invalidateQueries(QUERIES.accounts.HOLD_STATUS());
                    closeAccountContext.loader.hide();
                    closeAccountContext.handlers.nextStep();
                })
                .catch((error) =>
                {
                    closeAccountContext.loader.hide();
                    addNotification("danger", "Error", error.message);
                });
        }
        else
        {
            closeAccountContext.handlers.setState({ closeAccountData: state });
            closeAccountContext.handlers.nextStep();
        }
    },[addNotification, closeAccountContext.handlers, closeAccountContext.loader, isClosingAccount, state, queryClient]);

    const subscribeToggle = React.useCallback(() => setState((prev) => ({ subscribe: !prev.subscribe })),[setState]);

    const trans = useTranslation().t;

    return (
        <ModalLayout
            className="actionMargin"
            {...props}
            heading={isClosingAccount ? trans("CloseAccount.Close_Account") : trans("CloseAccount.Account_On_Hold")}
            actions={
                <ActionButtons hasLeftButton={isClosingAccount} rightButtonProps={{ positive: true, content: trans("CloseAccount.Next") , disabled: !isValid(state), onClick: () => handleSubmit(state) }} />
            }
        >
            {
                isClosingAccount ? (<b className="titleModal">{trans("CloseAccount.Please_Let_Us_Know_Hub_Account")}</b>) : (<>

                    <p className="paraModal">{trans("CloseAccount.Fill_Form_To_Submit_On_Hold_Request")}</p>
                    <b className="titleModal">{trans("CloseAccount.Reason_To_Hold")}</b>
                </>)
            }

            {
                Object.values(CLOSE_ACCOUNT_REASONS).map((reason) => (<Checkbox key={reason} onChange={() => handleChange(reason)} checked={isChecked(reason)} name="defer reason" radio label={reason} />))
            }

            <p className="paraTitle">{trans("CloseAccount.Feedback_Matters")}</p>
            <textarea className="feedbackArea" onChange={handleTextChange} value={state.feedback}></textarea>

            {
                isClosingAccount && <>
                    <p className="paraModal pm2">{trans("CloseAccount.Click_Next_To_Close_Account")}
                        <br /><br />
                        {trans("CloseAccount.Were_Sad_To_See_You_Go")}
                    </p>

                    <Checkbox onChange={subscribeToggle} checked={state.subscribe} name="subscribe" label={trans("CloseAccount.Email_Me_For_Latest_Updates")} />

                </>
            }

        </ModalLayout>
    );
};
