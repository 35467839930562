import React, { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Checkbox, Loader, Placeholder, Progress } from "semantic-ui-react";
import serverApi from "../../../../../_api/server.api";
import { DropdownForm } from "../../../../common/DropdownForm";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import { UploadCSVTemplate } from "../uploadCSVTemplate/UploadCSVTemplate";
import "./InviteUserModal.css";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { useSetState } from "ahooks";
import { Form, Field, FieldArray, ErrorMessage, getIn, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { MUTATIONS, QUERIES } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { NotificationContext } from "../../../../notifications";
import { Trans, useTranslation } from "react-i18next";

// Yup custom validator to ensure provided emails are all different
Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a)
{
    return this.test("unique", message, function (list)
    {
        return list.length === new Set(list.map(mapper)).size;
    });
});

export const InviteUserModal = ({ refetchUsers = () => null, open, setOpen }) =>
{
    const trans = useTranslation().t;

    const ctx = useContext(AuthContext);
    // Team checkbox form state that is not controlled by formik
    const [newUsersState, setNewUsersState] = useSetState({
        assignToTeam: false,
        teamId: undefined,
        assignTeamCheckboxError: false
    });

    // Current screen being rendered the modal - "EDITING" or "INVITING_USERS"
    const [mode, setMode] = useState("EDITING");

    // State for the INVITING_USERS progress tracker
    const [progress, setProgress] = useSetState({
        currentUser: "",
        value: 0,
        total: 0,
        error: undefined
    });

    const sameEmailMessage = trans("InviteUserModal.Some_Users_Have_Same_Email");

    const formik = useFormik({
        initialValues: { users: [{ firstName: "", lastName: "", email: "" }] },
        onSubmit: () => onFormSubmit().then(() => null, () => null),
        validationSchema: Yup.object().shape({
            users: Yup.array().of(
                Yup.object().shape({
                    firstName: Yup.string().required(),
                    lastName: Yup.string(),
                    email: Yup.string().email(trans("InviteUserModal.Email_Must_Be_Valid")).required(),
                    password: Yup.string()
                })
            )
                .unique(sameEmailMessage, (u) => u.email)
                .min(1, trans("InviteUserModal.Please_Provide_One_User_To_Invite"))
        })
    });
    const { values, submitForm, errors, handleSubmit, handleReset, touched, setFieldError, setFieldValue } = formik;

    const { mutateAsync, reset } = useMutation(MUTATIONS.utr.USER_INVITE(refetchUsers));
    const { addNotification } = useContext(NotificationContext);

    useEffect(() =>
    {
        if ((newUsersState.assignToTeam && newUsersState.teamId != null) || (!newUsersState.assignToTeam))
        {
            setNewUsersState({ assignTeamCheckboxError: false });
        }
    }, [newUsersState.assignToTeam, newUsersState.teamId, setNewUsersState]);


    const closeAll = () =>
    {
        handleReset();
        reset();
        setMode("EDITING");
        setProgress({
            currentUser: "",
            value: 0,
            total: 0,
            error: undefined
        });
        setNewUsersState({ assignToTeam: false, teamId: undefined });
        setOpen(false);
    };

    const handleInviteUsers = async () =>
    {
        setMode("INVITING_USERS");

        try
        {
            for (const [i, user] of values.users.entries())
            {
                setProgress({
                    total: values.users.length,
                    value: i,
                    currentUser: `${user.firstName} ${user.lastName} - ${user.email}`
                });

                // add new user and optionally assign them to a team
                await mutateAsync({
                    user: {
                        id: undefined,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        password: user.password,
                        phoneNumber: undefined,
                        title: undefined,
                        company: ctx.state.userInfo.company.name,
                        createdBy: ctx.state.userInfo.id,
                        token: serverApi.token, // Used to create licences
                        createUsers: false,
                    },
                    team: newUsersState.assignToTeam ? newUsersState.teamId : undefined
                });
            }

            setProgress({
                value: values.users.length,
            });

            setTimeout(() =>
            {
                closeAll();
                addNotification("success", "User(s) created successfully", "Email invites have been sent");
            }, 2000);
        }
        catch (e)
        {
            setProgress({ error: "An error occurred for this user. All previous users (if any) have been created and invited." });
        }
    };

    /**
     * Validates email and team checkbox of the form
     * @returns {Promise<boolean>}
     */
    const onFormSubmit = async () =>
    {
        // Ensure new user's emails are unique in db, otherwise display error
        const result = await serverApi.utr.validateUserEmailsUnique(values.users.map((user) => user.email));
        if (result?.length > 0)
        {
            for (const { index } of result)
            {
                setFieldError(`users.${index}.email`, trans("InviteUserModal.Already_Invited_User"));
            }
            return Promise.reject(trans("InviteUserModal.Users_Email_Already_Exist"));
        }
        // show an error if the user has clicked the team checkbox but has not selected a team.
        // The useEffect above takes care of dismissing the error when the user fixes the state.
        if (newUsersState.assignToTeam && newUsersState.teamId == null)
        {
            setNewUsersState({ assignTeamCheckboxError: true });
            return Promise.reject(trans("InviteUserModal.Please_Provide_A_Team_Or_Uncheck_Checkbox"));
        }
        await handleInviteUsers();
        return true;
    };

    function getStyles(fieldName)
    {
        if (getIn(touched, fieldName) && getIn(errors, fieldName))
            return { borderColor: "#F14850" };
    }

    /**
     * The following 3 functions are for displaying the form's error messages
     * @param index
     * @return {boolean}
     */
    function getTouchedUser(index)
    {
        const firstNameTouched = (getIn(touched, `users.${index}.firstName`) && getIn(errors, `users.${index}.firstName`));
        const lastNameTouched = (getIn(touched, `users.${index}.lastName`) && getIn(errors, `users.${index}.lastName`));
        const emailTouched = (getIn(touched, `users.${index}.email`) && getIn(errors, `users.${index}.email`));
        const emailValid = (errors?.users?.[index]?.email !== trans("InviteUserModal.Email_Must_Be_Valid") && errors?.users?.[index]?.email !== trans("InviteUserModal.Already_Invited_User"));
        return (firstNameTouched || lastNameTouched || (emailTouched && emailValid));
    }

    function getEmailValidError(index)
    {
        const emailTouched = (getIn(touched, `users.${index}.email`) && getIn(errors, `users.${index}.email`));
        const notValidEmail = errors?.users?.[index]?.email === trans("InviteUserModal.Email_Must_Be_Valid");
        return emailTouched && notValidEmail;
    }

    function getEmailUniqueError(index)
    {
        const emailTouched = (getIn(touched, `users.${index}.email`) && getIn(errors, `users.${index}.email`));
        const notValidEmail = errors?.users?.[index]?.email === trans("InviteUserModal.Already_Invited_User");
        return emailTouched && notValidEmail;
    }

    /**
     * This renders the formik form for supplying users to create/invite into the system
     * @return {JSX.Element}
     */
    const renderEditing = () => (
        <>
            <p className="pModal">
                <Trans 
                    i18nKey="InviteUserModal.To_Invite_New_User_Desc" 
                    components={{ UploadCSVTemplate: <UploadCSVTemplate setFieldValue={setFieldValue} /> }}
                />
            </p>

            <RenderTeamSection newUsersState={newUsersState} setNewUsersState={setNewUsersState} />
            <Scrollbars className="popupScroll" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"350px"}>
                <FormikProvider value={formik}>
                    <Form onSubmit={handleSubmit}>
                        <FieldArray
                            name="users"
                            render={({ remove, push }) => (<>
                                {(values?.users?.length > 0)
                                && values?.users?.map?.((user, index) => (
                                    <React.Fragment key={index}>
                                        <div className="inviteInputGroup">
                                            <TextGroup contentClassName="ui input inputForm" heading={trans("InviteUserModal.First_Name")}>
                                                <Field
                                                    name={`users.${index}.firstName`}
                                                    className="inputForm"
                                                    style={getStyles(`users.${index}.firstName`)}
                                                    placeholder="e.g. Jack"
                                                    type="text"
                                                />
                                            </TextGroup>
                                            <TextGroup contentClassName="ui input inputForm" heading={trans("InviteUserModal.Last_Name")}>
                                                <Field
                                                    name={`users.${index}.lastName`}
                                                    className="inputForm"
                                                    style={getStyles(`users.${index}.lastName`)}
                                                    placeholder="e.g. Johnson"
                                                    type="text"
                                                />
                                            </TextGroup>
                                            <TextGroup contentClassName="ui input inputForm" heading={trans("InviteUserModal.Email")}>
                                                <Field
                                                    name={`users.${index}.email`}
                                                    className="inputForm"
                                                    style={getStyles(`users.${index}.email`)}
                                                    placeholder="e.g. name@example.com"
                                                    type="email"
                                                />
                                            </TextGroup>

                                            <ButtonIcon
                                                onClick={() => remove(index)}
                                                disabled={values.users.length === 1}
                                                type="button"
                                                className="crossInvite"
                                                icon="cross"
                                            />
                                        </div>
                                        {(getEmailValidError(index))
                                        && <div className="field-error">
                                            {trans("InviteUserModal.Invalid_Email_Address")}
                                        </div>
                                        }
                                        {(getEmailUniqueError(index))
                                        && <div className="field-error">
                                            {trans("InviteUserModal.User_Already_Invited")}
                                        </div>
                                        }
                                        {(getTouchedUser(index))
                                        && <div className="field-error">{trans("InviteUserModal.First_Name_Last_Name_Required")}
                                            <br />{trans("InviteUserModal.Please_Fill_Out_Fields_Before_Continuing")}
                                        </div>
                                        }
                                    </React.Fragment>
                                ))}
                                {values.users.length === 0 && <ErrorMessage name="users" />}
                                <div className="inviteInputGroup">
                                    {/* TODO : please take this button out of <Scrollbars> */}
                                    <ButtonIcon
                                        onClick={() => push({ firstName: "", lastName: "", email: "" })}
                                        type="button"
                                        icon="plus"
                                        content={trans("InviteUserModal.Add_More_Users")}
                                    />
                                </div>
                            </>)}
                        />
                        {newUsersState.assignTeamCheckboxError && <div className="field-error">{trans("InviteUserModal.Please_Provide_A_Team_Or_Uncheck_Checkbox")}</div>}
                        <ErrorMessage name={"users.teamError"} component="div" className="field-error" />
                        {(errors.users === sameEmailMessage) && <div className="field-error">{sameEmailMessage}</div>}
                    </Form>
                </FormikProvider>
            </Scrollbars>
        </>
    );

    const renderInviteProgressTracker = (trans) => (<>
        <p>{trans("InviteUserModal.This_Might_Take_Few_Minutes")}</p>
        <Progress
            progress="ratio"
            indicating={!progress.error}
            total={progress.total}
            value={progress.value}
            label={<div>{progress.error? "": <Loader active inline size="mini" />}{" " + progress.currentUser}</div>}
        />
        {progress.error && <div className="field-error">{progress.error}</div>}
        {(progress.error && <Button color="orange" type="button" floated="right" content={trans("InviteUserModal.Close")} onClick={() => closeAll()} />)}
    </>);

    return (<>
        <ModalLayout className="inviteUserModal"
            open={open}
            onClose={() =>
            {
                if (mode === "EDITING")
                {
                    handleReset();
                    reset();
                    setNewUsersState({ assignToTeam: false, teamId: undefined });
                    setOpen(false);
                }
                else if (mode === "INVITING_USERS" && progress.error)
                {
                    closeAll();
                }
            }}
            heading={(mode === "EDITING") ? trans("InviteUserModal.Invite_New_Users") : trans("InviteUserModal.Creating_Inviting_Users")}
            actions={(mode === "EDITING") && <Button color="orange" type="submit" floated="right" content={trans("InviteUserModal.Send_Invites")} onClick={() => submitForm()} />}
        >
            {mode === "EDITING" && renderEditing()}
            {mode === "INVITING_USERS" && renderInviteProgressTracker(trans)}
        </ModalLayout>
    </>);
};

const TeamPropertiesProductsBox = ({ team }) => 
{
    const trans = useTranslation().t;

    return (
        <div className="inviteUserPropertyBox">
            <Scrollbars className="popupScroll" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax="210px">
                <div className="inviteuserPropertyInner">
                    <TextGroup heading={trans("InviteUserModal.Properties")}>
                        {team.properties.map((prop, i) => <p key={prop.id}>{`${i+1}. ${prop.name}`}</p>)}
                        {(team.properties.length < 1) && <p>{trans("InviteUserModal.None")}</p>}
                    </TextGroup>
                    <TextGroup heading={trans("InviteUserModal.Products")}>
                        {team.products?.map((product, i) => <p key={product.productId}>{`${i+1}. ${product.productName}`}</p>)}
                        {(team.products === undefined) && <p>{trans("InviteUserModal.None")}</p>}
                    </TextGroup>
                </div>
            </Scrollbars>
        </div>
    );
};


const RenderTeamSelect = ({ newUsersState, setNewUsersState }) =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);
    const { data: teamData, status } = useQuery(QUERIES.utr.TEAMS(state.userInfo.company.companyUID));

    switch (status)
    {
    case "loading":
        return <Placeholder className="gapBottom"><Placeholder.Paragraph><Placeholder.Line /><Placeholder.Line /><Placeholder.Line /></Placeholder.Paragraph></Placeholder>;
    case "success":
        return (<>
            <TextGroup className="limitedGroupCover" heading={trans("InviteUserModal.Team_Name")}>
                <DropdownForm
                    onChange={(e, { value }) => setNewUsersState({ teamId: value })}
                    options={teamData.map((team) => ({ key: team.id, text: team.name, value: team.id }))}
                    placeholder={trans("InviteUserModal.Select_Team")}
                    value={newUsersState.teamId}
                    search
                    noResultsMessage={trans("InviteUserModal.No_Teams_Found")}
                />
            </TextGroup>
            {newUsersState.teamId != null && <TeamPropertiesProductsBox team={teamData.find((team) => team.id === newUsersState.teamId)} />}
        </>);
    default:
        return "";
    }
};

const RenderTeamSection = ({ newUsersState, setNewUsersState }) => 
{
    const trans = useTranslation().t;

    return (<>
        <Checkbox
            label={trans("InviteUserModal.Assign_To_A_Team")}
            onChange={(e, { checked }) => setNewUsersState({ assignToTeam: checked })}
            className="secondary"
        />
        {newUsersState.assignToTeam && <RenderTeamSelect newUsersState={newUsersState} setNewUsersState={setNewUsersState} />}
    </>);
};
