import React from "react";
import { NotificationContext } from "../components/notifications";

import serverAPI from "../_api";
import { getSilentLoginTokenFromUrl } from "../_utils/browser";

/** @type {React.Context<Partial<import("../../types/store").AuthContextProps>>} */
const AuthContext = React.createContext({});

/* eslint-disable react/no-unused-state -- allow unused state in providers */
class AuthProvider extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            userInfo: {},
            accounts: [],
            properties: [],
            validatingToken: true,
            token: undefined
        };
    }

    componentDidMount()
    {
        const userToken = getSilentLoginTokenFromUrl();
        if (userToken)
        {
            this.silentLogin(userToken);
        }
        else
        {
            this.validateToken();
        }
    }

    logout = () => this.initUserSettings();

    login = async (username, password) =>
    {
        const userData = await serverAPI.login(username, password);
        const token = serverAPI.token;

        await this.initUserSettings(token, token && serverAPI.userData.user);
        !token && this.context.addNotification("danger", "Authentication", "The username or password you have entered is incorrect. Please try again.");

        return userData;
    }

    silentLogin = async (userToken) =>
    {
        serverAPI.clear();
        this.setState({ validatingToken: true });

        try
        {
            await serverAPI.silentLogin(userToken);
            const token = serverAPI.token;
            this.initUserSettings(token, token && serverAPI.userData.user, false);
            return token;
        }
        catch (error)
        {
            this.setState({ validatingToken: false });
        }
    }

    validateToken = async () =>
    {
        let isValid = false;
        try
        {
            isValid = await serverAPI.validateToken();
        }
        catch (err)
        {
            isValid = false;
        }

        if (isValid)
            await this.initUserSettings(serverAPI.token, serverAPI.userData.user);
        else
            await this.initUserSettings(undefined, undefined);

        return isValid;
    }

    initUserSettings = async (token, user, loading = true) =>
    {
        const userInfo = user && user.userInfo;

        this.setState({ token, userInfo, validatingToken: loading }, async () =>
        {
            if (token)
            {
                let userState = {};

                await Promise.allSettled([
                    serverAPI.getEnums().then((enums) => userState.enums = enums),
                    serverAPI.getAvatar().then((avatar) => userState.avatar = avatar),
                    serverAPI.getUserLicence().then((licence) => userState.licence = licence),
                    serverAPI.getAccounts().then((accounts) => userState.accounts = accounts),
                    serverAPI.getProperties().then((properties) => userState.properties = properties)
                ]);

                this.setState({ ...userState, validatingToken: false });

            }
            else
            {
                serverAPI.clear();
                this.setState({ userInfo: {}, accounts: [], properties: [], validatingToken: false });
            }
        });
    }



    render()
    {
        const value = {

            state: this.state,

            login: this.login,
            logout: this.logout,
            silentLogin: this.silentLogin,

            initUserSettings: this.initUserSettings,
        };

        return (
            <AuthContext.Provider value={value}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

AuthProvider.contextType = NotificationContext;

export { AuthProvider, AuthContext };
