import React from "react";
import { Button } from "semantic-ui-react";

export const EditButton = ({ className, gap, edit, add, icon, alt, content, hubIcon, href, onClick }) => (
    <Button href={href} icon basic onClick={onClick} className={`editButton${add ? " addMore":""}${content ? " buttonIcon":""}${className ? " "+className:""}`}
        style={{ marginBottom: gap && gap }}>
        {
            edit && (<img src="/img/icon-edit.svg" alt={alt} />)
        }
        {
            add && (<img src="/img/icon-add-circle.svg" alt={alt} />)
        }

        {
            icon && (<img src={`/img/icon-${icon}.svg`} alt={alt}/>)
        }

        {
            hubIcon && (<img src={`/img/hub/icon-${hubIcon}.svg`} alt={alt}/>)
        }

        {
            content && (content)
        }
    </Button>
);
