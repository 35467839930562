import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { SectionSettings } from "../SectionSettings";
import { SettingsLayout } from "../SettingsLayout";
import "./SecurityPrivacy.css";
import { SessionConfiguration } from "./sessionConfiguration/SessionConfiguration";
import { TwoStepVerification } from "./twoStepVerification/TwoStepVerification";
import { SAMLSingleSignOn } from "./samlSingleSignOn";
import { IPRangeRestrictions } from "./ipRangeRestrictions/IPRangeRestrictions";
import { AuditLogs } from "./auditLogs/AuditLogs";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const TITLE = "Security";

const OPTIONS = {
    SESSION_CONFIG: { title: "Session Configuration", content: "Automatically logs you out across all Mapsted products after a period of inactivity." },
    // TWO_STEP_VERIFICATION: { title: "Two-step Verification", content: "Provide a more secure login process." },
    SAML: { title: "SAML Single Sign-on", content: "Single sign-on with SAML allows your users to log in using your organization’s identity provider to access all your Mapsted cloud products" },
    // IP_RESTRICTIONS: { title: "IP Range Restrictions", content: "Increase security by allowing certain IP address to access entire Mapsted software portals." },
    // AUDIT_LOGS: { title: "Audit Logs", content: "View all activity and updates across the entire Mapsted HUB platform." },
};
export const SecurityPrivacy = () =>
{
    const trans = useTranslation().t;

    const [selectedOption, setSelectedOption] = useState({});
    const [breadCrumbs, setBreadCrumbs] = useState([{
        name: TITLE,
        onClick()
        {
            setSelectedOption(null);
            removeBreadCrumbAfter(TITLE); // remove this breadcrumb itself
        }
    }]);


    const addBreadCrumb = (newBreadCrumb) =>
    {
        setBreadCrumbs((prevCrumbs) => [...prevCrumbs, newBreadCrumb]);
    };

    const removeBreadCrumbAfter = (breadCrumbName) =>
    {
        setBreadCrumbs((prevCrumbs) =>
        {
            const index = prevCrumbs.findIndex((bc) => bc.name === breadCrumbName);
            return prevCrumbs.slice(0, index + 1);
        });
    };

    const renderOptions = () => (
        isEmpty(selectedOption) && (
            <>
                <p className="paragraph">
                    {trans("TwoStepVerification.We_Keep_Your_Account_Private")}
                </p>
                <div className="securityTabsCover">
                    {Object.values(OPTIONS).map((option) => (
                        <OptionItem
                            key={option.title}
                            {...option}
                            onClick={() => setSelectedOption(option)}
                        />
                    ))}
                </div>
            </>
        )
    );

    return (
        <SettingsLayout className="accountSettings">
            <div className="settingsWrapper securityWrapper">
                <SectionSettings icon="security-privacy"
                    // heading="Security"
                    breadCrumbs={breadCrumbs}
                >
                    <div className="sessionConfigWrapper">
                        {renderOptions()}
                    </div>
                </SectionSettings>

            </div>
            {selectedOption === OPTIONS.SESSION_CONFIG && (
                <SessionConfiguration
                    addBreadCrumb={addBreadCrumb}
                    removeBreadCrumbAfter={removeBreadCrumbAfter}
                />
            )}

            {selectedOption === OPTIONS.TWO_STEP_VERIFICATION && (
                <TwoStepVerification
                    title={OPTIONS.TWO_STEP_VERIFICATION.title}
                    addBreadCrumb={addBreadCrumb}
                    removeBreadCrumbAfter={removeBreadCrumbAfter}
                />
            )}

            {selectedOption === OPTIONS.SAML && (
                <SAMLSingleSignOn
                    addBreadCrumb={addBreadCrumb}
                    removeBreadCrumbAfter={removeBreadCrumbAfter}
                />
            )}

            {selectedOption === OPTIONS.IP_RESTRICTIONS && (
                <IPRangeRestrictions
                    title={OPTIONS.IP_RESTRICTIONS.title}
                    addBreadCrumb={addBreadCrumb}
                    removeBreadCrumbAfter={removeBreadCrumbAfter}
                />
            )}

            {selectedOption === OPTIONS.AUDIT_LOGS && (
                <AuditLogs
                    title={OPTIONS.AUDIT_LOGS.title}
                    addBreadCrumb={addBreadCrumb}
                    removeBreadCrumbAfter={removeBreadCrumbAfter}
                />
            )}
        </SettingsLayout>
    );
};

const OptionItem = ({ title, content, onClick }) => (
    <Button className="securityTabsItem" onClick={onClick}>
        {title}
        <span>{content}</span>
        <Icon name="caret right" />
    </Button>
);
