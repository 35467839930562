import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react";
import { useSet as useSetAHooks, useMap, useCounter } from "ahooks";
import { useLocation } from "react-router-dom";
import { isEqual, get, debounce } from "./misc";
import { AuthContext } from "../_store/AuthProvider";


/**
 * @typedef {import("yup").AnyObjectSchema} AnyObjectSchema
 */

/**
 * @typedef {Object} WatchedValue
 * @property {any} current the current value
 * @property {function(value):void} update updates the current value
 * @property {boolean} changed current === original
 * @property {string} error
 * @property {function(value):void} reset updates the original value
 * @property {function():void} resetOriginal updates the current value with the original value
 * @property {any} original the original value
 */
/**
 * For state values that change and that need to be compared to their original value
 * @param {*} value a non-reference value
 * @param {AnyObjectSchema} validationSchema
 * @returns {WatchedValue}
 */
export function useWatchedValue(value, validationSchema)
{
    const [current, update] = useState(value);
    const [original, reset] = useState(value);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState(null);

    const validate = debounce(useCallback((schema, value) =>
    {
        if (schema)
        {
            schema.validate(value)
                .then(() => setError(null))
                .catch((err) => setError(get(err, "errors[0]", null)));
        }
    },[setError]),100);

    useEffect(() =>
    {
        setChanged(!isEqual(current, original));

        validate(validationSchema, current);

    }, [current, original, setChanged, changed, validationSchema, validate]);

    const resetOriginal = React.useCallback(() => update(original),[original]);

    return {
        current,
        changed,
        original,
        error,
        reset,
        update,
        resetOriginal
    };
}

/**
 * useState with callback. from
 * https://stackoverflow.com/questions/54954091/how-to-use-callback-with-usestate-hook-in-react
 * setState(newState, current => void )
 * @param {Array} initialState [state, setState]
 */
export function useStateCallback(initialState)
{
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // mutable ref to store current callback

    const setStateCallback = (state, cb) =>
    {
        cbRef.current = cb; // store passed callback to ref
        setState(state);
    };

    useEffect(() =>
    {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
        if (cbRef.current)
        {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
}



/**
 * @typedef {Object} UseSetReturn
 * @property {Set} set Set object
 * @property {function(value):void} add adds the given value
 * @property {function(value):void} remove removes the given value
 * @property {function(value):boolean} has returns bool whether or not value is in the set
 * @property {function():void} reset resets to the original set
 */
/**
 * Wrapper around ahooks useSet to return one object
 * instead of an array with 2. Exactly the same as
 * https://ahooks.js.org/hooks/state/use-set
 * except set is returned as one of the properties
 * @param {Iterable} [initialValue] initial elements for the set
 * @returns {UseSetReturn}
 */
export function useSet(initialValue)
{
    const [set, operations] = useSetAHooks(initialValue);

    return { ...operations, set };
}


/**
 * Given a url fetches the url and returns response.status and response.ok in an array
 * @param {string} url
 * @returns {Array<number|boolean>} [status, ok]
 */
export function useFetchStatus(url)
{
    const [status, setStatus] = useState(undefined);
    const [ok, setOk] = useState(undefined);
    useEffect(() =>
    {
        fetch(url)
            .then((resp) =>
            {
                setStatus(resp.status);
                setOk(resp.ok);
            })
            .catch(() =>
            {
                setStatus(400);
                setOk(false);
            });
    }, [url]);

    return [status, ok];
}


/**
 * Like useState but the initial value is based off of query parameters
 * @param {string} searchParam search param to base the value off of
 * @param {string} defaultValue
 * @param {?Array<String>} oneOf optional if an array with length > 1 if the value is not in the array defualtValue is used insted
 * @returns {[string, Function]} value and setValue
 */
export function useStateFromQueryParams(searchParam, defaultValue, oneOf)
{
    const [value, setValue] = useState(defaultValue);

    const location = useLocation();
    useEffect(() =>
    {
        const searchParams = new URLSearchParams(location.search);
        let intialValue = searchParams.get(searchParam) || defaultValue;

        if (oneOf && Array.isArray(oneOf) && oneOf.length > 0)
        {
            intialValue = oneOf.includes(intialValue) ? intialValue : defaultValue;
        }
        setValue(intialValue);
    }, [searchParam, defaultValue, oneOf, location]);

    return [value, setValue];
}

/**
 * Given an array of elements with a varying number and order of elements
 * returns a new array of the same elements in a stable order.
 * New elements are added to the end.
 * @param {Array<any>} elements
 * @returns {Array<any>}
 */
export function useStableOrdering(elements)
{
    const [max, setMax] = useState(elements.length);

    const [orderingMap, orderingOps] = useMap(elements.map((el, i) => [el, i]));

    const [orderedEls, setOrderedEls] = useState([]);

    // give new ones a new ordering on the end
    useEffect(() =>
    {
        const elementsSet = new Set(elements);

        // remove old elements
        [...orderingMap.keys()]
            .filter((el) => !elementsSet.has(el))
            .forEach((el) => orderingOps.remove(el));

        // add to ordering new elements
        elements
            .filter((el) => !orderingMap.has(el))
            .forEach((el) =>
            {
                orderingOps.set(el, max);
                setMax(max + 1);
            });

        setOrderedEls([...elements].sort((a, b) => orderingOps.get(a) < orderingOps.get(b) ? -1 : 1));
        // eslint-disable-next-line
    }, [elements]);

    return orderedEls;
}

/**
 * @typedef {Object} UsePaginationReturn
 * @property {number} currentPage
 * @property {number} totalPages
 * @property {number} startIndex index of first element on page
 * @property {number} endIndex index of first element after elements on page
 * @property {function(number)} setPage sets the page
 * @property {function(number)} incPage increment the page
 * @property {function(number)} decPage decrement the page
 * @property {number} pageSize
 */
/**
 * Pagination numbers and operations
 * @param {number} pageSize how many elements per page
 * @param {number} nElements total number of elements
 * @param {?number} initialPage start page default 0
 * @param onChangeSetPageTo
 * @returns {UsePaginationReturn}
 */
export function usePagination(pageSize, nElements, initialPage = 0, onChangeSetPageTo = 0)
{
    const [current, ops] = useCounter(initialPage);

    const totalPages = Math.ceil(nElements / pageSize);

    const { set } = ops;
    useEffect(() =>
    {
        if (onChangeSetPageTo < 0)
        {
            set(Math.max(totalPages + onChangeSetPageTo, 0));
        }
        else
        {
            set(Math.min(totalPages - 1, onChangeSetPageTo));
        }
    }, [nElements, set, onChangeSetPageTo, totalPages]);

    return {
        currentPage: current,
        totalPages,
        startIndex: current * pageSize,
        endIndex: Math.min(current * pageSize + pageSize, nElements),
        setPage: ops.set,
        incPage: ops.inc,
        decPage: ops.dec,
        pageSize
    };
}

/**
 * @typedef {Object} CloseModalReturnType
 * @property {boolean | undefined} state
 * @property {function} close
 */

/**
 * Workaround to close a modal with trigger
 * 'state' should be passed to the open prop.
 * @returns {CloseModalReturnType}
 */
export function useCloseModal()
{
    const [state, setState] = useStateCallback(undefined);
    const close  = () => setState(false, () => setState(undefined));
    return { state, close };
}

export function usePropertyOptions(placeholderText = "Select Property")
{
    const { state: { properties } } = useContext(AuthContext);

    const propertyOptions = useMemo(() => (
        [{ key: "blank", value: "", text: placeholderText }].concat(
            Object.values(properties)
                .map((property) => ({
                    key: property.propertyId,
                    value: property.propertyId,
                    text: `${property.propertyId} - ${property.name}`
                }))
        )
    ), [properties, placeholderText]);

    return propertyOptions;
}
