import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import { Avatar } from "mapsted-components";
import LanguageDropdown from "../common/languageDropdown/LanguageDropdown";

import { AuthContext } from "../../_store/AuthProvider";
import { getFullName } from "../../_utils/user";

import "./Header.css";
import logoutIcon from "./media/icon-logout.svg";


const Header = () =>
{
    const trans = useTranslation().t;
    const location = useLocation();
    const { logout, state: authState } = useContext(AuthContext);

    const pages = React.useMemo(() => ([
        { title: trans("Header.DASHBOARD"), link: "/" },
        { title: trans("Header.Settings"), link: "/settings" },
    ]),[trans]);

    /**
     * @param {String} link
     * @return {Boolean}
     */
    const isActive = React.useCallback((link) => link.split("/")[1] === location.pathname.split("/")[1], [location.pathname]);

    return (
        <Menu secondary className="headerBar">
            <Container fluid>
                <Menu.Item content={<img src="/img/logo.svg" alt="" />} />
                {pages.map(({ link, title }) => (
                    <Menu.Item key={title} active={isActive(link)}>
                        <Link to={link}>{title}</Link>
                    </Menu.Item>
                ))}
                <Menu.Menu position="right">
                    <Menu.Item>
                        <LanguageDropdown />
                    </Menu.Item>
                    <Menu.Item className="displayPhoto">
                        {getFullName(authState?.userInfo)}
                        <Avatar imageSrc={authState?.avatar?.content} small className="headerAvatar" />
                    </Menu.Item>
                    {/* <Menu.Item>
                        <NotificationWidget />
                    </Menu.Item> */}
                    <Menu.Item onClick={logout}>
                        <img src={logoutIcon} alt={trans("Header.logout")} />
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    );
};

export default Header;
