import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Scrollbars } from "react-custom-scrollbars";
import { Header, Button } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import { LoadingScreen } from "mapsted-components";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { ContentGroup } from "../../../../common/ContentGroup";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import { QUERIES, useMutationsList } from "../../../../../_api/queries";
import { isEmpty } from "lodash";
import { ModalLayout } from "../../../../common/ModalLayout";
import "./style.css";
import CreateUpdateApiKeyModal from "./CreateUpdateApiKeyModal";
import CopyButton from "../../../../common/CopyButton";

const MODAL_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    DELETE: "DELETE",
    COPY: "COPY"
};
const ManageApiKeys = () =>
{
    const trans = useTranslation().t("ManageApplicationApiKeys");
    const { data: allApiKeys = [], isLoading: apiKeysLoading } = useQuery(QUERIES.GET_APPLICATION_API_KEYS());

    const [modelInfo, setModelInfo] = useState({ type: "" });
    const [selectedApiKey, setSelectApiKey] = useState(null);
   
    const { mutate: deleteApiKey , isLoading: deleteApiKeyLoading } = useMutation(
        useMutationsList().DELETE_API_KEY(selectedApiKey?.Id, closeModal)
    );

    function handleAction(apiKey, type)
    {
        setSelectApiKey(apiKey);
        setModelInfo({ type });
    }

    function closeModal()
    {
        setSelectApiKey(null);
        setModelInfo({ type: "" });
    }

    return (
        <>
            <LoadingScreen active={deleteApiKeyLoading} />
            <ContentGroup heading={trans["Mapsted_Api_Access_Keys"]}>
                <p className="paragraph">
                    <Trans i18nKey="ManageApplicationApiKeys.Generate_Your_Api_Key" components={{
                        //eslint-disable-next-line jsx-a11y/anchor-has-content -- has content when rendered
                        mail: <a href="mailto:support@mapsted.com" target="_blank" rel="noreferrer" />
                    }} />

                </p>

                <div className="appRow appRowRestricedAccess">
                    <div className="appRowBody">
                        <Header as="h6" className="headingRestrictedAccess">
                            {trans["Api_Keys"]}
                            <Button
                                disabled={apiKeysLoading}
                                className="buttonIcon"
                                onClick={() => setModelInfo({ type: MODAL_TYPE.CREATE })}
                            >
                                <img src={"/img/icon-plus-filled.svg"} alt={trans["Add_New"]} />{trans["Add_New"]}
                            </Button>
                            {/* )} */}
                            <CreateUpdateApiKeyModal
                                open={[MODAL_TYPE.CREATE, MODAL_TYPE.EDIT, MODAL_TYPE.COPY].includes(modelInfo.type)}
                                onClose={closeModal}
                                allApiKeys={allApiKeys}
                                selectedApiKey={selectedApiKey}
                                modelType={modelInfo.type}                                
                            />
                        </Header>

                        <div className="tableBox tableBoxRestrictedAccess">
                            <div className="tableBoxRow">
                                <TableBoxColumn detail={trans["API_KEY"]} />
                                <TableBoxColumn detail={trans["NAME"]} />
                                <TableBoxColumn detail={trans["DATE_CREATED"]} />
                                <TableBoxColumn detail={trans["RESTRICTION"]} />
                                <TableBoxColumn detail={trans["MODULES"]} />
                                <TableBoxColumn detail="" />
                            </div>
                            <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"250px"}>
                                <div className="alignerTable">
                                    {apiKeysLoading && <div className="emptytablebox">{trans["Loading"]}</div>}
                                    {!apiKeysLoading && isEmpty(allApiKeys) && <div className="emptytablebox">{trans["NO_API_KEYS"]}</div>}

                                    {allApiKeys.map((apiKey) => (
                                        <ApiKeyRow
                                            key={apiKey.Key}
                                            apiKey={apiKey.Key}
                                            name={apiKey.Name}
                                            createdAt={apiKey.CreatedAt}
                                            restriction={apiKey.AccessModes}
                                            modules={apiKey.Modules}
                                            onEdit={() => handleAction(apiKey, MODAL_TYPE.EDIT)}
                                            onCopy={() => handleAction(apiKey, MODAL_TYPE.COPY)}
                                            onDelete={() => handleAction(apiKey, MODAL_TYPE.DELETE)}
                                        />
                                    ))}

                                    <DeleteApiKey
                                        open={modelInfo.type === MODAL_TYPE.DELETE}
                                        keyName={selectedApiKey?.Name || ""}
                                        onClose={closeModal}
                                        onDelete={() => deleteApiKey(selectedApiKey)}
                                    />
                                </div>
                            </Scrollbars>

                        </div>

                    </div>
                </div>
            </ContentGroup>
        </>
    );
};

const ApiKeyRow = ({ apiKey, name, createdAt, restriction, modules, onEdit, onCopy, onDelete }) => 
{   
    const renderAccessModes = (modes) => (
        <div>
            <span className={modes.includes("C") ? "enableMode" : "disableMode"}>C</span>/ 
            <span className={modes.includes("R") ? "enableMode" : "disableMode"}>R</span>/ 
            <span className={modes.includes("U") ? "enableMode" : "disableMode"}>U</span>/ 
            <span className={modes.includes("D") ? "enableMode" : "disableMode"}>D</span> 
        </div>
    );
    
    const truncateText = (text, maxLength = 20) => (text.length > maxLength) ? text.slice(0, maxLength - 1) + "..." : text;
    
    return (
        <div
            key={apiKey}
            className="tableBoxRow">
            <TableBoxColumn detail={`${apiKey}`} className="apiKeyWidth"/>
            <TableBoxColumn detail={truncateText(name, 14)} />
            <TableBoxColumn detail={moment(createdAt).format("DD-MMM-YYYY")} />
            <TableBoxColumn detail={renderAccessModes(restriction)} />
            <TableBoxColumn detail={`${modules}`} />
            <TableBoxColumn>
                <div className="tableboxActions">
                    <CopyButton text={apiKey} disableClass={true}/>
                    <ButtonIcon icon="edit" onClick={onEdit} />
                    <ButtonIcon icon="trash2" onClick={onDelete} />
                </div>
            </TableBoxColumn>
        </div>);
};

const DeleteApiKey = ({ open, keyName, onClose, onDelete }) =>
{
    const trans = useTranslation().t("ManageApplicationApiKeys.DeleteApiKeyModal");

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            heading={trans["Delete_Api_Key"]}
            size="mini"
            actions={<Button onClick={onDelete} color="orange" floated="right" content={trans["Delete"]} />}
        >
            <p className="pModal">{`${trans["Are_you_sure_you"]} - ${keyName} ?`}</p>
        </ModalLayout>
    );
};


export default ManageApiKeys;
