import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * If user does not have permissions defined in `hasPermission`, block access to route and redirect to `redirectPath`.
 * @param {object} props
 * @param {boolean} [props.hasPermission=false]
 * @param {string} [props.redirectPath="/"]
 * @param props.children
 * @returns {JSX.Element}
 */
export function ProtectedRoute({ hasPermission = false, redirectPath = "/", children, ...rest })
{
    return (
        <Route
            {...rest}
            render={({ location }) =>
            {
                if (hasPermission)
                {
                    return children;
                }
                else
                {
                    return <Redirect to={{ pathname: redirectPath, state: { from: location } }} />;
                }
            }
            }
        />
    );
}
