import React, { useContext, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { YOUTUBE_IDS, WEB_INTEGRATION_CODE_SNIPPET } from "../../constants";

import { ContentGroup } from "../../../common/ContentGroup";
import CopyButton from "../../../common/CopyButton";
import { YoutubePlayer } from "./YoutubePlayer";
import { usePropertyOptions } from "../../../../_utils/stateHooks";

const PLACEHOLDER_TEXT = "{PROPERTY_ID}";

export const WebIntegration = () =>
{
    const trans = useTranslation().t;
    const propertyOptions = usePropertyOptions(PLACEHOLDER_TEXT);
    const [selectedPropertyId, setSelectedPropertyId] = useState("");

    return (
        <ContentGroup heading={trans("WebIntegration.Integrate_Mapsted_Maps_on_Web")}>
            <p className="paragraph">
                <Trans i18nKey="WebIntegration.Select_a_property_from_the_Property_ID_d" components={{
                    //eslint-disable-next-line jsx-a11y/anchor-has-content -- has content when rendered
                    mail: <a href="mailto:support@mapsted.com" target="_blank" rel="noreferrer" />
                }} />
            </p>
            <div className="videoEmbedCover">
                <CopyButton text={WEB_INTEGRATION_CODE_SNIPPET(selectedPropertyId || PLACEHOLDER_TEXT)} />
                <div className="videoEmbedBox large">

                    <pre style={{ margin: 0 }}>
                        <code>{WEB_INTEGRATION_CODE_SNIPPET((
                            <Dropdown
                                options={propertyOptions}
                                placeholder={PLACEHOLDER_TEXT}
                                onChange={(e, { value }) => setSelectedPropertyId(value)}
                                value={selectedPropertyId}
                                text={selectedPropertyId || PLACEHOLDER_TEXT}
                                search
                                style={{ backgroundColor: "#ffffa3" }}
                            />
                        ))}</code>
                    </pre>
                </div>
                <YoutubePlayer
                    videoId={YOUTUBE_IDS.WEB_INTEGRATION}
                    caption={trans("WebIntegration.Watch_this_video_to_integrate_Mapsted_Ma")}
                />
            </div>

        </ContentGroup>
    );
};
