import React, { useCallback, useEffect } from "react";
import { RoleActionButton } from "./View";
import serverApi from "../../../../../_api/server.api";
import { useCloseModal } from "../../../../../_utils/stateHooks";
import { useDynamicList, useSetState } from "ahooks";
import { ModalLayout } from "../../../../common/ModalLayout";
import { Button } from "semantic-ui-react";
import { CheckboxGroup } from "../../../../common/CheckboxGroup";
import { DropdownGroup, InputWrapper } from "../../../../common/InputFormGroup";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { get } from "lodash";
import { EditButton } from "../../../../common/EditButton";
import { Trans, useTranslation } from "react-i18next";

const RoleContactModal = ({ category, contacts, properties, trigger, refreshContacts }) =>
{
    const trans = useTranslation().t;

    const { defaultContact, otherContacts } = React.useMemo(() =>
    {
        const defaultContact = contacts.find((c) => c.AllProperties);
        const otherContacts = contacts.filter((c) => c !== defaultContact).map((item, index) => ({ ...item, index }));
        return { defaultContact, otherContacts };
    },[contacts]);

    const { list, remove, push, replace, resetList } = useDynamicList(otherContacts);
    const [defaultContactState, setDefaultContactState] = useSetState(defaultContact);

    const [oneContact, setOneContact] = React.useState(false);

    const reset = useCallback((otherContacts) =>
    {
        resetList(otherContacts);
        setOneContact(otherContacts.length <= 0);
    }, [resetList, setOneContact]);

    useEffect(() =>
    {
        reset(otherContacts);
    }, [otherContacts, reset]);

    const toggleOneContact = (value) =>
    {
        setOneContact(value);
        if (value)
        {
            resetList([]);
        }
        else
        {
            resetList(otherContacts);
        }
    };

    // !list.find((c) => !c.PropertyId || !c.UserId) &&
    const addContact = () =>
    {
        push(({
            PropertyId: "",
            UserId: "",
            ContactType: category.id,
            AllProperties: false,
        }));
        // scrollRef.current && scrollRef.current?.scrollToBottom()
    };

    // INFO: Role emails are sent to those contacts where dirty = true
    const modifyContact = (id, update) => replace(id, { ...update, dirty: true });

    const removeContact = (id) => remove(id);

    const handleSave = () =>
    {
        const contacts = [defaultContactState].concat(list);

        serverApi.post("/api/v5/company/contacts", { contacts }, true)
            .then((success) =>
            {
                if (success)
                {
                    refreshContacts();
                    close();

                    // Remove 'dirty' (or mark 'dirty' = false) field from previous editing
                    resetList(otherContacts);
                    setDefaultContactState({ ...defaultContact, dirty: false });
                }
            });
    };

    // workaround to close modal with trigger
    const { state: authState } = React.useContext(AuthContext);

    const { state, close } = useCloseModal();

    const onClose = () => reset(otherContacts);

    // bool - all contacts have user and (property or allProperties)
    const valid = list.map((c) => (c.PropertyId || c.AllProperties) && !!c.UserId).reduce((acc, cur) => acc && cur, true);
    const nProperties = Object.values(properties).length;

    const { data: users } = useQuery({ ...QUERIES.utr.USERS(authState.userInfo.company.companyUID), refetchOnWindowFocus: false });

    const userOptions = React.useCallback((propertyFilter) => users
        .reduce((acc, user) =>
        {
            if (!user.isSuspended)
            {
                if (propertyFilter)
                {
                    const assignedProperties = get(user, "assignedProperties", []);
                    const userHasProperty = assignedProperties.some(({ propertyId }) => Number(propertyId) === Number(propertyFilter));
                    if (userHasProperty)
                    {
                        acc.push({ key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}` });

                    }
                }
                else
                {
                    acc.push({ key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}` });
                }
            }
            return acc;
        },[]),[users]);

    const usedProperties = React.useMemo(() => new Set(list.map((c) => c.PropertyId)),[list]);

    const propertyOptions = React.useMemo(() => Object.values(properties)
        .map((p) => ({
            key: p.propertyId,
            value: String(p.propertyId),
            text: p.propertyName,
            disabled: usedProperties.has(String(p.propertyId))
        })),[properties, usedProperties]);

    const hasUnusedProperty = React.useMemo(() => propertyOptions.some((prop) => !prop.disabled) && list.length !== propertyOptions.length ,[list.length, propertyOptions]);

    const checkBoxLabel = trans("UserTeamsRoles.Set_The_Same_Cateogry_For_All_Properties", { category: trans(category.title) });

    return (
        <ModalLayout
            open={state}
            onClose={onClose}
            trigger={trigger}
            className="roleContacts tabRoleModal"
            heading={<Trans
                i18nKey="UserTeamsRoles.Set_Category"
                values={{ category: trans(category.title) }}
            />}
            actions={<>
                <Button color="orange" floated="right" content={trans("UserTeamsRoles.Done")} onClick={handleSave} disabled={!valid}/>
                {(nProperties < 1)
                    && <Button
                        basic
                        floated="right"
                        content={trans("UserTeamsRoles.Add_Properties")}
                        as="a"
                        href={"https://manage.mapsted.com/dashboard/property"}
                    />
                }
            </>}
        >

            <div className="aligner">
                <p className="pModal gap3">{trans(category.quote)}</p>

                {(nProperties > 1) && (
                    <CheckboxGroup secondary
                        checkboxProps={{
                            label: checkBoxLabel,
                            checked: oneContact,
                            onChange: (e, { checked }) => toggleOneContact(checked)
                        }}
                    />
                )}
                <div className="defaultModal flexModal" style={{ marginTop: "20px" }}>
                    <InputWrapper label={trans("UserTeamsRoles.Default_Contact")}>
                        <DropdownGroup
                            dropdownProps={{
                                value: defaultContactState && defaultContactState.UserId,
                                options: userOptions(),
                                onChange: (e, { value }) => setDefaultContactState({ UserId: value, dirty: true }),
                                placeholder: trans("UserTeamsRoles.Choose_User"),
                                search: true
                            }}
                        />
                    </InputWrapper>
                </div>

                {(!oneContact) && (
                    <div className="scrolledFlex">
                        {list.map((c, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={`${i}:${c.index}:${c.PropertyId}`}>
                                <div className="defaultModal flexModal flexTwinModal">
                                    <InputWrapper label={trans("UserTeamsRoles.Property")}>
                                        <DropdownGroup
                                            dropdownProps={{
                                                value: c.PropertyId,
                                                options: propertyOptions,
                                                onChange: (e, { value }) => modifyContact(i, { ...c, PropertyId: value }),
                                                placeholder: trans("UserTeamsRoles.Select_Property"),
                                                search: true,
                                                selectOnBlur: false
                                            }}
                                        />
                                    </InputWrapper>
                                    <InputWrapper label="Designated Contact">
                                        <DropdownGroup
                                            dropdownProps={{
                                                value: c.UserId,
                                                options: userOptions(c.PropertyId),
                                                onChange: (e, { value }) => modifyContact(i, { ...c, UserId: value }),
                                                placeholder: trans("UserTeamsRoles.Choose_User"),
                                                search: true,
                                                selectOnBlur: false
                                            }}
                                        />
                                    </InputWrapper>
                                    <RoleActionButton
                                        img="remove-circle"
                                        color="red"
                                        onClick={() => removeContact(i)}
                                    />
                                </div>

                            </React.Fragment>
                        ))}

                        {hasUnusedProperty && <EditButton add content={trans("UserTeamsRoles.Add_More_Contacts")} onClick={addContact}/>}
                    </div>
                )}
            </div>
        </ModalLayout>
    );
};

export { RoleContactModal };
