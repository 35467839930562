import React, { useContext, useState } from "react";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { useMutation } from "react-query";
import { MUTATIONS } from "../../../../../_api/queries";
import { LoadingScreen } from "mapsted-components";
import { NotificationContext } from "../../../../notifications";
import { Trans, useTranslation } from "react-i18next";


export const DeleteTeamModal = ({ teamName, teamId, refetchTeams }) =>
{
    const trans = useTranslation().t;

    const notifications = useContext(NotificationContext);
    const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
    const { mutateAsync, isLoading, reset } = useMutation(MUTATIONS.utr.TEAM_DELETE(refetchTeams));

    const handleCloseModal = () =>
    {
        reset();
        setDeleteTeamModalOpen(false);
    };

    const handleDeleteTeam = () => mutateAsync(teamId)
        .then(() => handleCloseModal())
        .catch((err) =>
        {
            handleCloseModal();
            notifications.addNotification("danger", "Delete Team Failed", "Try again later");
        });

    return (
        <ModalLayout
            trigger={
                <Button className="buttonIcon" onClick={() => setDeleteTeamModalOpen(true)}>
                    <img src="/img/icon-trash-fill.svg" alt="delete" />
                </Button>
            }
            open={deleteTeamModalOpen}
            onClose={() => setDeleteTeamModalOpen(false)}
            heading={trans("DeleteTeamModal.Delete_Team")}
            size="mini"
            actions={<Button onClick={() => handleDeleteTeam()} color="orange" floated="right" content={trans("DeleteTeamModal.Delete")} />}
        >
            {isLoading && <LoadingScreen />}
            <p>
                <Trans
                    i18nKey="DeleteTeamModal.Confirmation_Msg"
                    values={{ teamName }}
                />
            </p>
        </ModalLayout>
    );
};
