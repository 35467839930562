import React from "react";
import { Modal, Button } from "semantic-ui-react";
import "./ModalLayout.css";

export const ModalLayout = ({
    className,
    regularModal,
    success,
    scrolling,
    heading,
    customHeading,
    actions,
    children,
    style,
    hideClose = false,
    ...rest
}) => (
    <Modal
        centered
        closeIcon={!hideClose && <Button className="closeBtn" />}
        className={`modalLayout${success ? " successModal" : ""}${
      regularModal ? " regularModal" : ""
    }${className ? " " + className : ""}`}
        {...rest}
        style={style}
        closeOnDimmerClick={false}
        closeOnEscape={false}
    >
        {heading ? (
            <Modal.Header>{heading}</Modal.Header>
        ) : (
            <div className="aligner">
                <Modal.Header>{customHeading}</Modal.Header>
            </div>
        )}
        <Modal.Content scrolling={scrolling}>{children}</Modal.Content>
        <div className="modal-bottom">

            {actions && <Modal.Actions>{actions}</Modal.Actions>}
        </div>
    </Modal>
);