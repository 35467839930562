import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import serverApi from "./index";

// Contains all react-query queries.

export const QUERIES = {
    // Queries for users, teams and roles page
    utr: {
        USERS: (companyUID) => ({
            queryKey: `users-${companyUID}`,
            queryFn: () => serverApi.getQuery("/api/v5/company/users", true)
        }),
        TEAMS: (companyUID) => ({
            queryKey: `teams-${companyUID}`,
            queryFn: () => serverApi.getQuery("/api/v5/company/teams", true)
        }),
        ROLES: (companyUID) => ({
            queryKey: `roles-${companyUID}`,
            queryFn: () => serverApi.getQuery("/api/v3/company/contacts", true)
        }),
        IMAGE_FROM_DB: (id) => ({
            queryKey: `image-${id}`,
            queryFn: () => serverApi.get(`/api/v3/image/${id}`)
        })
    },
    places: {
        GET_COUNTRIES: () => ({
            queryKey: "places",
            queryFn: () => serverApi.getCountries(),
            staleTime: 10 * 60 * 1000 // fresh for 10 minutes
        })
    },
    accounts: {
        HOLD_STATUS: () => ({
            queryKey: "hold-status",
            queryFn: () => serverApi.holdStatus(),
            staleTime: 10 * 60 * 1000 // fresh for 10 minutes
        })
    },
    GET_PRODUCT_URLS: () => ({
        queryKey: "GET_PRODUCT_URLS",
        queryFn: () => serverApi.get("/api/v5/product-urls", false),
        refetchOnWindowFocus: false,
        initialData: {}
    }),
    GET_MOBILE_APPS: () => ({
        queryKey: "GET_MOBILE_APPS",
        queryFn: () => serverApi.get("/api/v3/company/sdk", true),
        refetchOnWindowFocus: false
    }),
    GET_SESSION_CONFIG: () => ({
        queryKey: "GET_SESSION_CONFIG",
        queryFn: () => serverApi.get("/api/v5/security/session-configuration", true),
    }),
    GET_DOMAINS: () => ({
        queryKey: "GET_DOMAINS",
        queryFn: () => serverApi.get( "/api/v5/security/domains", true),
        select: (data) => isEmpty(data) ? [] : data // prevent {} from returning
    }),
    GET_SAML_CONFIG: () => ({
        queryKey: "GET_SAML_CONFIG",
        queryFn: () => serverApi.get("/api/v5/security/saml-config", true),
    }),
    GET_CERTIFICATE: () => ({
        queryKey: "GET_CERTIFICATE",
        queryFn: () => serverApi.get( "/api/v5/security/certificate", true),
        select: ({ data }) => data
    }),
    GET_RESTRICTIONS: () => ({
        queryKey: "GET_RESTRICTIONS",
        queryFn: () => serverApi.get( "/api/v2/properties/restrictions", true),
        select: (data) => data.sort((a, b) => a.propertyId - b.propertyId)
    }),
    GET_APPLICATION_API_KEYS: () => ({
        queryKey: "GET_APPLICATION_API_KEYS",
        queryFn: () => serverApi.get( "/api/v5/applicationApi", true),
        select: (data) => data.data
    }),
};

/**
 * List of mutations. Deprecrated in favour of useMutationsList
 * so you can use the queryClient directly instead of passing it in
 */
export const MUTATIONS = {
    utr: {
        USER_EDIT: (refetch) => ({
            /** @type {({firstName: string, lastName: string, selectedProducts: [], selectedProperties: [], userId: string}) => Promise<any>} */
            mutationFn: (user) => serverApi.patch("/api/v5/user/user", { patch: user }, true),
            onSuccess: () => refetch()
        }),
        USER_INVITE: (refetch) => ({
            /** @type {({user: {id?: string, firstName: string, lastName: string, email: string, phoneNumber?:string, title?:string, company: string, createdBy: string, token: string, createUsers: boolean}, team?: number}) => Promise<any>} */
            mutationFn: (user) => serverApi.post("/api/v5/user/register-invite-user", user, true),
            onSuccess: () => refetch()
        }),
        USER_SUSPEND: (refetch) => ({
            mutationFn: (userId) => serverApi.suspendUser(userId),
            onSuccess: () => refetch()
        }),
        USER_UNSUSPEND: (refetch) => ({
            mutationFn: (userId) => serverApi.unsuspendUser(userId),
            onSuccess: () => refetch()
        }),
        USER_DELETE: (refetch) => ({
            mutationFn: (userId) => serverApi.deleteUser(userId),
            onSuccess: () => refetch()
        }),
        USER_SET_AS_ADMIN: (refetch) => ({
            mutationFn: (userId) => serverApi.assignCompanyAdmin(userId, serverApi.userData.user.userCompanyInfo.id),
            onSuccess: () => refetch()
        }),
        TEAM_EDIT: (refetch) => ({
            mutationFn: (team) => serverApi.patch("/api/v5/team/team", { patch: team }, true),
            onSuccess: () => refetch()
        }),
        TEAM_CREATE: (refetch) => ({
            mutationFn: (team) => serverApi.post("/api/v5/team/team", { newTeam: team }, true),
            onSuccess: () => refetch()
        }),
        TEAM_DELETE: (refetch) => ({
            mutationFn: (teamId) => serverApi.delete(`/api/v5/team/team?id=${teamId}`, true),
            onSuccess: () => refetch()
        }),
    },
    CHANGE_PASSWORD: {
        /**
         * @param pass {{ email: string, currentPassword: string, newPassword: string }}
         * @return {Promise<*>}
         */
        mutationFn: (pass) => serverApi.updatePassword(pass.email, pass.currentPassword, pass.newPassword)
    }
};

export function useMutationsList()
{
    const queryClient = useQueryClient();
    return {
        ADD_MOBILE_APP: () => ({
            mutationFn: ({ appName, deviceType }) => serverApi.post("/api/v3/company/sdk", { appName, deviceType }, true),
            onSuccess: (data) => queryClient.setQueryData("GET_MOBILE_APPS", data)
        }),
        UPDATE_APPLICATION_NAME: () => ({
            mutationFn: ({ previousAppName, newAppName }) => serverApi.post("/api/v3/company/sdk/name", { previousAppName, newAppName }, true),
            onSuccess: () => queryClient.invalidateQueries("GET_MOBILE_APPS")
        }),
        DOWNLOAD_SDK_FILE: () => ({
            mutationFn: ({ deviceType, appName }) => serverApi.getMobileSDK({ deviceType, appName })
        }),
        UPDATE_SESSION_CONFIG: (callback) => ({
            mutationFn: (data) => serverApi.post("/api/v5/security/session-configuration", data, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_SESSION_CONFIG");
                if (callback) callback();
            }
        }),
        ADD_DOMAIN: (callback) => ({
            mutationFn: (data) => serverApi.post( "/api/v5/security/domain", data, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_DOMAINS");
                if (callback) callback();
            }
        }),
        VERIFY_DOMAIN: (callback) => ({
            mutationFn: (domain) => serverApi.patch( `/api/v5/security/domain/verify/${domain}`, undefined, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_DOMAINS");
                if (callback) callback();
            }
        }),
        DELETE_DOMAIN: (callback) => ({
            mutationFn: (domain) => serverApi.delete( `/api/v5/security/domain/${domain}`, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_DOMAINS");
                if (callback) callback();
            }
        }),
        SAVE_SAML_CONFIG: (callback) => ({
            mutationFn: (data) => serverApi.post( "/api/v5/security/saml-config", data, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_SAML_CONFIG");
                if (callback) callback();
            }
        }),
        TOGGLE_SSO: (callback) => ({
            mutationFn: (isEnabled) => serverApi.patch( "/api/v5/security/toggleSSO", { isEnabled }, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_SAML_CONFIG");
                if (callback) callback();
            }
        }),

        CREATE_UPDATE_RESTRICTION: (callback) => ({
            mutationFn: (data) => serverApi.patch( "/api/v2/properties/restriction", data, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_RESTRICTIONS");
                if (callback) callback();
            }
        }),

        DELETE_RESTRICTION: (callback) => ({
            mutationFn: (propertyId) => serverApi.delete(`/api/v2/properties/restriction/${propertyId}`, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_RESTRICTIONS");
                if (callback) callback();
            }
        }),
        USER_DELETE: (companyUID, callback) => ({
            mutationFn: (userIds) => serverApi.deleteUser(userIds),
            onSuccess: () =>
            {
                queryClient.invalidateQueries(`users-${companyUID}`);
                queryClient.invalidateQueries(`teams-${companyUID}`);
                if (callback) callback();
            }
        }),
        DELETE_API_KEY: (keyId, callback) => ({
            mutationFn: () => serverApi.delete(`/api/v5/applicationApi/${keyId}`, true),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_APPLICATION_API_KEYS");
                if (callback) callback();
            }
        }),
        CREATE_API_KEY: (apiKeyDetails, callback) => ({
            mutationFn: () => serverApi.post("/api/v5/applicationApi/add", apiKeyDetails),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_APPLICATION_API_KEYS");
                if (callback) callback();
            }
        }),
        UPDATE_API_KEY: (keyId, apiKeyDetails, callback) => ({
            mutationFn: () => serverApi.post(`/api/v5/applicationApi/${keyId}`, apiKeyDetails),
            onSuccess: () =>
            {
                queryClient.invalidateQueries("GET_APPLICATION_API_KEYS");
                if (callback) callback();
            }
        })
    };
}
