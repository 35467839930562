import React, { useContext, useState } from "react";
import { Button, Tab, Popup } from "semantic-ui-react";
import { AuthContext } from "../../../../../../_store/AuthProvider";
import { Trans, useTranslation } from "react-i18next";

export const ManagedDomainsDNSTab = () =>
{
    const [copyPopupOpen, setCopyPopupOpen] = useState(false);

    const { state: { userInfo } } = useContext(AuthContext);
    const verificationCode = `mapsted-domain-verification=${userInfo?.company?.companyUID}`;

    const handleCopy = () =>
    {
        navigator.clipboard.writeText(verificationCode).then(() =>
        {
            setCopyPopupOpen(true);
            setTimeout(() => setCopyPopupOpen(false), 1500);
        });

    };

    const trans = useTranslation().t;

    return (
        <Tab.Pane>
            <p className="para">{trans("SamlSingleSignIn.Add_The_Txt_Record")}</p>
            <div className="dnsCodeWrap">
                <div className="dnsCode">
                    <div className="urlcodebox">{verificationCode}</div>

                    <Popup
                        trigger={<Button color="orange" basic content={trans("SamlSingleSignIn.Copy")} onClick={handleCopy} />}
                        content={trans("SamlSingleSignIn.Copied")}
                        position="top center"
                        open={copyPopupOpen}
                    />
                </div>
                <p className="paraSmall">{trans("SamlSingleSignIn.Same_Record_Can_Be_Used_Multiple_Domains")}</p>
            </div>

            <p className="para">
                <Trans i18nKey="SamlSingleSignIn.DNS_Changes_Desc" />
            </p>
            <p className="para">{trans("SamlSingleSignIn.After_Adding_Txt_Record")} </p>
            <p className="para">{trans("SamlSingleSignIn.If_You_Have_Difficulties")} </p>
        </Tab.Pane>
    );
};
