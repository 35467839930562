import React from "react";
import PropTypes from "prop-types";
import { Button, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Avatar, UploadImageModal } from "mapsted-components";


const acceptableImageFileFormats = "image/x-png,image/png,image/jpeg,image/svg+xml,image/svg,image/gif";

export const AvatarEditor = ({ imageSrc, placeholder, handleSave, heading, description, loading }) =>
{
    const [isOpen, setIsOpen] = React.useState(false);
    const trans = useTranslation().t;

    const renderTrigger = React.useCallback(() => 
    {
        let trigger;
        const triggerClass = isOpen ? "uploadModalOpen" : "uploadEditButton";
        if (loading)
        {
            trigger =  <Loader active inline size="tiny" />;
        }
        else
        {

            if (imageSrc)
            {
                trigger = <Button className={triggerClass} onClick={() => handleSave()}><img src="/img/icon-trash-fill.svg" alt="" /></Button>;
            }
            else
            {
                trigger = <UploadImageModal
                    src={imageSrc}
                    trigger={<Button className={triggerClass} content={trans("AvatarEditor.Upload_Image")} />}
                    heading={heading}
                    description={description}
                    aspectRatio={1}
                    acceptableImageFileFormats={acceptableImageFileFormats}
                    onSave={handleSave}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    defaultFilename="Image"
                    proxyUrl="/api/v3/image/url?link="
                    responseHeadersRetrievalUrl="/api/v3/image/responseHeaders?link="
                    text={{
                        next: "Upload"
                    }}
                />;
            }
            
        }
        return trigger;

    },[description, handleSave, heading, imageSrc, isOpen, loading, trans]);

    return (
        <>
            <Avatar imageSrc={imageSrc} placeholder={placeholder}  />
            {renderTrigger()}
        </>
    );
};
AvatarEditor.propTypes = {
    /** if falsey placeholder is used  */
    imageSrc: PropTypes.string,
    placeholder: PropTypes.string,
    handleSave: PropTypes.func,
    heading: PropTypes.string,
    description: PropTypes.string.isRequired

};
AvatarEditor.defaultProps = {
    imageSrc: "",
    placeholder: "/img/display-placeholder.svg",
    handleSave: () => null,
    heading: "Upload an Image"
};

export default AvatarEditor;
