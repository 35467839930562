import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment, Image } from "semantic-ui-react";
import { Button, LocalIcon } from "mapsted-components";
import "./DashboardProduct.css";

const ProductRow = ({ className, heading, subheading, children, color }) => (
    <div className={`mapstedProductRow${className ? " "+className:""}`}>
        <div className={`productHeading ${color}`}>
            <Header as="h2" content={heading} subheader={subheading} />
        </div>
        <div className="mapstedRowContent">
            {children}
        </div>
    </div>
);

const ProductColumn = ({ heading, productImg, description, isActive, onClick }) =>
{
    const trans = useTranslation().t;
    return (
        <div className="mapstedProductColumn" onClick={onClick}>
            <Header content={heading} />
            <Segment className="mapstedProductBox">

                <Image as="span" src={`/img/dashboard/product-${productImg}.png`} alt={heading} />

                <p>{description}</p>
                {(isActive) && (
                    <span className="labelStatus">
                        <LocalIcon name="success-filled" marginRight={5} />
                        {trans("DashboardProduct.Activated")}
                    </span>
                )}
                {(!isActive) && (
                    <Button basic={false} icon={<LocalIcon name="go-filled" marginRight={5} />} content={trans("DashboardProduct.Contact_Sales")} href="#" />
                )}
            </Segment>
        </div>
    );
};

export { ProductRow, ProductColumn };

export const MarketingColumn = ({ heading, slider, description, url, onClick }) =>
{
    const trans = useTranslation().t;
    return (
        <div className="mapstedProductColumn" onClick={onClick}>
            <Header content={heading} />
            <Segment className="mapstedProductBox">
                {slider}
                <p>{description}</p>
                <Button
                    className="downloadIcon"
                    icon={<LocalIcon name="download" marginRight={5} />}
                    content={trans("DashboardProduct.Download")}
                    href={url}
                />
            </Segment>
        </div>
    );
};
