/* eslint-disable react/display-name */
import React from "react";
import { Menu } from "semantic-ui-react";
import { SettingsLayout } from "../SettingsLayout";
import { SectionSettings } from "../SectionSettings";
import { TabUsers } from "./TabUsers";
import { TabTeams } from "./TabTeams";
import { TabRoles } from "./TabRoles";
import "./UsersTeamsRoles.css";
import { NavLink, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BASE_PATH = "/settings/org";
const PATHS = {
    USERS: "users",
    TEAMS: "teams",
    ROLES: "roles"
};

export const UsersTeamsRoles = () =>
{
    const location = useLocation();
    const generateMenuLink = (path, text) => ({
        as: NavLink,
        id: path,
        to: `${BASE_PATH}/${path}`,
        key: path,
        content: text,
        active: location.pathname === `${BASE_PATH}/${path}`
    });

    const items = [
        generateMenuLink(PATHS.USERS, "Users"),
        generateMenuLink(PATHS.TEAMS, "Teams"),
        generateMenuLink(PATHS.ROLES, "Roles"),
    ];

    const trans = useTranslation().t;

    return (
        <SettingsLayout className="userLayout">
            
            <div className="settingsWrapper userWrapper">
                <SectionSettings icon="users-teams" heading={trans("UserTeamsRoles.Users_Teams_Roles")} />
                <div className="boxTabs userTab">
                    <Menu text secondary items={items} />
                    <Switch>
                        <Route path={`${BASE_PATH}/${PATHS.USERS}`}><TabUsers /></Route>
                        <Route path={`${BASE_PATH}/${PATHS.TEAMS}`}><TabTeams /></Route>
                        <Route path={`${BASE_PATH}/${PATHS.ROLES}`}><TabRoles /></Route>
                        <Route><Redirect to={`${BASE_PATH}/${PATHS.USERS}`} /></Route>
                    </Switch>
                </div>
            </div>
        </SettingsLayout>
    );
};
