import React from "react";
import LoginSection from "./LoginSection";
import { Trans, useTranslation } from "react-i18next";

const RecoveryEmailSent = ({ username }) => 
{
    const trans = useTranslation().t();

    return (
        <LoginSection className="recoveryCover" heading={<Trans 
            i18nKey="Login.An_Email_Password_Reset_Instructions"
            values={{ username }}
        />}>
            <span className="loginDetail">
                {trans("Login.Email_Junk_Instruction")}<br/>
                <Trans i18nKey="Login.Email_Instruction" />
            </span>
        </LoginSection>
    );
    
};
export default RecoveryEmailSent;
