import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import serverApi from "../_api";


import { AuthProvider } from "../_store/AuthProvider";
import { NotificationProvider } from "./notifications";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const Providers = ({ children }) =>
{
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() =>
    {
        serverApi.billing.getStripeToken().then((token) => setStripePromise(loadStripe(token)));
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <NotificationProvider>
                <AuthProvider>
                    <Elements stripe={stripePromise}>
                        <Router>
                            {children}
                        </Router>
                    </Elements>
                </AuthProvider>
            </NotificationProvider>
        </QueryClientProvider>
    );
};

export default Providers;
