import React from "react";
import { AuthContext } from "../../../_store/AuthProvider";
import { AccountAccessColumn } from "../Dashboard";
import AccountAccessListItem from "./AccountAccessListItem";
import Entities from "./Entities";
import { useTranslation } from "react-i18next";

const Properties = () => 
{
    const { state: authState } = React.useContext(AuthContext);
    const properties = React.useMemo(() => Object.values(authState?.properties || {})
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((property, index) => ({
            key: `${property?.propertyId}:${index}`,
            content: property?.name,
            detail: `${property?.city?.cityName || ""}, ${property?.stateProvince?.longName || ""}`
        })),[authState?.properties]);
    
    const trans = useTranslation().t;

    return properties.length > 0 ? (
        <Entities heading={trans("Properties.Properties")} subHeading={trans("Properties.You_Can_Access_The_Following_Properties")} entities={properties} searchPlaceholder={trans("Properties.Search_Property")}  />
    ) : (
        <AccountAccessColumn heading={trans("Properties.Properties")} subheading={trans("Properties.You_Can_Access_The_Following_Properties")} >
            <AccountAccessListItem content={trans("Properties.No_Properties_Assigned_To_You")} detail={trans("Properties.Contact_Your_Company_Admin")}  />
        </AccountAccessColumn>
    );
};

export default Properties;
