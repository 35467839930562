/**
 * Based on:
 * https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";

const RouteLeavingGuard = ({
    when,
    // function that takes in the new location, blocks if returns true
    shouldBlockNavigation = () => true,
}) =>
{
    const history = useHistory();

    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const trans = useTranslation().t;

    const closeModal = () =>
    {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation) =>
    {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation))
        {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () =>
    {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() =>
    {
        if (!when)
        {
            window.onbeforeunload = (e) => undefined;
        }
        else
        {
            window.onbeforeunload = (e) => "";
        }
        return () => window.onbeforeunload = undefined;
    }, [when]);

    useEffect(() =>
    {
        if (confirmedNavigation && lastLocation)
        {
            // Navigate to the previous blocked location with your navigate function
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, history]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal
                className="modalLayout routeLeavingModal" 
                size="small" open={modalVisible}>
                <p className="pModal">{trans("AccountSettings.You_Have_Unsaved_Changes")}</p>
                <div className="actions">
                    <Button color="grey" content={trans("AccountSettings.Cancel")} onClick={closeModal}/>
                    <Button color="orange" content={trans("AccountSettings.Continue")} onClick={handleConfirmNavigationClick} />
                </div>
                
            </Modal>
        </>
    );
};
export default RouteLeavingGuard;
