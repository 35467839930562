import React, { useContext, useMemo } from "react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { Button } from "semantic-ui-react";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { LoadingScreen } from "mapsted-components";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { useMutation, useQuery } from "react-query";
import { QUERIES, useMutationsList } from "../../../../../_api/queries";
import { ROLE_CATEGORIES } from "../../../../../_utils/constants";
import { Trans, useTranslation } from "react-i18next";

export const BulkDeleteModal = ({ open, onClose, users = [], onToggleSelect = () => null, onDeleteSuccess = () => null }) =>
{
    const trans = useTranslation().t;

    const { state: authCtxState } = useContext(AuthContext);
    const companyUID = authCtxState.userInfo.company.companyUID;

    const { data: contacts = [], isLoading: contactsLoading } = useQuery(QUERIES.utr.ROLES(companyUID));
    const { mutateAsync: deleteUsers, isLoading: deleteUsersLoading } = useMutation(
        useMutationsList().USER_DELETE(companyUID, () =>
        {
            onDeleteSuccess();
            onClose();
        })
    );

    const contactUsers = useMemo(() =>
    {
        if (contactsLoading) return [];

        // Only the users under 'Roles' i.e. if user is a contact are included
        const filteredUsers = [];
        for (const u of users)
        {
            // Get all the roles 'u' has
            const userRoles = contacts.filter((c) => c.UserId === u.id).map((c) => c.ContactType);
            if (!userRoles.length) continue;
            // Find the title of each of these roles
            const roleTitles = ROLE_CATEGORIES.filter((rc) => userRoles.includes(rc.id)).map((rc) => trans(rc.title));

            // Convert 'roleTitles' to string of 2 roles followed by count if more than 2 roles.
            // Ex - "John Wick (Primary Contact, Billing Contact, +2)"
            const roleNames = roleTitles.slice(0, 2).join(", ") + (roleTitles.length > 2 ? `, +${roleTitles.length - 2}` : "");

            filteredUsers.push({ ...u, roles: roleNames });
        }
        return filteredUsers;
    }, [users, contacts]);

    const handleDelete = () =>
    {
        deleteUsers(users.map((u) => u.id));
    };

    return (
        <>
            {deleteUsersLoading && <LoadingScreen />}

            <ModalLayout
                className="bulkDeleteModal"
                open={open}
                heading={users.length > 1 ? trans("BulkDeleteModal.Bulk_Delete") : trans("BulkDeleteModal.Delete_User")}
                actions={<Button color="orange" type="button" floated="right" content={trans("BulkDeleteModal.Delete")} onClick={handleDelete} />}
                onClose={onClose}
            >
                {users.length > 1 && <b className="bulkDeleteHeading">
                    <Trans 
                        i18nKey="BulkDeleteModal.Bulk_Delete_Users"
                        values={{ users: users.length }}
                    />
                </b>}
                <p className="pModal">
                    <Trans 
                        i18nKey="BulkDeleteModal.Delete_Users_Confirmation"
                        values={{ users: users.length }}
                    />
                   
                    <br /><br />
                    {contactUsers.length > 0 && trans("BulkDeleteModal.Confirm_List_Of_Users")}
                </p>
                <div className="limitScrollHeight">
                    {contactUsers.map((user) => (
                        <UserItem
                            key={user.id}
                            user={user}
                            onToggleSelect={onToggleSelect}
                            disableDelete={users.length === 1}
                        />
                    ))}
                </div>
                

            </ModalLayout>
        </>
    );
};

const UserItem = ({ user, onToggleSelect, disableDelete }) => (
    <div className="bulkDeleteModalItem">
        <div className="bulkDeleteItemContent">
            <strong>{`${user.firstName} ${user.lastName} (${user.roles})`}</strong>
        </div>

        <ButtonIcon
            className="crossInvite"
            icon="cross"
            onClick={() => onToggleSelect(user)}
            disabled={disableDelete}
        />
    </div>
);
