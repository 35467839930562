import React, { useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Scrollbars } from "react-custom-scrollbars";
import { Header, Button, Icon, Popup } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import { LoadingScreen } from "mapsted-components";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { ContentGroup } from "../../../../common/ContentGroup";
import { TableBoxColumn } from "../../../../common/TableBoxColumn";
import CreateUpdateRestrictionModal from "./CreateUpdateRestrictionModal";
import { QUERIES, useMutationsList } from "../../../../../_api/queries";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { isEmpty } from "lodash";
import { ModalLayout } from "../../../../common/ModalLayout";

const MODAL_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    DELETE: "DELETE",
    COPY: "COPY"
};
const ManageRestrictions = () =>
{
    const trans = useTranslation().t("ManageRestrictions");
    const { data: allRestrictions = [], isLoading: restrictionsLoading } = useQuery(QUERIES.GET_RESTRICTIONS());
    const [modelInfo, setModelInfo] = useState({ type: "" });
    const [selectedPropertyId, setSelectedPropertyId] = useState(-1);
    const { state: { properties } } = useContext(AuthContext);

    const allowAddProperty = useMemo(
        () => allRestrictions.length < Object.keys(properties).length,
        [allRestrictions, properties]
    );

    const { mutate: deleteRestriction , isLoading: deleteRestrictionLoading } = useMutation(
        useMutationsList().DELETE_RESTRICTION(closeModal)
    );

    function handleAction(propertyId, type)
    {
        setSelectedPropertyId(propertyId);
        setModelInfo({ type });
    }

    function closeModal()
    {
        setSelectedPropertyId(-1);
        setModelInfo({});
    }

    return (
        <>
            <LoadingScreen active={deleteRestrictionLoading} />
            <ContentGroup heading={trans["Integrate_Mapsted_Maps_on_the_Web"]}>
                <p className="paragraph">
                    <Trans i18nKey="ManageRestrictions.Limit_web_access_to_your_indoor_maps" components={{
                        //eslint-disable-next-line jsx-a11y/anchor-has-content -- has content when rendered
                        mail: <a href="mailto:support@mapsted.com" target="_blank" rel="noreferrer" />
                    }} />

                </p>

                <div className="appRow appRowRestricedAccess">
                    <div className="appRowBody">
                        <Header as="h6" className="headingRestrictedAccess">
                            {trans["Property_Restrictions"]}
                            {allowAddProperty && (
                                <Button
                                    disabled={restrictionsLoading}
                                    className="buttonIcon"
                                    onClick={() => setModelInfo({ type: MODAL_TYPE.CREATE })}
                                >
                                    <img src="/img/icon-plus-filled.svg" />{trans["Add_property"]}
                                </Button>
                            )}
                            <CreateUpdateRestrictionModal
                                open={[MODAL_TYPE.CREATE, MODAL_TYPE.EDIT, MODAL_TYPE.COPY].includes(modelInfo.type)}
                                onClose={closeModal}
                                allRestrictions={allRestrictions}
                                propertyId={selectedPropertyId}
                                isCopy={modelInfo.type === MODAL_TYPE.COPY}
                            />
                        </Header>

                        <div className="tableBox tableBoxRestrictedAccess">
                            <div className="tableBoxRow">
                                <TableBoxColumn detail={trans["PROPERTY_NAME"]} />
                                <TableBoxColumn detail={trans["DATE_CREATED"]} />
                                <TableBoxColumn detail={trans["RESTRICTION"]} />
                                <TableBoxColumn detail="" />
                            </div>
                            <Scrollbars className="settingsScrollLayout" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"250px"}>
                                <div className="alignerTable">
                                    {restrictionsLoading && <div className="emptytablebox">{trans["Loading"]}</div>}
                                    {!restrictionsLoading && isEmpty(allRestrictions) && <div className="emptytablebox">{trans["No_property"]}</div>}

                                    {allRestrictions.map(({ propertyId, restriction, createdAt }) => (
                                        <RestrictionRow
                                            key={propertyId}
                                            propertyId={propertyId}
                                            restriction={restriction}
                                            createdAt={createdAt}
                                            onEdit={() => handleAction(propertyId, MODAL_TYPE.EDIT)}
                                            onCopy={() => handleAction(propertyId, MODAL_TYPE.COPY)}
                                            onDelete={() => handleAction(propertyId, MODAL_TYPE.DELETE)}
                                        />
                                    ))}

                                    <DeleteRestrictionModal
                                        open={modelInfo.type === MODAL_TYPE.DELETE}
                                        property={properties[selectedPropertyId]}
                                        onClose={closeModal}
                                        onDelete={() => deleteRestriction(selectedPropertyId)}
                                    />
                                </div>
                            </Scrollbars>

                        </div>

                    </div>
                    <div className="appRowColumn">
                        <div className="videoWrap">
                            <div className="videoThumb"></div>
                            <p>{trans["Video_How_to_Create"]}</p>
                        </div>
                    </div>
                </div>
            </ContentGroup>
        </>
    );
};

const RestrictionRow = ({ propertyId, createdAt, restriction, onEdit, onCopy, onDelete }) =>
{
    const trans = useTranslation().t("ManageRestrictions");
    const { state: { properties } } = useContext(AuthContext);

    const { keys, referers, ips, dates } = restriction;

    return (<div
        key={propertyId}
        className="tableBoxRow">
        <TableBoxColumn detail={`${propertyId} - ${properties[propertyId]?.name}`} />
        <TableBoxColumn detail={moment(createdAt).format("DD-MMM-YYYY")} />
        <TableBoxColumn>
            {!isEmpty(keys) && <RestrictionColumnItem icon="key" content={trans["Access_Key"]} />}
            {!isEmpty(referers) && <RestrictionColumnItem icon="globe" content={trans["HTTP_referrers_web_sites"]} />}
            {!isEmpty(ips) && <RestrictionColumnItem icon="server" content={trans["IP_addresses_web_servers"]} />}
            {!isEmpty(dates) && <RestrictionColumnItem icon="calendar alternate" content={trans["Date_Ranges"]} />}
        </TableBoxColumn>
        <TableBoxColumn>
            <div className="tableboxActions">
                <ButtonIcon className="copyKeyAccess" icon="copy" onClick={onCopy} />
                <ButtonIcon icon="edit" onClick={onEdit} />
                <ButtonIcon icon="trash2" onClick={onDelete} />
            </div>
        </TableBoxColumn>
    </div>);
};

const RestrictionColumnItem = ({ icon, content }) => (
    <Popup className="restrictionColumnPopup"
        size="small"
        position="top center"
        on="hover"
        trigger={<Icon name={icon} color="grey" />}
        content={content} />
);

const DeleteRestrictionModal = ({ open, property = {}, onClose, onDelete }) =>
{
    const trans = useTranslation().t("ManageRestrictions.DeleteRestrictionModal");
    const { name, propertyId } = property;

    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            heading={trans["Delete_Restriction"]}
            size="mini"
            actions={<Button onClick={onDelete} color="orange" floated="right" content={trans["Delete"]} />}
        >
            <p className="pModal">{`${trans["Are_you_sure_you"]} ${propertyId} - ${name} ?`}</p>
        </ModalLayout>
    );
};


export default ManageRestrictions;
