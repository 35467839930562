import { isEmpty } from "lodash";

export { default as get } from "lodash/get";
export { default as isEmpty } from "lodash/isEmpty";
export { default as isEqual } from "lodash/isEqual";
export { default as find } from "lodash/find";
export { default as debounce } from "lodash/debounce";

export const getDateFormats = () =>
{
    const options = {
        weekday: ["short", "long", undefined],
        year: ["numeric", "2-digit"],
        month: ["2-digit", "short", "long"],
        day: ["2-digit"],
    };
    const formats = [];
    const date = new Date();
    options.weekday.forEach((weekday) =>
    {
        options.year.forEach((year) => [
            options.month.forEach((month) =>
            {
                options.day.forEach((day) =>
                {
                    let options = { weekday, year, month, day };
                    let example = new Intl.DateTimeFormat(navigator.languages[0] || navigator.language, options).format(date);
                    formats.push({ options, example });
                });
            })
        ]);
    });
    return formats;
};

/**
 *
 * @param {Date|string} date
 * @param {string} tzString
 * @returns {Date}
 */
export function convertTZ(date, tzString = Intl.DateTimeFormat().resolvedOptions().timeZone)
{
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString(navigator.languages[0] || navigator.language, { timeZone: tzString }));
}

/**
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatDate(date)
{
    return new Intl.DateTimeFormat(navigator.languages[0] || navigator.language, {
        year: "numeric",
        month: "long",
        day: "2-digit"
    }).format(date);
}

/**
 *
 * @param {string} ip
 * @returns {boolean}
 */
export function isValidIpv4(ip)
{
    if (isEmpty(ip)) return false;

    ip = ip.split(".");

    if (ip.length !== 4) return false;

    if (ip.some((num) => num === "" || (num.length > 1 && num[0] === "0"))) return false;

    ip = ip.map(Number);

    return ip.every((num) => Number.isInteger(num) && 0 <= num && num <= 255);
}

/**
 * @param {string} password
 * @returns {boolean}
 */
export function validatePassword(password)
{
    // Length at least 8 characters
    const isValidLength = password.length >= 8;

    // Upper-case
    const isUpperCase = password.search(/[A-Z]/) >= 0;

    // Lower-case
    const isLowerCase = password.search(/[a-z]/) >= 0;

    // Digit
    const isDigit = password.search(/[0-9]/) >= 0;

    // Special
    const isSpecial = password.search(/[!@#$%^&*(),.?":{}|<>]/) >= 0;

    return { isValidLength, isUpperCase, isLowerCase, isDigit, isSpecial };
}
