import React from "react";
import { Checkbox } from "semantic-ui-react";

import "./CheckboxGroup.css";

const CheckboxGroup = ({ className, img, title, detail, gap, secondary, checkboxProps }) => (
    <div className={`${secondary ? " defaultWrap":"checkboxGroup"}${className ? " "+className:""}${gap ? " gap"+gap:""}`}>
        <Checkbox {...checkboxProps} className={`${secondary ? "secondary" :""}`}/>
        {img}
        {title && <p>{title}
            {detail && <span>{detail}</span>}
        </p>
        }
    </div>
);

export { CheckboxGroup };
