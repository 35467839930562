import React from "react";
import { Checkbox } from "mapsted-components";
import { ModalLayout } from "../../../../common/ModalLayout";
import CloseAccountContext from "./CloseAccountContext";
import ActionButtons from "./ActionButtons";
import { CLOSE_ACCOUNT_TYPES } from "./closeAccountConfig";
import { convertTZ, formatDate } from "../../../../../_utils/misc";
import { useTranslation } from "react-i18next";

/**
 * 
 * @type {React.FC<import("semantic-ui-react").ModalProps>}  
 */
export const CloseAccountTypeModal = (props) => 
{

    const { state, handlers, holdStatus } = React.useContext(CloseAccountContext);

    const handleChange = (value) => 
    {
        handlers.setState((prev) => 
        {
            if (prev.closeAccountType === value) return prev;
            return ({ closeAccountType: value });
        });
    };
    
    const trans = useTranslation().t;

    return (
        <ModalLayout 
            {...props} 
            heading={trans("CloseAccount.Close_Account")}
            actions={<ActionButtons 
                hasLeftButton={false}
                rightButtonProps={{ positive: true, disabled: holdStatus.data?.onHold && (state.closeAccountType === CLOSE_ACCOUNT_TYPES.HOLD) }}
            />} >

            <p className="paraHeading">{trans("CloseAccount.Your_About_To_Close")}</p>
            
            {/* DO NOT REMOVE */}
            {/* <CloseAccountType onHold={holdStatus.data.onHold} holdTime={holdStatus.data.date} label="Put My Account On Hold" checked={state.closeAccountType === CLOSE_ACCOUNT_TYPES.HOLD} id={CLOSE_ACCOUNT_TYPES.HOLD} onChange={handleChange}>
                We will keep your account data for 6 months, free of charge.You can activate your account at any time during that window by contacting Mapsted Sales.
            </CloseAccountType> */}


            <CloseAccountType onHold={holdStatus.data?.onHold} disabled={holdStatus.data?.onHold} holdData={holdStatus.data} label={trans("CloseAccount.Put_Your_Account_On_Hold")} checked={state.closeAccountType === CLOSE_ACCOUNT_TYPES.HOLD} id={CLOSE_ACCOUNT_TYPES.HOLD} onChange={handleChange}>
                {trans("CloseAccount.We_Keep_Your_Account_Data")}
            </CloseAccountType>

            <CloseAccountType label={trans("CloseAccount.Close_My_Account")}checked={state.closeAccountType === CLOSE_ACCOUNT_TYPES.CLOSE} id={CLOSE_ACCOUNT_TYPES.CLOSE} onChange={handleChange}>
                {trans("CloseAccount.Closing_Your_Contact")}
            </CloseAccountType>
        </ModalLayout>
    );
};

const CloseAccountType = ({ label, onHold, holdData, children, disabled, checked, id, onChange }) => 
{

    const holdTimeString = React.useMemo(() => holdData && formatDate(convertTZ(holdData.date)),[holdData]);

    const trans = useTranslation().t;
    
    return (
        <div className={`closeAccountType${disabled ? " disabled":""}`} >
            <Checkbox checked={checked} onClick={() => onChange(id)} radio label={label} name={"closeAccount"} />
            {
                onHold && <span className="onHoldText">{trans("CloseAccount.On_Hold_Since")} {holdTimeString}</span>
            }
            <p>{children}</p>
        </div>
    );
};
