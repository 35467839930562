import React, { useState } from "react";
import { Button, Header, Input } from "semantic-ui-react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { TextGroup } from "../../../common/TextGroup";
import { DEVICE_TYPE_NAMES } from "../../constants";
import { QUERIES, useMutationsList } from "../../../../_api/queries";
import { YoutubePlayer } from "./YoutubePlayer";

export const MobileOsIntegration = ({ deviceType, inputLabel, youtubeId, videoCaption }) =>
{
    const trans = useTranslation().t;
    const getAppsQuery = useQuery({ ...QUERIES.GET_MOBILE_APPS(), onSuccess: (data) => setAppName(getFirstAppName(data, deviceType) || "") });

    const [appName, setAppName] = useState("");

    const downloadMutation = useMutation(useMutationsList().DOWNLOAD_SDK_FILE());
    const changeNameMutation = useMutation(useMutationsList().UPDATE_APPLICATION_NAME());

    const handleUpdateApplicationName = () =>
    {
        changeNameMutation.mutateAsync({ previousAppName: getFirstAppName(getAppsQuery.data, deviceType), newAppName: appName })
            .then(() => downloadMutation.mutate({ deviceType, appName }));
    };

    const isAppNameNew = appNameIsNew(appName, getAppsQuery.data);
    return (
        <div className="appRow">
            <div className="appRowBody">
                <Header as="h6" content={`${DEVICE_TYPE_NAMES[deviceType]} App`} />
                <div className="appSubRow">
                    <TextGroup heading={inputLabel}>
                        <Input
                            loading={getAppsQuery.isLoading}
                            value={appName}
                            onChange={(e, { value }) => setAppName(value)}
                            placeholder="com.company.app"
                        />
                    </TextGroup>

                    <TextGroup title={trans("MobileOsIntegration.Download_SDK_Licence")}>
                        {!isAppNameNew && (
                            <ButtonIcon
                                className="buttonDownloadLicence"
                                icon="download2"
                                content={DEVICE_TYPE_NAMES[deviceType]}
                                onClick={() => downloadMutation.mutate({ deviceType, appName })}
                                disabled={!appName.trim()}
                                loading={downloadMutation.isLoading}
                            />
                        )}
                        {isAppNameNew && (
                            <Button
                                basic
                                content={trans("MobileOsIntegration.Generate_File")}
                                onClick={handleUpdateApplicationName}
                                disabled={!appName.trim()}
                                color="orange"
                                loading={changeNameMutation.isLoading}
                            />
                        )}
                    </TextGroup>
                </div>
            </div>
            <div className="appRowColumn">
                <YoutubePlayer
                    videoId={youtubeId}
                    width={300}
                    caption={videoCaption}
                />
            </div>
        </div>
    );
};

function appNameIsNew(appName, appDetails)
{
    if (!appDetails?.sdks?.length)
    {
        return true;
    }
    const alreadyExists = appDetails.sdks.some((sdk) => sdk.appName === appName);
    return !alreadyExists;
}

function getFirstAppName(appDetails, deviceType)
{
    if (appDetails?.hasSDK)
    {
        return appDetails.sdks.find((sdk) => sdk.deviceType === deviceType)?.appName;
    }
    return undefined;
}
