import React from "react";
import { Link } from "react-router-dom";

export const HeaderLogin = () => (
    <div className="headerLogin">
        <div className="container">
            <Link to="/" className="navbar-brand">
                <img src="/img/hub-logo.png" alt="" />
            </Link>
        </div>
    </div>
);