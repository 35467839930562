import React from "react";
import { Header } from "semantic-ui-react";

export const ContentGroup = ({
    flexible = false,
    className = "",
    heading = undefined,
    asHeading,
    children,
    extra = undefined
}) => (
    <div className={`contentGroup${flexible ? " cgFlexible":""} ${className}`}>
        {(heading) && (<Header as={asHeading ? asHeading : "h6"} content={heading} />)}
        <div className="content">
            {children}
        </div>
        {(extra) && (<span className="extraContent">{extra}</span>)}
    </div>
);
