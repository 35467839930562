import React from "react";
import { Button, Popup, Placeholder, Icon, Checkbox } from "semantic-ui-react";
import { TableBoxColumn } from "../../../common/TableBoxColumn";
import { SettingsPopupLayout } from "../SettingsPopupLayout";
import { EditUserModal } from "./editUserModal/EditUserModal";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { useSetState } from "ahooks";
import { BulkDeleteModal } from "./newComponents/BulkDeleteModal";
import { Trans, useTranslation } from "react-i18next";

export const TableTabUsers = ({ users, loadingStatus, selectedUsers, allUsersSelected, onSelectUser, onSelectAllUsers }) =>
{
    const trans = useTranslation().t;

    const [state, setState] = useSetState({
        userToDelete: undefined
    });

    const handleClose = (success) =>
    {
        if (success)
        {
            // Remove deleted user from 'selectedUsers' state in parent
            onSelectUser(state.userToDelete);
        }

        setState({ userToDelete: undefined });
    };

    return (
        <>
            <div className="tableBox tableBoxUser userTable">
                <div className="tableBoxRow">
                    <TableBoxColumn className="userTableFirstCol">
                        {users?.length > 1 && <Checkbox className="secondary deleteCheckbox" checked={allUsersSelected} onChange={onSelectAllUsers} /> }
                        <p>NAME</p>
                    </TableBoxColumn>
                    <TableBoxColumn detail={trans("UserTeamsRoles.TEAM")} />
                    <TableBoxColumn detail={trans("UserTeamsRoles.PROPERTY")} />
                    <TableBoxColumn detail={trans("UserTeamsRoles.PRODUCT")} />

                    {/* Might be added in future */}
                    {/* <TableBoxColumn detail="EMAIL STATUS" /> */}

                    <TableBoxColumn narrow />
                </div>
                {/* Might be added in future */}
                {/* <div className="tableBoxRow">
            <TableBoxColumn><Checkbox className="secondary deleteCheckbox" /> Name</TableBoxColumn>
            <TableBoxColumn></TableBoxColumn>
            <TableBoxColumn></TableBoxColumn>
            <TableBoxColumn></TableBoxColumn>
            <TableBoxColumn>
                <Popup className="popupBox statusProcessingPopup"
                    basic
                    on="click"
                    trigger={<Button className="statusButtonTrigger">
                    Invited
                        <img src="/img/icon-chevron-down.svg" alt="expand" />
                    </Button>}>
                    <Header>Status Progress</Header>
                    <div className="processingStatus">
                        <p className="isCompleted">
                            <span></span>
                            Invited
                        </p>
                        <p className="isProcessing">
                            <span></span>
                            <div className="ui small active indeterminate inline loader"></div>
                            Opened
                        </p>
                        <p>
                            <span></span>
                            Clicked
                        </p>
                        <p>
                            <span></span>
                            Validate (Optional)
                        </p>
                        <p>
                            <span></span>
                            Active
                        </p>

                    </div>
                </Popup>
            </TableBoxColumn>
            <TableBoxColumn narrow></TableBoxColumn>
        </div> */}

                {(loadingStatus === "success") && (users || []).map((user) => (
                    <TableRow
                        key={user.id}
                        user={user}
                        selectedUsers={selectedUsers}
                        onToggleSelect={onSelectUser}
                        onDelete={(userToDelete) => setState({ userToDelete })}
                    />
                ))}
                {(loadingStatus === "success") && users?.length === 0 && <EmptyUserRow />}
                {(loadingStatus === "error") && <ErrorUserRow />}
                {(loadingStatus === "loading") && new Array(5).fill(undefined).map((_, i) => (
                    // eslint-disable-next-line react/no-array-index-key -- Array is hardcoded to be length 5
                    <div key={i} className="tableBoxRow">
                        <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="short" /><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                        <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="short" /><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                        <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="short" /><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                        <TableBoxColumn style={{ alignItems: "inherit" }}><Placeholder><Placeholder.Line length="short" /><Placeholder.Line length="very short" /></Placeholder></TableBoxColumn>
                        <TableBoxColumn narrow><EditUserModal disabled={true} /></TableBoxColumn>
                    </div>
                ))}
            </div>

            <BulkDeleteModal
                open={!!state.userToDelete}
                users={state.userToDelete ? [state.userToDelete]: []}
                onDeleteSuccess={() => handleClose(true)}
                onClose={handleClose}
            />
        </>
    );
};

export const AdminTag = () => <span key="admin" className="adminTag">Admin</span>;
export const SuspendedTag = () => <span key="admin" className="adminTag" style={{ background: "#f26202" }}>Suspended</span>;

export const TableRow = ({ user, selectedUsers = [], onToggleSelect, onDelete }) =>
{
    const isSelected = !!selectedUsers.find((u) => u.id === user.id);

    const renderName = () => (
        <>
            <span key="fnamelname">
                {`${user.firstName} ${user.lastName}`}
                {user.isCompanyAdmin && <AdminTag />}
                {user.isSuspended && <SuspendedTag />}
            </span>
            <p key="useremail" className="emailUser">{ user.email }</p>
        </>
    );

    const renderTeams = () =>
    {
        if (user?.teams == null)
            return "—";
        if (user?.teams?.length === 1)
            return user.teams[0].name;
        else
            return <InfoPopup type="teams" number={user.teams.length} list={user.teams}/>;
    };

    const renderProperties = () =>
    {
        if (user?.assignedProperties == null)
            return "—";
        if (user?.assignedProperties?.length === 1)
            return user.assignedProperties[0].propertyName;
        else
            return <InfoPopup type="properties" number={user.assignedProperties.length} list={[...user.assignedProperties]?.sort((a,b) => a?.propertyName?.localeCompare(b?.propertyName))}/>;
    };

    const renderProducts = () =>
    {
        if (user?.products == null)
            return "—";
        if (user?.products?.length === 1)
            return user.products[0].productName;
        else
            return <InfoPopup type="products" number={user.products.length} list={user.products} />;
    };

    return (
        <div className="tableBoxRow">
            <Checkbox className="secondary deleteCheckbox" disabled={user.isCompanyAdmin} checked={isSelected} onChange={() => onToggleSelect(user)} />
            <TableBoxColumn>{renderName()}</TableBoxColumn>
            <TableBoxColumn>{renderTeams()}</TableBoxColumn>
            <TableBoxColumn>{renderProperties()}</TableBoxColumn>
            <TableBoxColumn>{renderProducts()}</TableBoxColumn>
            <TableBoxColumn className="tableBoxColumnLastCol" narrow>
                <EditUserModal user={user} />
                {!user.isCompanyAdmin && <ButtonIcon className="deleteButton" icon="trash" onClick={() => onDelete(user)} />}
            </TableBoxColumn>
        </div>
    );
};

/**
 * @param {object} props
 * @param {"properties" | "teams" | "products"} props.type
 * @param {number} props.number
 * @param {Array} props.list
 * @returns {JSX.Element}
 */
const InfoPopup = ({ number, type, list }) =>
{
    const capitalize = (word) => word[0].toUpperCase() + word.slice(1).toLowerCase();

    const renderList = () =>
    {
        switch (type)
        {
        case "teams":
            return list.map((team) => <p key={team.id}>{team.name}</p>);
        case "properties":
            return list.map((property) => <p key={property.propertyId}>{property.propertyName}</p>);
        case "products":
            return list.map(({ productId, productName }) => <p key={productId}>{productName}</p>);
        default:
            return "";
        }
    };

    return (
        <Popup className="popupBox hasPopup"
            trigger={<Button className="buttonIcon" content={`${number} ${type}`} />}
            on="click"
            position="bottom center"
        >
            <SettingsPopupLayout heading={capitalize(type)}>
                {renderList()}
            </SettingsPopupLayout>
        </Popup>
    );
};

const EmptyUserRow = () => (
    <div className="tableBoxRow emptyTableList">
        <Icon circular name="users" size="big" />
        <p>
            <Trans 
                i18nKey="UserTeamsRoles.No_Users_Found"
            />
        </p>
    </div>
);

const ErrorUserRow = () => (
    <div className="tableBoxRow emptyTableList">
        <Icon circular name="users" size="big" />
        <p>
            <Trans 
                i18nKey="UserTeamsRoles.Cannot_Retrive_Users"
            />
        </p>
    </div>
);
