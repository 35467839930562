import React, { useContext, useMemo, useState } from "react";
import { Tab } from "semantic-ui-react";
import { useQuery } from "react-query";
import { QUERIES } from "../../../../_api/queries";
import { DropdownForm } from "../../../common/DropdownForm";
import { TextGroup } from "../../../common/TextGroup";
import { AuthContext } from "../../../../_store/AuthProvider";
import RolesTableView from "./roles/RolesTableView";
import { useTranslation } from "react-i18next";

export const TabRoles = () =>
{
    const trans = useTranslation().t;

    const { state } = useContext(AuthContext);

    const { data: admin, status: propertiesStatus } = useQuery({
        ...QUERIES.utr.USERS(state.userInfo.company.companyUID),
        select: (usersData) => usersData?.find?.((user) => user.isCompanyAdmin) || {},
        refetchOnWindowFocus: false
    });

    const [propertyFilter, setPropertyFilter] = useState();
    const properties = useMemo(() => admin?.assignedProperties
        ?.filter((prop) => prop.propertyName)
        ?.sort((a, b) => a.propertyName.localeCompare(b.propertyName)) || [], [admin]);

    const { data: contacts, status: contactsStatus, refetch } = useQuery({
        ...QUERIES.utr.ROLES(state.userInfo.company.companyUID),
        onSuccess: (data) => setPropertyFilter(properties?.filter((prop) => data?.map?.((c) => c.PropertyId).includes(prop.propertyId.toString()))?.[0]?.propertyId.toString()),
        enabled: !!admin,
        refetchOnWindowFocus: false
    });

    const nonDefaultContacts = useMemo(() => properties?.filter((prop) => contacts?.map?.((c) => c.PropertyId).includes(prop.propertyId.toString())), [contacts, properties]);

    return (
        <Tab.Pane>
            <div className="defaultWrap flexible tabRoleHead">
                <TextGroup flexible title={trans("UserTeamsRoles.Property") + ":"}>
                    <DropdownForm
                        onChange={(e, { value }) => setPropertyFilter(value)}
                        options={nonDefaultContacts?.map((prop) => ({ key: prop.propertyId, text: prop.propertyName, value: prop.propertyId.toString() }))}
                        value={propertyFilter}
                        loading={(propertiesStatus === "loading") || (contactsStatus === "loading")}
                    />
                </TextGroup>
            </div>
            <RolesTableView
                properties={properties}
                contacts={contacts || []}
                refreshContacts={refetch}
                propertiesStatus={propertiesStatus}
                contactsStatus={contactsStatus}
                propertyFilter={propertyFilter}
            />
        </Tab.Pane>
    );
};
