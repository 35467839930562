import React, { useRef } from "react";
import { Button, Header, Segment, Tab } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import serverApi from "../../../../../_api/server.api";
import "./UploadCSVTemplate.css";
import { CSVReader } from "react-papaparse";
import { useSetState } from "ahooks";
import { validatePassword } from "../../../../../_utils/misc";
import { Trans, useTranslation } from "react-i18next";
import LinkButton from "../../../../common/LinkButton";


export const UploadCSVTemplate = ({ setFieldValue }) =>
{
    const trans = useTranslation().t;
        
    const COLUMN_NAMES = {
        firstName: trans("UserTeamsRoles.First_Name"),
        lastName: trans("UserTeamsRoles.Last_Name"),
        email: trans("UserTeamsRoles.Email"),
        password: trans("UserTeamsRoles.Password")
    };
        
    const OPTIONAL_COLUMN = [COLUMN_NAMES.password];
    const uploadBoxRef = useRef();
    const [state, setState] = useSetState({
        errors: [],
        modalOpen: false
    });

    const handleDownloadTemplate = (e) =>
    {
        e.preventDefault();
        serverApi.downloadPublicFile("Invite-Users-Template.csv");
    };

    const fillFormWithCSVData = (data) =>
    {
        // Check for valid .csv file format
        const fileName = uploadBoxRef?.current?.state?.file?.name;
        if (!fileName.endsWith(".csv"))
        {
            setState({ errors: [{ message: trans("UserTeamsRoles.Invalid_File_Type") }] });
            return;
        }

        // Ensure file is not empty
        if (data.length === 0)
        {
            setState({ errors: [{ message: trans("UserTeamsRoles.CSV_File_Is_Empty") }] });
            return;
        }

        // Show parser errors if any
        const errors = data.map((row) => row.errors).filter((errs) => errs.length).flat();
        if (errors.length > 0)
        {
            setState({ errors });
            return;
        }

        // check for the correct column names
        const fields = data?.[0]?.meta?.fields || {};
        for (const field of Object.values(COLUMN_NAMES))
        {
            if (!fields?.includes(field) && !OPTIONAL_COLUMN.includes(field))
            {
                setState({
                    errors: [{
                        message: <Trans
                            i18nKey="UserTeamsRoles.CSV_File_Must_Have_Coulmns"
                            values={{ firstName: COLUMN_NAMES.firstName, lastName: COLUMN_NAMES.lastName, email: COLUMN_NAMES.email }}
                        /> 
                    }] 
                });
                return;
            }
        }

        const isInvalidPassword = (p) => !!p && Object.values(validatePassword(p)).some((value) => !value);
        const passwords = data.map((row) => row.data[COLUMN_NAMES.password]);
        if (passwords.some(isInvalidPassword))
        {
            setState({ errors: [{ message: trans("UserTeamsRoles.Password_Must_Be_Atleast_Eight_Character") }] });
            return;
        }

        // csv has passed initial validation. Populate form state with data and close modal.
        let colDNE = false;
        const rows = data.map((row) =>
        {
            const rowData = row.data;

            if (!rowData[COLUMN_NAMES.firstName] || !rowData[COLUMN_NAMES.lastName] || !rowData[COLUMN_NAMES.email])
            {
                colDNE = true;
            }

            return {
                firstName: rowData[COLUMN_NAMES.firstName],
                lastName: rowData[COLUMN_NAMES.lastName],
                email: rowData[COLUMN_NAMES.email],
                password: rowData[COLUMN_NAMES.password]
            };
        });

        // CSV does not contain one of the cols that we need
        if (colDNE)
        {
            setState({ errors: [{ message: trans("UserTeamsRoles.CSV_File_Has_One_More_Empty_Rows") }] });
            return;
        }

        setFieldValue("users", rows);

        // pause for a second to show successful upload screen
        setTimeout(handleCloseModal, 1500);
    };

    const handleCloseModal = () =>
    {
        setState({ errors: [], modalOpen: false });
    };

    return (
        <ModalLayout className="uploadCSVTemplateModal"
            trigger={<Button className="buttonIcon" content={trans("UserTeamsRoles.CSV_Template")} onClick={() => setState({ modalOpen: true })} />}
            heading={trans("UserTeamsRoles.Upload_CSV_Template")}
            onClose={handleCloseModal}
            open={state.modalOpen}
            // actions={<Button color="orange" floated="right" content="Upload" /> }
        >
            <p className="pModal">

                <Trans
                    i18nKey="InviteUserModal.Please_Upload_CSV_File" 
                    components={{ linkButton: <LinkButton className="linkButton" onClick={handleDownloadTemplate} /> }}
                />
            </p>
            <CSVReader
                ref={uploadBoxRef}
                noClick
                noProgressBar
                addRemoveButton={state?.errors?.length > 0}
                onDrop={(data) => fillFormWithCSVData(data)}
                onRemoveFile={() => setState({ errors: [] })}
                config={{
                    header: true,
                    skipEmptyLines: true
                }}
            >
                <Tab.Pane>
                    <Segment placeholder className="brandUpload">
                        <Header icon content={trans("UserTeamsRoles.Drag_Drop_Files")} subheader="or" />
                        <Button basic primary size="large" content={trans("UserTeamsRoles.Browse")} onClick={(e) => uploadBoxRef?.current?.open(e)}/>
                    </Segment>
                </Tab.Pane>
            </CSVReader>
            {(state.errors?.length > 0)
            && <div>
                {state.errors.map((error) => <div className="field-error" key={error.row || error?.index}>
                    {`${(error.row != null)? `Row ${error.row}: `: ""}${error.message}`}
                </div>
                )}
            </div>}
        </ModalLayout>
    );
};
