import React from "react";
import { Image } from "semantic-ui-react";

const AccountAccessListItem = ({ icon, content, detail }) => (
    <div className="accountListItem">
        {<Image 
            as="span" 
            src={icon ? icon : "/img/icon-access-building.svg" }//{`/img/icon-access-${icon ? icon: "building"}.svg`} 
        />} 
        {/* this needs to be changed with place */}
        <p>
            {content}
            <span>{detail}</span>
        </p>
    </div>
);

export default AccountAccessListItem;
