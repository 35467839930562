import React, { useContext } from "react";
import { useSetState } from "ahooks";
import { Button, Dropdown, Input } from "mapsted-components";
import { Button as SButton } from "semantic-ui-react";
import cns from "classnames";
import { string } from "yup";

import { AuthContext } from "../../../../_store/AuthProvider";
import serverApi from "../../../../_api";
import { getDateFormats } from "../../../../_utils/misc";
import TIMEZONES from "../../../../_constants/timezones";

import { ContentGroup } from "../../../common/ContentGroup";
import { TextGroup } from "../../../common/TextGroup";
import { DividerLine } from "../../../common/DividerLine";

import { SettingsLayout } from "../SettingsLayout";
import { SectionSettings } from "../SectionSettings";
import "./PersonalPreferences.css";
import AvatarEditor from "../avatarEditor/AvatarEditor";
import { NotificationContext } from "../../../notifications";
import RouteLeavingGuard from "../../../common/RouteLeavingGuard";
import { useTranslation } from "react-i18next";
import { useWatchedValue } from "../../../../_utils/stateHooks";
import { get } from "../../../../_utils/misc";
import { ChangePasswordV2 } from "./ChangePasswordV2";

const DATE_FORMATS = getDateFormats().map((d, i) => ({
    key: i,
    text: d.example,
    value: JSON.stringify(d.options)
}));

const TimeZoneList = TIMEZONES.map((t) => ({ key: t, text: t, value: t }));

export const PersonalPreferences = () =>
{
    const { state: authState, initUserSettings } = useContext(AuthContext);
    const trans = useTranslation().t;

    const notificationContext = useContext(NotificationContext);
    const userInfo = authState.userInfo;

    const firstName = useWatchedValue(userInfo.firstName, string().trim().required());
    const lastName = useWatchedValue(userInfo.lastName, string().trim().required());
    const timezone = useWatchedValue(userInfo.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
    const timeFormat = useWatchedValue(userInfo.timeFormat || DATE_FORMATS[0].value);


    const [state, setState] = useSetState({
        loading: false,
        imageLoading: false,
        changePasswordModalOpen: false
    });

    const imageSrc = React.useMemo(() => get(authState, "avatar.content", null),[authState]);

    const changed = React.useMemo(() => [firstName, lastName, timezone, timeFormat].some(({ changed }) => changed),[firstName, lastName, timeFormat, timezone]);

    const isInvalid = React.useMemo(() => [firstName, lastName, timezone, timeFormat].some(({ error }) => error),[firstName, lastName, timeFormat, timezone]);

    const handleSave = () =>
    {

        let update = {
            firstName: firstName.current.trim(),
            lastName: lastName.current.trim(),
            timezone: timezone.current,
            timeFormat: timeFormat.current
        };

        // updates user only
        setState({ loading: true });
        serverApi.updateCurrentUserAndProfileImage({ ...authState.userInfo, ...update }, false, false)
            .then((success) =>
            {
                if (success)
                {
                    return serverApi.silentLogin(serverApi.token)
                        .then(() => initUserSettings(authState.token, serverApi.userData.user, false))
                        .then(() => true);
                }
                throw new Error("error updating");
            })
            .then((success) =>
            {
                if (success)
                {
                    firstName.reset(update.firstName);
                    lastName.reset(update.lastName);
                    timezone.reset(update.timezone);
                    timeFormat.reset(update.timeFormat);

                }
                else
                {
                    notificationContext.addNotification("danger", trans("PersonalPreferences.Error"), trans("PersonalPreferences.Your_preferences_were_not_saved__Please_"));
                }
            })
            .catch((err) =>
            {
                console.log("updateUserPrefs error:", err);
                notificationContext.addNotification("danger", trans("PersonalPreferences.Error"), trans("PersonalPreferences.Your_preferences_were_not_saved__Please_"));
            })
            .then(() => setState({ loading: false }));
    };

    const handleImageSave = (imageData) =>
    {
        let imageModel = {
            Name: "",
            Content: imageData,
            ImageId: ""
        };
        setState({ imageLoading: true });
        serverApi.updateUserProfileImage(imageModel)
            .then((success) =>
            {

                if (success)
                {
                    return serverApi.silentLogin(serverApi.token)
                        .then(() => initUserSettings(authState?.token, serverApi.userData.user, false))
                        .then(() => setState({ imageLoading: false }));
                }
                else
                {
                    notificationContext.addNotification("danger", trans("PersonalPreferences.Error"), trans("PersonalPreferences.Image_could_not_be_saved__Please_try_aga"));
                }
            })
            .catch((err) =>
            {
                setState({ imageLoading: false });
                console.log("updateUserPrefs error:", err);
                notificationContext.addNotification("danger", trans("PersonalPreferences.Error"), trans("PersonalPreferences.Image_could_not_be_saved__Please_try_aga"));
            });
        // .then(() => setState({ imageLoading: false }));
    };

    const handleResetValues = React.useCallback(() =>
    {
        firstName.resetOriginal();
        lastName.resetOriginal();
        timezone.resetOriginal();
        timeFormat.resetOriginal();
    },[firstName, lastName, timeFormat, timezone]);


    return (
        <SettingsLayout className="personalPreferences">
            <RouteLeavingGuard when={changed} />
            <div className="settingsWrapper">
                <SectionSettings icon="user-settings" heading={trans("PersonalPreferences.User_Settings")}>

                    <ContentGroup heading={trans("PersonalPreferences.Profile_Photo")}>
                        <p className="paragraph">
                            {trans("PersonalPreferences.Upload_your_profile_photo_below__Your_im")} <br />
                            {trans("PersonalPreferences.Supported_file_types__PNG__JPG__JPEG__GI")}
                        </p>

                        <div className="defaultWrap flexible displayImg hubDisplay">
                            <AvatarEditor
                                imageSrc={imageSrc}
                                placeholder="/img/img-placeholder.svg"
                                handleSave={handleImageSave}
                                heading={trans("PersonalPreferences.Profile_Photo")}
                                description={trans("PersonalPreferences.Upload_your_profile_photo_below__Your_im")}
                                loading={state.imageLoading}
                            />
                        </div>
                    </ContentGroup>

                    <ContentGroup heading={trans("PersonalPreferences.Basic_Information")}>
                        <div className="flexiblePreference">
                            <TextGroup flexible title={trans("PersonalPreferences.First_Name_")}>
                                <Input
                                    placeholder={trans("PersonalPreferences.e_g__John")}
                                    value={firstName.current}
                                    onChange={(e, { value }) => firstName.update(value)}
                                    error={!!firstName.error}
                                />
                            </TextGroup>
                            <TextGroup flexible title={trans("PersonalPreferences.Last_Name_")}>
                                <Input
                                    placeholder={trans("PersonalPreferences.e_g__Smith")}
                                    value={lastName.current}
                                    onChange={(e, { value }) => lastName.update(value)}
                                    error={!!lastName.error}
                                />
                            </TextGroup>
                        </div>
                    </ContentGroup>

                    <DividerLine gap="30px 0 40px" />

                </SectionSettings>

                <SectionSettings icon="time-date" heading={trans("PersonalPreferences.Time_&_Date")}>

                    <ContentGroup className="timeDateMargin">
                        <div className="flexiblePreference fluid">
                            <TextGroup flexible title={trans("PersonalPreferences.Time_Zone_")}>
                                <Dropdown
                                    options={TimeZoneList}
                                    onChange={(e, { value }) => timezone.update(value)}
                                    value={timezone.current}
                                    search={true}
                                    upward={true}
                                    selectOnBlur={false}
                                />
                                <span className="extraDetail">{trans("PersonalPreferences.Select_the_time_zone_from_the_dropdown_l")}</span>
                            </TextGroup>

                            <TextGroup flexible title={trans("PersonalPreferences.Date_Format_")}>
                                <Dropdown
                                    options={DATE_FORMATS}
                                    onChange={(e, { value }) => timeFormat.update(value)}
                                    value={timeFormat.current}
                                    search={true}
                                    upward={true}
                                    selectOnBlur={false}
                                />
                                <span className="extraDetail">{trans("PersonalPreferences.Select_the_date_format_from_the_dropdown")}</span>
                            </TextGroup>
                        </div>

                    </ContentGroup>
                    <DividerLine gap="20px 0 40px" />
                    <ContentGroup>
                        <Button
                            content={trans("PersonalPreferences.Change_Password")}
                            onClick={() => setState({ changePasswordModalOpen: true })}
                        />
                    </ContentGroup>

                    <ChangePasswordV2
                        onSuccess={() => setState({ changePasswordModalOpen: false })}
                        modalProps={{ open: state.changePasswordModalOpen, onClose: () => setState({ changePasswordModalOpen: false }) }}
                    />

                </SectionSettings>

            </div>

            <div className={cns("stickyActionGroup", { show: changed || state.loading })}>
                <SButton color="grey" floated="left" content={trans("PersonalPreferences.Cancel")} onClick={handleResetValues} disabled={state.loading} />
                <SButton color="orange" floated="right" content={trans("PersonalPreferences.Save")} disabled={(!changed || isInvalid)} loading={state.loading} onClick={handleSave} />
            </div>
        </SettingsLayout>
    );
};
