import React, { useEffect } from "react";
import { Icon, Input, Pagination } from "semantic-ui-react";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { SectionSettings } from "../../SectionSettings";
import { Trans, useTranslation } from "react-i18next";

export const AuditLogs = ({ title, addBreadCrumb, removeBreadCrumbAfter }) =>
{
    useEffect(() =>
    {
        addBreadCrumb({
            name: title,
            onClick()
            {
                // remove all breadcrums after current one
                removeBreadCrumbAfter(title);
            }
        });
    }, []);

    const trans = useTranslation().t;

    return (
        <>
            <SectionSettings className="auditLogsLayout">
                <p className="paragraph">
                    {trans("AuditLogs.View_The_Activity_Of_All_Changes")}
                </p>
                <div className="auditLogsWrapper">
                    <Input light className="inputForm searchDashboard" icon="search" placeholder={trans("AuditLogs.Search_Logs")} />
                    <AuditLogsTable />
                    <div className="paginationBar">
                        <Pagination
                            className="paginationDashboard"
                            totalPages={5}
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name="angle left"/>, icon: true }}
                            nextItem={{ content: <Icon name="angle right"/>, icon: true }}
                        />
                        <div className="displayText">
                            <span>
                                <Trans i18nKey="AuditLogs.Showing" />
                            </span>
                        </div>
                    </div>
                </div>

            </SectionSettings>
        </>
    );
};

const AuditLogsTable = () => (
    <div className="auditLogsTable">
        <AuditLogsTableHead />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
        <AuditTableRow />
    </div>
);

const AuditLogsTableHead = () => 
{
    const trans = useTranslation().t;

    return (
        <div className="auditTableRow">
            <AuditTableColumn detail={trans("AuditLogs.TIMESTAMP")}  />
            <AuditTableColumn detail={trans("AuditLogs.USER")}  />
            <AuditTableColumn detail={trans("AuditLogs.EVENT_TYPE")}  />
            <AuditTableColumn detail={trans("AuditLogs.CHANGE")} />
            <AuditTableColumn detail={trans("AuditLogs.IP_ADDRESS")}  />
        </div>
    );
};

export const AuditTableRow = () => 
{
    const trans = useTranslation().t;

    return (
        <div className="auditTableRow">
            <AuditTableColumn detail={trans("AuditLogs.JuneDate")} />
            <AuditTableColumn detail={trans("AuditLogs.Name_Surname")} />
            <AuditTableColumn detail={trans("AuditLogs.Brand_Campaign_Created")} />
            <AuditTableColumn detail={trans("AuditLogs.Account_Setting_Change")} />
            <AuditTableColumn>
                123.456.1.17
                <ButtonIcon icon="trash" />
            </AuditTableColumn>
        </div>
    );
};

export const AuditTableColumn = ({ detail, children }) => (
    <div className="auditTableColumn">
        {
            detail && (<p>{detail}</p>)
        }
        {children}
    </div>
);
