import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Header } from "semantic-ui-react";

export const SettingsPopupLayout = ({ heading, children }) => (
    <>
        <div className="alignerPopup">
            <Header content={heading} />
        </div>
        <Scrollbars className="popupScroll" autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"200px"}>
            <div className="alignerPopup">
                {children}
            </div>
        </Scrollbars>
    </>
);
