import React, { useContext, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Checkbox, Header, Input, Popup } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import { EditUserItem, EDIT_USER_ITEM_TYPES } from "../EditUserItem";
import { HeadingTitle } from "../HeadingTitle";
import { Form, FormikProvider, getIn, useFormik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { useMutation, useQuery } from "react-query";
import { MUTATIONS, QUERIES } from "../../../../../_api/queries";
import { LoadingScreen } from "mapsted-components";
import { NotificationContext } from "../../../../notifications";
import { redirectToContactPageLink } from "../../../../../_utils/dashboardCards";
import serverApi from "../../../../../_api/server.api";
import { AdminTag, SuspendedTag } from "../TableTabUsers";
import { isEqual } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import AnchorLink from "../../../../common/AnchorLink";

export const EditUserModal = ({ disabled = false, user }) =>
{
    const { state, logout } = useContext(AuthContext);
    const notification = useContext(NotificationContext);
    const { data: admin, refetch: refetchUsers } = useQuery({
        ...QUERIES.utr.USERS(state.userInfo.company.companyUID),
        select: (usersData) => usersData?.find?.((user) => user.isCompanyAdmin) || {}
    });

    const trans = useTranslation().t;

    const [modalOpen, setModalOpen] = useState(false);
    const [propertySearch, setPropertySearch] = useState("");

    const formik = useFormik({
        initialValues: {
            editUser: {
                firstName: user?.firstName,
                lastName: user?.lastName,
                // email: "",
                selectedProducts: user?.products?.map((product) => product.productId.toString()) || [],
                selectedProperties: user?.assignedProperties?.map((property) => property.propertyId.toString()) || []
            }
        },
        onSubmit: () => onEditUserFormSubmit().then(() => null, () => null),
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            editUser: Yup.object().shape({
                firstName: Yup.string().required(),
                lastName: Yup.string().required(),
                // email: Yup.string().email("Email must be valid"),
                selectedProducts: Yup.array(),
                selectedProperties: Yup.array()
            })
        })
    });
    const { values, submitForm, errors, handleSubmit, handleReset, touched, isSubmitting, isValid, initialValues } = formik;

    const { mutateAsync, reset } = useMutation(MUTATIONS.utr.USER_EDIT(refetchUsers));

    const onEditUserFormSubmit = async () => await mutateAsync({
        ...values.editUser,
        firstName: isEqual(initialValues.editUser.firstName, values.editUser.firstName)? "": values.editUser.firstName,
        lastName: isEqual(initialValues.editUser.lastName, values.editUser.lastName)? "": values.editUser.lastName,
        userId: user.id
    })
        .then(() => handleModalClose())
        .catch((err) =>
        {
            handleModalClose();
            notification.addNotification("danger", "Edit Team Failed", "Try again later");
        });

    function getStyles(fieldName)
    {
        if (getIn(touched, fieldName) && getIn(errors, fieldName))
            return { borderColor: "#F14850" };
    }

    /**
     * If a user field is touched and has error then show error message.
     * @return {boolean}
     */
    function getTouchedUser()
    {
        const fNameTouched = (getIn(touched, "editUser.firstName") && getIn(errors, "editUser.firstName"));
        const lNameTouched = (getIn(touched, "editUser.lastName") && getIn(errors, "editUser.lastName"));
        return (fNameTouched || lNameTouched);
    }

    const renderProducts = () =>
    {
        if (admin?.products == null)
        {
            return <EditUserItem
                content={trans("EditUserModal.You_Do_Not_Have_Any_Product_Licences")}
                link={(
                    <div style={{ fontSize: "13px" }}>
                        <Trans 
                            i18nKey="EditTeamModal.Contact_Sales_To_Purchase" 
                            components={{ link: <AnchorLink href={redirectToContactPageLink(serverApi?.userData?.user?.userInfo)} /> }}
                        />
                        {/* <a href={redirectToContactPageLink(serverApi?.userData?.user?.userInfo)} target="_blank" rel="noreferrer">Contact sales</a> to purchase */}
                    </div>
                )}
            />;
        }
        return admin?.products?.map((product) => (
            <EditUserItem
                key={`product-${product.productId}`}
                checkbox={user?.isCompanyAdmin? <Checkbox disabled={true} checked={true} className="secondary" />:                    <div className="defaultCB">
                    <label/>
                    <Field
                        name="editUser.selectedProducts"
                        type="checkbox"
                        value={product.productId.toString()}
                        className="ui fitted checkbox secondary"
                    />
                </div>}
                content={`${product.productName}`}
                detail={<Trans 
                    i18nKey="EditTeamModal.Licence_Expiry_Date"
                    values={{ date: new Date(product.expiryDate).toDateString().split(" ").slice(1).join(" ") }}
                />}
                items={(product.productId === 1)? ["Mapsted Maps - Mobile", "Mapsted Maps - Web"].concat(product?.licenceNames?.split(",")): product?.licenceNames?.split(",")}
                type={EDIT_USER_ITEM_TYPES.PRODUCT}
            />
        ));
    };

    const renderProperties = () =>
    {
        const filtered = admin?.assignedProperties?.filter((prop) => prop.propertyName && prop.propertyName.toUpperCase().includes(propertySearch.toUpperCase()));
        if (filtered == null)
        {
            return <EditUserItem content={trans("EditUserModal.No_Properties_Are_Assigned_To_You")} link={<div style={{ fontSize: "13px" }}>
                <Trans 
                    i18nKey="EditTeamModal.Please_Add_Property_To_Account" 
                    components={{ link: <AnchorLink href={"https://manage.mapsted.com/dashboard/property"} /> }}
                />
                {/* Please <a href="https://manage.mapsted.com/dashboard/property">add a property to your account</a> */}
                
            </div>} />;
        }
        else if (filtered?.length < 1)
        {
            return <EditUserItem content={trans("EditUserModal.No_Properties_Found")} detail={trans("EditUserModal.Try_Adjusting_Your_Search")} />;
        }
        else
        {
            return filtered?.sort((a,b) => a?.propertyName?.localeCompare(b?.propertyName))?.map((property) => (
                <EditUserItem
                    key={"property-" + property.propertyId}
                    checkbox={(() =>
                    {
                        if (user?.isCompanyAdmin)
                        {
                            return <Checkbox disabled={true} checked={true} className="secondary" />;
                        }
                        return  (
                            <div className="defaultCB">
                                <label/>
                                <Field
                                    name="editUser.selectedProperties"
                                    type="checkbox"
                                    value={property.propertyId.toString()}
                                />
                            </div>
                        );
                    })()}
                    imgSrc={admin?.assignedPropertyIcons?.[property.propertyId]}
                    content={property.propertyName}
                    detail={((property.cityName === property.stateProvince) || !property.cityName || !property.stateProvince) ? property.cityName || "": property.cityName + ", " + property.stateProvince}
                    items={property?.buildings}
                />
            ));
        }
    };

    const handleModalClose = () =>
    {
        setModalOpen(false);
        handleReset();
        reset();
    };

    /**
     * @param initial {[]}
     * @param current {[]}
     * @returns {boolean}
     */
    const checkboxesEqual = (initial = [], current = []) => isEqual(new Set(initial), new Set(current));

    /**
     * If checkboxes have same current values as initial values but different order, this means there are no changes
     * Formik cares about array order to determine dirty - so this does an unordered comparison
     * @type {boolean}
     */
    const clean = checkboxesEqual(initialValues.editUser.selectedProperties, values.editUser.selectedProperties)
        && checkboxesEqual(initialValues.editUser.selectedProducts, values.editUser.selectedProducts)
        && isEqual(initialValues.editUser.firstName, values.editUser.firstName)
        && isEqual(initialValues.editUser.lastName, values.editUser.lastName);

    return (
        <FormikProvider value={formik}><Form onSubmit={handleSubmit}>
            {isSubmitting && <LoadingScreen />}
            <ModalLayout className="editUserModal"
                trigger={<Button className="buttonIcon" type="button" disabled={disabled} onClick={() => setModalOpen(true)}>
                    <img src="/img/icon-edit.svg" alt="edit"/>
                </Button>}
                open={modalOpen}
                onClose={handleModalClose}
                customHeading={<>
                    Edit User
                    {(user?.isCompanyAdmin || user?.isAdmin) && <AdminTag />}
                    {user?.isSuspended && <SuspendedTag />}
                </>}
                actions={<>
                    <Button color="grey" floated="left" type="button" content={trans("EditUserModal.Back")} onClick={() => handleModalClose()} />
                    <Button color="orange" floated="right" content={trans("EditUserModal.Save")} type="submit" disabled={clean || !isValid} onClick={submitForm} />
                </>}
            >
                <div className="editUserModalSidebar">
                    <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"506px"}>
                        <div className="alignerScroll">
                            <TextGroup heading={trans("EditUserModal.First_Name")} contentClassName="ui input inputForm">
                                <Field
                                    name="editUser.firstName"
                                    className="inputForm"
                                    type="text"
                                    style={getStyles("editUser.firstName")}
                                    placeholder={trans("EditUserModal.First_Name")}
                                />
                            </TextGroup>
                            <TextGroup heading={trans("EditUserModal.Last_Name")} contentClassName="ui input inputForm">
                                <Field
                                    name="editUser.lastName"
                                    className="inputForm"
                                    type="text"
                                    style={getStyles("editUser.lastName")}
                                    placeholder={trans("EditUserModal.Last_Name")}
                                />
                            </TextGroup>
                            <TextGroup heading={trans("EditUserModal.Email_ID")} contentClassName="ui input inputForm">
                                {
                                    <Input disabled={true} className="inputForm" placeholder={user?.email} />
                                    // <Field
                                    //     name="editUser.email"
                                    //     className="inputForm"
                                    //     placeholder={user?.email}
                                    //     type="email"
                                    //     style={getStyles("editUser.email")}
                                    // />
                                }
                            </TextGroup>
                            {!user?.isCompanyAdmin && <div className="editUserActionsGroup">
                                {!user?.isSuspended && <SuspendUserPopup userId={user?.id} firstName={user?.firstName} lastName={user?.lastName} refetchUsers={refetchUsers} handleModalClose={handleModalClose} />}
                                {user?.isSuspended && <ReinstateUserPopup userId={user?.id} firstName={user?.firstName} lastName={user?.lastName} refetchUsers={refetchUsers} handleModalClose={handleModalClose} />}
                                <DeleteUserPopup  userId={user?.id} firstName={user?.firstName} lastName={user?.lastName} refetchUsers={refetchUsers} handleModalClose={handleModalClose} />
                                {!user?.isSuspended && <SetAdminPopup userId={user?.id} forceLogout={() => logout()} />}
                            </div>}
                            {(getTouchedUser())
                            && <div className="field-error">
                                {trans("EditUserModal.First_Name_Last_Name_Required")}
                            </div>}
                            <ErrorMessage name="editUser.email" component="div" className="field-error" />
                            <ErrorMessage name="editUser.selectedProperties" component="div" className="field-error" />
                        </div>
                    </Scrollbars>
                </div>

                <div className="editUserModalContentBody">
                    <div className="editUserModalContent">
                        <HeadingTitle heading="Product"/>
                        <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"194px"}>
                            <div className="alignerScroll">
                                {renderProducts()}
                            </div>
                        </Scrollbars>
                    </div>

                    <div className="editUserModalContent">
                        <HeadingTitle heading="Property" hasSearch
                            isSearchOpen={propertySearch.length > 0}
                            searchInputProps={{
                                onChange: (e, { value }) => setPropertySearch(value),
                                value: propertySearch,
                                type: "text"
                            }}/>
                        <Scrollbars autohide="true" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"194px"}>
                            <div className="alignerScroll alignerHeight">
                                {renderProperties()}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </ModalLayout>
        </Form></FormikProvider>
    );
};

const DeleteUserPopup = ({ firstName, lastName, userId, refetchUsers, handleModalClose }) =>
{
    const notifications = useContext(NotificationContext);
    const { mutateAsync, isLoading } = useMutation(MUTATIONS.utr.USER_DELETE(refetchUsers));
    const [open, setOpen] = useState(false);

    const handleDelete = async () =>
    {
        try
        {
            setOpen(false);
            await mutateAsync(userId);
            handleModalClose();
        }
        catch (e)
        {
            notifications.addNotification("danger", "Could not delete user", "Try again later");
            console.log(e);
        }
    };

    const trans = useTranslation().t;

    return (<>
        {isLoading && <LoadingScreen />}
        <Popup className="popupBox deleteUserPopup"
            trigger={<Button onClick={() => setOpen(true)} basic color="orange" content={trans("EditUserModal.Delete_User")} />}
            on="click"
            position="bottom center"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Header content={trans("EditUserModal.Delete_User")} />
            <p>
                <Trans 
                    i18nKey="EditUserModal.Delete_User_Confirmation"
                    values={{ firstName, lastName }}
                />
            </p>
            <Button floated="right" color="orange" content={trans("EditUserModal.Delete")} onClick={() => handleDelete()} />
        </Popup>
    </>);
};

const SuspendUserPopup = ({ firstName, lastName, userId, refetchUsers, handleModalClose }) =>
{
    const notifications = useContext(NotificationContext);
    const { mutateAsync, isLoading } = useMutation(MUTATIONS.utr.USER_SUSPEND(refetchUsers));
    const [open, setOpen] = useState(false);

    const handleSuspend = async () =>
    {
        try
        {
            setOpen(false);
            await mutateAsync(userId);
            handleModalClose();
        }
        catch (e)
        {
            notifications.addNotification("danger", "Could not suspend user", "Try again later");
            console.log(e);
        }
    };

    const trans = useTranslation().t;

    return (<>
        {isLoading && <LoadingScreen />}
        <Popup className="popupBox deleteUserPopup"
            trigger={<Button onClick={() => setOpen(true)} basic color="orange" content="Suspend User" />}
            on="click"
            open={open}
            position="bottom center"
            onClose={() => setOpen(false)}
        >
            <Header content="Suspend User" />
            <p> <Trans 
                i18nKey="EditUserModal.Suspend_User_Confirmation"
                values={{ firstName, lastName }}
            /></p>
            <Button floated="right" color="orange" content={trans("EditUserModal.Suspend")} onClick={() => handleSuspend()} />
        </Popup>
    </>);
};

// Unsuspend user
const ReinstateUserPopup = ({ firstName, lastName, userId, refetchUsers, handleModalClose }) =>
{
    const notifications = useContext(NotificationContext);
    const { mutateAsync, isLoading } = useMutation(MUTATIONS.utr.USER_UNSUSPEND(refetchUsers));
    const [open, setOpen] = useState(false);

    const handleUnSuspend = async () =>
    {
        try
        {
            setOpen(false);
            await mutateAsync(userId);
            handleModalClose();
        }
        catch (e)
        {
            notifications.addNotification("danger", "Could not reinstate user", "Try again later");
            console.log(e);
        }
    };

    const trans = useTranslation().t;

    return (<>
        {isLoading && <LoadingScreen />}
        <Popup className="popupBox deleteUserPopup"
            trigger={<Button onClick={() => setOpen(true)} basic color="orange" content={trans("EditUserModal.Reinstate_User")} />}
            on="click"
            open={open}
            position="bottom center"
            onClose={() => setOpen(false)}
        >
            <Header content={trans("EditUserModal.Suspend_User")} />
            <p><Trans 
                i18nKey="EditUserModal.Reinstate_User_Confirmation"
                values={{ firstName, lastName }}
            /></p>
            <Button floated="right" color="orange" content={trans("EditUserModal.Reinstate")} onClick={() => handleUnSuspend()} />
        </Popup>
    </>);
};

const SetAdminPopup = ({ forceLogout, userId }) =>
{
    const notifications = useContext(NotificationContext);
    const { mutateAsync, isLoading } = useMutation(MUTATIONS.utr.USER_SET_AS_ADMIN(forceLogout));
    const [open, setOpen] = useState(false);

    const handleSetAsAdmin = async () =>
    {
        try
        {
            setOpen(false);
            await mutateAsync(userId);
            // will force logout onSuccess of useMutation
        }
        catch (e)
        {
            notifications.addNotification("danger", "Could not set user as admin", "Try again later");
            console.log(e);
        }
    };

    const trans = useTranslation().t;

    return (<>
        {isLoading && <LoadingScreen />}
        <Popup className="popupBox"
            trigger={<Button onClick={() => setOpen(true)} basic color="orange" content={trans("EditUserModal.Set_As_Admin")} />}
            on="click"
            position="bottom center"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Header content={trans("EditUserModal.Set_As_Admin")} />
            <p>
                {trans("EditUserModal.Set_As_Admin_Confirmation")}
            </p>
            <Button floated="right" color="orange" content={trans("EditUserModal.Confirm")} onClick={() => handleSetAsAdmin()} />
        </Popup>
    </>);
};
