import React from "react";
import { AuthContext } from "../../../../../_store/AuthProvider";
import { ModalLayout } from "../../../../common/ModalLayout";
import { REDIRECT_TIMER } from "./closeAccountConfig";
import { useTranslation } from "react-i18next";

/**
 * 
 * @type {React.FC<import("semantic-ui-react").ModalProps>}  
 */
const RedirectModal = (props) => 
{

    const { logout } = React.useContext(AuthContext);

    const countdownTimeoutId = React.useRef(null);
    const countdownIntervalId = React.useRef(null);

    const [countdown, setCountdown] = React.useState(parseInt(REDIRECT_TIMER/1000));

    const logOutHandler = React.useCallback(() => 
    {
        clearTimeout(countdownTimeoutId.current);
        clearInterval(countdownIntervalId.current);  
        
        logout();

    },[logout]);

    const handleModalOpen = React.useCallback(() => 
    {
        setCountdown(parseInt(REDIRECT_TIMER/1000));
        countdownIntervalId.current = setInterval(() => setCountdown((prev) => prev - 1), 1000);
        countdownTimeoutId.current = setTimeout(logOutHandler, REDIRECT_TIMER);

        
    },[logOutHandler]);
    
    const handleModalClose = React.useCallback(() => 
    {
        clearTimeout(countdownTimeoutId.current);
        clearInterval(countdownIntervalId.current);   
    },[]);

    const trans = useTranslation().t;

    return (
        
        <ModalLayout 
            className="redirectingModal"
            {...props} 
            success 
            hideClose
            onMount={handleModalOpen}
            onUnmount={handleModalClose}
        >
            <div className="redirectSpinner">{countdown}</div>
            <p className="paraModal">{trans("CloseAccount.Redirecting")}</p>
        </ModalLayout>
    );
};

export default RedirectModal;
