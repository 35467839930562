import serverAPI from "./_api";

export default class Permissions
{
    static roles = {
        superAdmin: 1,
        admin: 2,
        client: 3,
    }

    static access = {
        read: "read",
        write: "write",
        delete: "delete",
    };

    static claims = {
        companies: "companies",
        manageUsers: "manageusers",
        manageCompany: "managecompany",
        manageUserRole: "manageuserrole",
    };

    static subRoles = {
        shoppingMall: "shopping_mall",
        sdk_licence: "sdk_licence"
    };

    static companyTypes = {
        none: 0,
        primary: 1,
    };

    static has(claim, access)
    {
        const userData = serverAPI.userData;
        if (userData && userData.user && userData.user.claimsList)
        {
            return Array.isArray(userData.user.claimsList[claim]) && userData.user.claimsList[claim].includes(access);
        }
        return false;
    }


    static get isAdmin()
    {
        return Permissions.hasRole([Permissions.roles.superAdmin, Permissions.roles.admin]);
    }

    static get isClient()
    {
        return Permissions.hasRole([Permissions.roles.client]);
    }

    static get isSuperAdmin()
    {
        return Permissions.hasRole([Permissions.roles.superAdmin]);
    }

    static get isCompanyAdmin()
    {
        if (Permissions.isClient)
        {
            const userData = serverAPI.userData;
            if (userData && userData.user && userData.user.userInfo)
                return userData.user.userInfo.id === userData.user.userInfo.company.adminId;
        }
        return false;
    }

    static get isPrimaryCompany()
    {
        const userData = serverAPI.userData;
        if (userData && userData.user && userData.user.userInfo)
            return userData.user.userInfo.company.companyType === Permissions.companyTypes.primary;

        return false;
    }

    static hasRole(arrayOfRoles)
    {
        const userData = serverAPI.userData;
        const roleId = userData && userData.user && userData.user.userRole.roleId;

        return roleId && arrayOfRoles.includes(roleId);
    }

    static hasSubRole(subRole)
    {
        const userData = serverAPI.userData;
        const startClaim = `${subRole}_start_date`;
        const endClaim = `${subRole}_end_date`;
        let startDate, endDate;
        let dateNow = new Date();
        const claimsList = userData?.user?.claimsList;

        if (claimsList)
        {
            startDate = Array.isArray(claimsList[startClaim]) && new Date(claimsList[startClaim][0]);
            endDate = Array.isArray(claimsList[endClaim]) && new Date(claimsList[endClaim][0]);

            if (!isNaN(startDate) && !isNaN(endDate))
            {
                return startDate <= dateNow && dateNow <= endDate;
            }
        }
        return false;
    }
}
