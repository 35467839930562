import React from "react";
import { Placeholder } from "semantic-ui-react";
import { AccountAccessColumn } from "../Dashboard";

const AccessLoader = ({ heading, subHeading }) => (
    <AccountAccessColumn heading={heading} subheading={subHeading}>
        {Array(3).fill(
            <div className="accountListItem" style={{ display: "block" }}>
                <p>
                    <span>
                        <Placeholder>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder>
                    </span>
                </p>
            </div>
        )}
    </AccountAccessColumn>
);

export default AccessLoader;
