import React from "react";
import { useTranslation } from "react-i18next";
import { Menu, Popup } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { AccountSettings } from "./components/accountSettings/AccountSettings";
import { PersonalPreferences } from "./components/personalPreferences/PersonalPreferences";
import "./Settings.css";
import { NavLink, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Permissions from "../../permissions";
import { UsersTeamsRoles } from "./components/usersTeamsRoles/UsersTeamsRoles";
import { DevelopersSettings } from "./components/developers/DevelopersSettings";
import { SecurityPrivacy } from "./components/securityPrivacy/SecurityPrivacy";
import Header from "../layout/Header";

const PATHS = {
    ACCOUNT: "/settings/account",
    PERSONAL: "/settings/personal",
    BILLING: "/settings/billing",
    USERS: "/settings/org",
    DEVELOPERS: "/settings/developers",
    SECURITY: "/settings/security"
};

const Settings = () =>
{
    const trans = useTranslation().t;
    const location = useLocation();

    const generateMenuLink = (path, text, disabled = false, disabledText = "Admin Access Only") =>
    {
        if (disabled)
        {
            return <Popup
                content={disabledText}
                trigger={<p className="item disabled" style={{ pointerEvents: "all" }}>{text}</p>}
                position="right center"
            />;
        }
        else
        {
            return {
                as: NavLink,
                id: path,
                to: path,
                key: path,
                content: text,
                active: location.pathname === path,
                disabled
            };
        }
    };

    const isAdmin = Permissions.isAdmin || Permissions.isCompanyAdmin;

    const canAccessDevelopers = Permissions.hasSubRole(Permissions.subRoles.sdk_licence) || Permissions.isSuperAdmin;
    const items = [
        generateMenuLink(PATHS.ACCOUNT, trans("Settings.ACCOUNT_SETTINGS"), !isAdmin),
        generateMenuLink(PATHS.PERSONAL, trans("Settings.PERSONAL_PREFERENCES")),
        generateMenuLink(PATHS.USERS, trans("Settings.USERS_&_TEAMS"), !isAdmin),
        generateMenuLink(PATHS.SECURITY, trans("Settings.SECURITY"), !isAdmin,),
        generateMenuLink(PATHS.DEVELOPERS, trans("Settings.DEVELOPERS"), !canAccessDevelopers, "SDK Licence Required"),
        // generateMenuLink(PATHS.BILLING, trans("Settings.PAYMENT_&_BILLING"), !isAdmin),
        // { content: trans("Settings.INSTALATION"), disabled: true },
        // { content: trans("Settings.SECURITY"), disabled: true }
    ];

    return (
        <>
            <Helmet>
                <title>Settings | Mapsted Hub</title>
                <meta name="description" content={trans("Settings.Mapsted_Hub_Settings_Page")} />
            </Helmet>
            <Header />
            <div className="settingsTabGrid">
                <Menu items={items} text />
                <Switch>
                    <ProtectedRoute
                        hasPermission={isAdmin}
                        redirectPath={PATHS.PERSONAL}
                        path={PATHS.ACCOUNT}
                    >
                        {/* <DevelopersSettings /> */}
                        <AccountSettings/>
                    </ProtectedRoute>
                    <Route path={PATHS.PERSONAL}>
                        <PersonalPreferences />
                    </Route>
                    <ProtectedRoute
                        hasPermission={isAdmin}
                        redirectPath={PATHS.USERS}
                        path={PATHS.USERS}
                    >
                        <UsersTeamsRoles />
                    </ProtectedRoute>
                    <ProtectedRoute
                        hasPermission={canAccessDevelopers}
                        path={PATHS.DEVELOPERS}
                    >
                        <DevelopersSettings />
                    </ProtectedRoute>

                    <ProtectedRoute
                        hasPermission={isAdmin}
                        path={PATHS.SECURITY}
                    >
                        <SecurityPrivacy />
                    </ProtectedRoute>

                    {/*<ProtectedRoute*/}
                    {/*    hasPermission={isAdmin}*/}
                    {/*    redirectPath={PATHS.PERSONAL}*/}
                    {/*    path={PATHS.BILLING}*/}
                    {/*>*/}
                    {/*    <PaymentBilling />*/}
                    {/*</ProtectedRoute>*/}
                    <Route><Redirect to={PATHS.ACCOUNT} /></Route>
                </Switch>
            </div>
        </>
    );
};

export default Settings;
