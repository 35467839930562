import React from "react";
import { Button, Image, Input } from "semantic-ui-react";
import { ModalLayout } from "../../../../common/ModalLayout";
import { TextGroup } from "../../../../common/TextGroup";
import { useTranslation } from "react-i18next";

export const VerificationApplicationModal = () =>
{
    const trans = useTranslation().t;

    return (
        <ModalLayout className="verificationModal" 
            scrolling
            trigger={<Button color="orange" content={trans("TwoStepVerification.Application_Setup_Modal")} style={{ margin: "10px 10px 10px 0", float: "left" }} />}
            customHeading={trans("TwoStepVerification.Two_Step_Verification_Setup_Application")}
            actions={<Button color="orange" floated="right" content={trans("TwoStepVerification.Configure")} />}>
            <div className="aligner">
                <p className="paraModal pModal">
                    {trans("TwoStepVerification.To_Configure_Two_Step")}
                </p>

                <div className="applicationSetupCover">
                    <div className="applicationSetupItem">
                        <Image src="/img/qr-verification-img.svg" />
                    </div>

                    <div className="applicationSetupItem">
                        <Image src="/img/qr-verification.svg" />
                        <div className="codeApplicationVerification">AFA6D4F5SD4F6S5</div>
                        <span>{trans("TwoStepVerification.Secret_Code")}</span>
                    </div>
                </div>

                <TextGroup className="verificationModalCode" flexible title={trans("TwoStepVerification.Verification_Code")}>
                    <Input placeholder={trans("TwoStepVerification.Enter_Code")} icon="check"/>
                    <Button color="orange" basic content={trans("TwoStepVerification.Verify")} />
                </TextGroup>
                    
            </div>
        </ModalLayout>
    );
};