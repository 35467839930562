import React from "react";
import { useTranslation } from "react-i18next";

export const FooterLogin = () => {

    const trans = useTranslation().t;

    return (
        <div className="footer">
            <p>{trans("Footer.CopyRight")}</p>
        </div>
    );
}