import React from "react";
import serverApi from "../../../../../_api/server.api";
import { ModalLayout } from "../../../../common/ModalLayout";
import { NotificationContext } from "../../../../notifications";
import ActionButtons from "./ActionButtons";
import CloseAccountContext from "./CloseAccountContext";
import { useTranslation } from "react-i18next";

/**
 *
 * @type {React.FC<import("semantic-ui-react").ModalProps>}
 */
export const CloseAccountModalStep3 = (props) =>
{

    const { state, handlers, loader } = React.useContext(CloseAccountContext);
    const { addNotification } = React.useContext(NotificationContext);

    const handleCloseAccount = React.useCallback(() =>
    {
        loader.show();

        serverApi.suspendDeleteAccount(state.closeAccountData, "close")
            .then(() =>
            {
                loader.hide();
                handlers.nextStep();
            })
            .catch((error) =>
            {
                loader.hide();
                addNotification("danger", "Error", error.message);
            });

    },[addNotification, handlers, loader, state.closeAccountData]);

    const trans = useTranslation().t;

    return (
        <ModalLayout
            {...props}
            heading={trans("CloseAccount.Close_Account")}
            size="large" actions={<ActionButtons rightButtonProps={{ content: trans("CloseAccount.Close_Account"), onClick: handleCloseAccount }} />}>
            <b className="titleModal">{trans("CloseAccount.Are_You_Sure_Want_To_Close_Account")}</b>
            <p className="paraModal">{trans("CloseAccount.Once_You_Click")}</p>

        </ModalLayout>
    );
};
