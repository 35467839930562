import React from "react";
import { Header, Input } from "semantic-ui-react";

export const HeadingTitle = ({ heading, button, hasSearch, searchInputProps, isSearchOpen, className = "" }) => (
    <Header className={`headingTitle ${className}`}>
        {heading}
        {hasSearch && <Input {...searchInputProps} icon={<img src="/img/icon-search.svg" alt="" />} className={isSearchOpen ? "toggleSearchBar open": "toggleSearchBar"} placeholder="Search" />}
        {button && button}
    </Header>
);
