import React, { useContext, useState } from "react";
import { Input } from "mapsted-components";
import { Button as SButton } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import cns from "classnames";
import { string } from "yup";

import { AuthContext } from "../../../../_store/AuthProvider";
import { useWatchedValue, useStateCallback } from "../../../../_utils/stateHooks";
import { AvatarEditor } from "../avatarEditor/AvatarEditor";

import { ContentGroup } from "../../../common/ContentGroup";
import { SectionSettings } from "../SectionSettings";
import { SettingsLayout } from "../SettingsLayout";

import "./AccountSettings.css";
import serverApi from "../../../../_api";
import IntlPhoneBox from "./phoneBox/IntlPhoneBox";
import { NotificationContext } from "../../../notifications";
import RouteLeavingGuard from "../../../common/RouteLeavingGuard";
import CloseAccount from "./closeAccount/CloseAccount";
import CurrencyDropdown from "./CurrencyDropdown";
import { get } from "../../../../_utils/misc";

const BLANK_ADDRESS = {
    address: "",
    appt: "",
    city: "",
    state: "",
    country: "",
    zip: ""
};
const parseAddress = (string) =>
{
    try
    {
        const result = JSON.parse(string);
        return result === null ? false : result;
    }
    catch (error)
    {
        return false;
    }
};

export const AccountSettings = () =>
{
    const { initUserSettings, ...authContext } = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);

    const company = React.useMemo(() => get(authContext.state, "userInfo.company", {}),[authContext.state]);

    const trans = useTranslation().t;

    
    const [validPhone, setValidPhone] = useState(true);
    const [loading, setLoading] = useStateCallback(false);
    const [imageLoading, setImageLoading] = useState(false);

    const name = useWatchedValue(company.name);
    const address = useWatchedValue(parseAddress(company.address) || BLANK_ADDRESS);
    const phone = useWatchedValue(company.phone || "", string().trim().notRequired().test("phone-check", trans("AccountSettings.Error_In_Phone_Number"), function()
    {
        if (this.originalValue === "") 
        {
            return this.resolve(true);
        }
        return this.resolve(validPhone);
    }));
    const currency = useWatchedValue(company.currency || "USD");


    const handleSave = () =>
    {
        let trimmedName = name.current.trim();
        let update = { name: trimmedName, address: JSON.stringify(address.current), phone: phone.current, currency: currency.current };
        setLoading(true, () =>
        {
            serverApi.updateCompany({ ...company, ...update })
                .then((success) =>
                {
                    if (success)
                    {
                        name.reset(trimmedName);
                        name.update(trimmedName);
                        address.reset(address.current);
                        phone.reset(phone.current);
                        currency.reset(currency.current);
                        return serverApi.silentLogin(serverApi.token)
                            .then(() => initUserSettings(authContext.state?.token, serverApi.userData.user, false))
                            .then(() => true);
                    }
                    else
                    {
                        notificationContext.addNotification("danger", trans("AccountSettings.Error"), trans("AccountSettings.Your_information_was_not_saved_correctly"));
                    }
                })
                .catch((err) =>
                {
                    console.log(err);
                    notificationContext.addNotification("danger", trans("AccountSettings.Error"), trans("AccountSettings.Your_information_was_not_saved_correctly"));
                })
                .then(() => setLoading(false));
        });
    };

    const handleImageSave = (imageData) =>
    {
        let imageModel = {
            Name: "",
            Content: imageData,
            ImageId: ""
        };
        setImageLoading(true);
        
        serverApi.updateCompanyImage(imageModel)
            .then((success) =>
            {
                if (success)
                {
                    return serverApi.silentLogin(serverApi.token)
                        .then(() => initUserSettings(authContext.state?.token, serverApi.userData.user, false))
                        .then(() => setImageLoading(false));
                }
                else
                {
                    notificationContext.addNotification("danger", trans("AccountSettings.Error"), trans("AccountSettings.Image_could_not_be_saved__Please_try_aga"));
                }
            })
            .catch((err) =>
            {
                setImageLoading(false);
                notificationContext.addNotification("danger", trans("AccountSettings.Error"), trans("AccountSettings.Image_could_not_be_saved__Please_try_aga"));
            });
    };

    const changed = React.useMemo(() =>
    {
        const phoneChanged = phone.original.replace(/\D/g, "") !== phone.current.replace(/\D/g, "");
    
        return (name.changed || address.changed || phoneChanged || currency.changed);

    },[address.changed, currency.changed, name.changed, phone]);

    const handleResetValues = React.useCallback(() => 
    {
        name.resetOriginal();
        address.resetOriginal();
        phone.resetOriginal();
        currency.resetOriginal();
    },[address, currency, name, phone]);

    return (
        <SettingsLayout className="accountSettings">
            <RouteLeavingGuard when={changed} />
            <div className="settingsWrapper">
                <SectionSettings icon="account-settings" heading={trans("AccountSettings.Account_Settings")}>
                    <ContentGroup heading={trans("AccountSettings.Company_Logo")}>
                        <p className="paragraph">
                            {trans("AccountSettings.Upload_your_company_logo_below_")}<br />
                            {trans("AccountSettings.Your_logo_must_be_at_least_150x150_pixel")}
                        </p>
                        <div className="defaultWrap flexible displayImg hubDisplay">
                            <AvatarEditor
                                imageSrc={company?.image?.content}
                                placeholder="/img/img-placeholder.svg"
                                handleSave={handleImageSave}
                                heading={trans("AccountSettings.Upload_Logo")}
                                description={trans("AccountSettings.Upload_or_link_your_company_logo_below__")}
                                loading={imageLoading}
                            />
                        </div>
                    </ContentGroup>

                    <ContentGroup heading={trans("AccountSettings.Company_Name")}>
                        <Input
                            maxWidth="350px"
                            placeholder={trans("AccountSettings.Ex__ABC_Corporation")}
                            value={name.current}
                            onChange={(e, { value }) => name.update(value)}
                            error={name.current.trim() === ""}
                            // description="Enter the name of your company."
                        />
                    </ContentGroup>

                    <ContentGroup heading={trans("AccountSettings.Company_Address")}>
                        <div className="defaultWrap flexible three">
                            <Input
                                maxWidth="350px"
                                placeholder={trans("AccountSettings.Address")}
                                value={address.current.address}
                                onChange={(e, { value }) => address.update({ ...address.current, address: value })}
                            />
                            <Input
                                placeholder={trans("AccountSettings.City")}
                                value={address.current.city}
                                onChange={(e, { value }) => address.update({ ...address.current, city: value })}
                            />
                        </div>
                        <div className="defaultWrap flexible three">
                            <Input
                                placeholder={trans("AccountSettings.State_Province")}
                                value={address.current.state}
                                onChange={(e, { value }) => address.update({ ...address.current, state: value })}
                            />
                            <Input
                                placeholder={trans("AccountSettings.Zip_Postal_Code")}
                                value={address.current.zip}
                                onChange={(e, { value }) => address.update({ ...address.current, zip: value })}
                            />
                            <Input
                                placeholder={trans("AccountSettings.Country")}
                                value={address.current.country}
                                onChange={(e, { value }) => address.update({ ...address.current, country: value })}
                            />
                        </div>
                    </ContentGroup>

                    <ContentGroup heading={trans("AccountSettings.Phone_Number_")}
                        extra={trans("AccountSettings.select_your_country_from_the_dropdown_li")}>
                        <IntlPhoneBox
                            className="phoneBoxNew"
                            value={phone.current}
                            onChange={phone.update}
                            onValidate={setValidPhone}
                            error={Boolean(phone.error)}
                        />
                    </ContentGroup>

                    <ContentGroup className="currencyWrap" heading={trans("AccountSettings.Default_Account_Currency")}>
                        <CurrencyDropdown value={currency.current} onChange={currency.update} />
                    </ContentGroup>
                    
                    <CloseAccount />

                </SectionSettings>

            </div>
            <div className={cns("stickyActionGroup", { show: changed || loading })}>
                <SButton color="grey" floated="left" content={trans("AccountSettings.Cancel")} onClick={handleResetValues} disabled={loading} />
                <SButton color="orange" floated="right" content={trans("AccountSettings.Save")} disabled={(!changed || Boolean(phone.error) || name.current.trim() === "")} loading={loading} onClick={handleSave} />
            </div>
        </SettingsLayout>
    );
};

